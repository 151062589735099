import React, { useState } from "react";
import AddSite from "./addSitesTabs/addSite";
import UserTable from "./addSitesTabs/usertab";
import GroupNotifications from "./groupNotifications";
import PermissionSets from "./permissions";
import Credentials from "./tabs/tab7";
const AddSites = () => {
  const [activeTab, setActiveTab] = useState("User");

  const renderContent = () => {
    switch (activeTab) {
      case "Sites":
        return <AddSite />;
      case "User":
        return <UserTable />;
      case "Group":
        return <GroupNotifications />;
      case "Permissions":
        return <PermissionSets />;
      case "Credentials":
        return <Credentials />;  
      // case "State":
      //   return <StateTab/>;
      // case "Site":
      //   return <SiteTab/>;

      default:
        return null;
    }
  };
  return (
    <div className=" container mx-auto pe-10">
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["Users", "Groups", "Sites", "Permissions", "Credentials"].map(
            (tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : ""
                }`}
              >
                {tab}
              </button>
            )
          )}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AddSites;
