import React, { useState, useEffect, useRef } from "react";
import useHttp from "../../../common/hooks/useHttp";
import { useNotification } from "../../../common/hooks/useNotification";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft, FaSearch } from "react-icons/fa";

const AssetField = () => {
  const { authData } = useAuth();
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const navigate = useNavigate();

  const { id } = useParams();

  // State to manage the dropdown for each item
  const [openDropdown, setOpenDropdown] = useState(null);

  const [isFieldPopupOpen, setIsFieldPopupOpen] = useState(false);
  const [isTypePopupOpen, setIsTypePopupOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null); // For field renaming
  const [currentType, setCurrentType] = useState(null); // For type renaming
  const [assetTypesFields, setAssetTypeFields] = useState([]);

  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [assetFields, setAssetFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const filteredFields = assetFields.filter(
      (field) =>
        !assetTypesFields.some(
          (typeField) => typeField.fieldId === field.fieldId
        )
    );
    setAssetFields(filteredFields);
  }, [assetTypesFields]); // Runs whenever assetTypesFields changes

  // Example function to add a field to assetTypesFields
  const addToAssetTypeFields = (field) => {
    setAssetTypeFields((prev) => [...prev, field]);
  };

  // Reference to dropdown menu for detecting clicks outside
  const dropdownRef = useRef([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // States for form inputs
  const [fieldName, setFieldName] = useState("");
  const [valueType, setValueType] = useState("STRING");

  // Function to handle form submission
  const handleRenameField = (fieldId, fieldName) => {
    setCurrentField({ id: fieldId, name: fieldName }); // Set the field to be renamed
    setIsFieldPopupOpen(true); // Open the rename popup
  };
  // const handleRenameType = (typeId, typwName) => {
  //   setCurrentType({ id: typeId, name: typwName }); // Set the field to be renamed
  //   setIsFieldPopupOpen(true); // Open the rename popup
  // };

  const handleSaveRename = () => {
    if (currentField && currentField.name) {
      // Call API to rename the field
      // setIsPopupOpen(false); // Close the popup after renaming
    }
  };

  const getAssetTypeFields = async () => {
    try {
      await sendRequest(
        {
          url: `v1/asset/type/fields?assetTypeId=${id}`,
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          setAssetTypeFields(response.data.assetTypeFields);

          const assetType = response.data.assetType;
          setCurrentType({ id: assetType.id, name: assetType.name });
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  useEffect(() => {
    getAssetTypeFields();
  }, [authData, id]);

  const handleSaveFieldRename = async () => {
    setIsFieldPopupOpen(false);

    try {
      await sendRequest(
        {
          url: "v1/asset/field/update",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            assetFieldId: currentField.id,
            fieldName: currentField.name,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setIsFieldPopupOpen(false); // Close the popup after renaming
              setCurrentField(null);
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
    if (currentField && currentField.name) {
      // Call API to rename the field
      setIsFieldPopupOpen(false); // Close the popup after renaming
    }
  };

  const handleSaveTypeRename = async () => {
    if (currentType && currentType.name) {
      try {
        await sendRequest(
          {
            url: "v1/asset/type/update",
            method: "PUT",
            accessToken: authData.accessToken,
            headers: {
              userName: authData.userName,
              organizationId: authData.organizationId,
              deviceId: authData.deviceId,
            },
            payload: {
              assetTypeId: currentType.id,
              assetTypeName: currentType.name,
            },
          },
          (response) => {
            if (response) {
              if (response.responseCode == "200") {
                getAssetTypeFields();
                setIsTypePopupOpen(false);
                notify(response.responseDesc, "success");
              } else {
                notify(response.responseDesc, "error");
              }
            }
          }
        );
      } catch (error) {
        console.error("Error getting:", error.response);
      }
    }
  };

  const handleDeleteField = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/asset/field/delete?assetFieldId=${id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleAddFieldToType = async (assetFieldId, removing = false) => {
    try {
      await sendRequest(
        {
          url: `v1/asset/type/field/update`,
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            assetTypeId: id,
            assetFieldId: assetFieldId,
            isDelete: removing,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getAssetFields();
              getAssetTypeFields();

              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleCreate = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/field/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            fieldName,
            valueType,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setFieldName("");
              setIsModalOpen(false);
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const getAssetFields = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/fields",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          setAssetFields(response.data.fields);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    getAssetFields();
  }, [authData]);
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicking outside of it
      if (
        dropdownRef.current &&
        !dropdownRef.current.some((ref) => ref && ref.contains(event.target))
      ) {
        setOpenDropdown(null);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredAssetFields = assetFields.filter((field) =>
    field.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="flex items-center justify-between mt-4">
        <button
          className="bg-white text-indigo-600 border border-black rounded py-2 px-4 rounded hover:bg-indigo-50"
          onClick={() => navigate(-1)}
        >
          Done
        </button>
        <div className="bg-white w-full h-10 p-4 text-center flex items-center justify-center font-bold">
          Edit type
        </div>
      </div>
      <div className="flex flex-col lg:flex-row bg-white min-h-screen p-8 relative">
        {/* Main Content */}
        <div className="flex-1 bg-gray-100 shadow-md rounded-lg p-6 space-y-6">
          <div className="flex items-center mb-4">
            <FaArrowLeft
              className="text-black text-2xl cursor-pointer mr-2"
              onClick={() => navigate(-1)}
            />
            <h3 className="font-bold text-2xl text-left">Manage types</h3>
          </div>
          {/* Header */}
          <div className="flex items-center space-x-2">
            <h1 className="text-lg font-semibold text-gray-800">
              {currentType?.name}
            </h1>
            <button
              className="text-gray-500 hover:text-gray-800"
              onClick={() => setIsTypePopupOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.172 3.828l4.95 4.95a2 2 0 010 2.828l-10 10a2 2 0 01-1.414.586H4v-4.586a2 2 0 01.586-1.414l10-10a2 2 0 012.828 0z"
                />
              </svg>
            </button>
          </div>

          {/* Summary Fields */}
          <div>
            <div className="relative flex items-center mb-2">
              <h2 className="text-gray-700 font-medium">Summary Fields</h2>
              <div className="group relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 ml-2 cursor-pointer"
                  viewBox="0 0 24 24"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <text
                    x="12"
                    y="16"
                    textAnchor="middle"
                    fill="currentColor"
                    fontSize="12"
                    fontWeight="bold"
                    fontFamily="Arial, sans-serif"
                  >
                    i
                  </text>
                </svg>
                <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 w-max px-3 py-1 bg-gray-700 text-white text-left text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                  Summary fields are important
                  <br />
                  identifiers for each asset and are
                  <br />
                  displayed in more views.
                </div>
              </div>
            </div>
            <div className="">
              {/* Unique ID */}
              <div className="flex items-center justify-between p-4 border rounded-md bg-white">
                <div class="flex items-center">
                  <p className="font-semibold text-gray-800">Unique ID</p>
                  <span class="ml-2 text-xs text-black bg-blue-50">
                    Required
                  </span>
                </div>

                <p className="text-sm text-gray-500">Text</p>
              </div>
              {/* Site */}
              <div className="flex items-center justify-between p-4 border rounded-md bg-white">
                <p className="font-semibold text-gray-800">Site</p>
                <p className="text-sm text-gray-500">Site</p>
              </div>
            </div>
          </div>

          {/* Detail Fields */}
          <div>
            <div className="flex items-center mb-2 relative group">
              <h2 className="text-gray-700 font-medium">Detail Fields</h2>
              <div className="group relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 ml-2"
                  viewBox="0 0 24 24"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <text
                    x="12"
                    y="16"
                    textAnchor="middle"
                    fill="currentColor"
                    fontSize="12"
                    fontWeight="bold"
                    fontFamily="Arial, sans-serif"
                  >
                    i
                  </text>
                </svg>
                <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 w-max px-3 py-1 bg-gray-700 text-white text-left text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                  Detail field provide additional <br /> context for each asset
                  such as <br /> address, serial number, and more.
                </div>
              </div>
            </div>

            {assetTypesFields.length > 0 ? (
              assetTypesFields.map(
                (
                  field,
                  index // Fixed variable name from `feilds` to `field`
                ) => (
                  <div
                    key={index}
                    className="p-4 border rounded-md flex items-center justify-between"
                  >
                    <p className="font-semibold text-gray-800">{field.name}</p>
                    <div className="flex items-center space-x-4">
                      <p className="text-sm text-gray-500">{field.valueType}</p>
                      {/* Cross Icon */}
                      <button
                        className="text-gray-500 hover:text-red-500"
                        onClick={() => {
                          handleAddFieldToType(field.fieldId, true);
                        }} // Make sure setIsVisible is properly defined
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )
              )
            ) : (
              <p className="text-gray-500">
                There are no detail fields added to this type yet.
                <br />
                Start adding them from the panel on the right.
              </p>
            )}
          </div>
        </div>

        {/* Sidebar */}
        <div className="w-full lg:w-1/3 bg-white shadow-md rounded-lg p-4 sm:p-6 ml-0 lg:ml-6 overflow-y-auto h-auto sm:h-[calc(100vh-64px)] mt-4 sm:mt-6 lg:mt-0">
          <h2 className="text-gray-800 font-medium mb-4 text-lg sm:text-xl">
            Add an existing field
          </h2>
          <p className="text-sm sm:text-base text-gray-500 mb-4">
            Reuse existing fields that have been added to other types to keep
            your asset details consistent.
          </p>
          <input
            type="text"
            placeholder="Search"
            className="w-full p-2 sm:p-3 border rounded-md mb-4 text-sm sm:text-base"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredAssetFields.length > 0 ? (
            <>
              <ul className="space-y-4">
                {filteredAssetFields.map((field, index) => (
                  <li
                    key={index}
                    className="relative flex flex-col sm:flex-row sm:items-center justify-between p-4 border rounded-md"
                  >
                    <span className="font-medium text-gray-800 text-sm sm:text-base">
                      {field.name}
                    </span>
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 mt-2 sm:mt-0 space-y-2 sm:space-y-0">
                      <span className="text-gray-500 text-xs sm:text-sm">
                        {field.valueType}
                      </span>
                      <button
                        className="text-gray-500 hover:text-gray-800"
                        onClick={() => handleAddFieldToType(field.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </button>
                      {field.fieldType !== "PREDEFINED" && (
                        <>
                          <button
                            className="text-gray-500 hover:text-gray-800 relative"
                            onClick={() => toggleDropdown(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6h.01M12 12h.01M12 18h.01"
                              />
                            </svg>
                          </button>
                          {openDropdown === index && (
                            <div
                              ref={(el) => (dropdownRef.current[index] = el)}
                              className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                            >
                              <ul className="py-1 text-sm text-gray-700">
                                <li
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() =>
                                    handleRenameField(field.id, field.name)
                                  }
                                >
                                  Rename Field
                                </li>
                                <li
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => handleDeleteField(field.id)}
                                >
                                  Delete Field
                                </li>
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <div className="fixed bottom-6 right-4 sm:right-8 lg:right-16 mt-4 w-96 h-20 text-left bg-gray-200 text-sky-700 rounded px-4 py-2 shadow-lg">
                <button
                  className="w-full sm:w-auto p-2 border border-black rounded bg-white h-10 mt-2 ml-4 right-10"
                  onClick={() => setIsModalOpen(true)}
                >
                  + Create a new field
                </button>
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500 mt-10">
              <FaSearch className="w-12 h-12 mx-auto mb-4 text-gray-400" />
              <h2 className="text-lg sm:text-xl font-medium mb-2">
                No results
              </h2>
              <p className="text-sm sm:text-base">
                Try again with different search terms
              </p>
            </div>
          )}
        </div>

        {isFieldPopupOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-96">
              <h3 className="text-lg font-semibold text-gray-800 mb-4 text-left">
                Rename Field
              </h3>
              <input
                type="text"
                value={currentField?.name || ""}
                onChange={(e) =>
                  setCurrentField({ ...currentField, name: e.target.value })
                }
                className="w-full p-2 border rounded-md mb-4"
                placeholder="Enter new field name"
              />
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                  onClick={() => setIsFieldPopupOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  onClick={handleSaveFieldRename} // Save the renamed field
                >
                  Rename
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Popup */}
        {isTypePopupOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-[500px]">
              {" "}
              {/* Updated width */}
              <h3 className="text-lg text-start font-semibold text-gray-800 mb-4">
                Rename Type
              </h3>
              <label className="text-start flex"> Name of type </label>
              <input
                type="text"
                value={currentType?.name || ""}
                onChange={(e) =>
                  setCurrentType({ ...currentType, name: e.target.value })
                }
                className="w-full p-2 border rounded-md mb-4"
                placeholder="Enter new type name"
              />
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                  onClick={() => setIsTypePopupOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  onClick={handleSaveTypeRename}
                >
                  Rename
                </button>
              </div>
            </div>
          </div>
        )}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">
                Create New Field
              </h3>

              {/* Name Input and Value Type Dropdown in Flex */}
              <div className="flex space-x-4 mb-4">
                {/* Field Name Input */}
                <div className="flex-1">
                  <label
                    htmlFor="fieldName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Field name
                  </label>
                  <input
                    id="fieldName"
                    type="text"
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                    placeholder="Enter field name"
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Value Type Dropdown */}
                <div className="flex-1">
                  <label
                    htmlFor="valueType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Value type
                  </label>
                  <select
                    id="valueType"
                    value={valueType}
                    onChange={(e) => setValueType(e.target.value)}
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="Text">Text</option>
                    <option value="Date">Date</option>
                    <option value="Currency">Currency</option>
                  </select>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                  onClick={() => {
                    setIsModalOpen(false);
                    setFieldName("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`px-4 py-2 text-white rounded-md ${
                    fieldName.trim()
                      ? "bg-[#6559ff] hover:bg-[#6559ff]"
                      : "bg-[#b1abff] cursor-not-allowed"
                  }`}
                  disabled={!fieldName.trim()}
                  onClick={handleCreate}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssetField;
