import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const Time = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setIsStartDateOpen(false); // Close the date picker when a date is selected
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndDateOpen(false); // Close the date picker when a date is selected
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      {/* Start Date */}
      <div className="w-1/2 relative">
        <div className="relative">
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            dateFormat="d MMMM, yyyy h:mm aa"
            className="mt-1 block w-full border border-grey rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
            placeholderText="Select date and time"
            onFocus={() => setIsStartDateOpen(true)}
            onBlur={() => setIsStartDateOpen(false)}
          />
          {isStartDateOpen ? (
            <FaChevronUp className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          ) : (
            <FaChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          )}
        </div>
        <span className="text-gray-500 text-sm">First schedule starts on 5 Jan 2025.</span>
      </div>
          <p className="mt-2 text-xl text-gray-500">~</p>
      {/* End Date */}
      <div className="w-1/2 relative">
        <div className="relative">
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            dateFormat="d MMMM, yyyy h:mm aa"
            className="mt-1 block w-full border border-grey rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
            placeholderText="Select date and time"
            onFocus={() => setIsEndDateOpen(true)}
            onBlur={() => setIsEndDateOpen(false)}
          />
          {isEndDateOpen ? (
            <FaChevronUp className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          ) : (
            <FaChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Time;
