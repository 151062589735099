import React, { useState, useEffect, useRef } from "react";
import { IoIosSettings } from "react-icons/io";
import { FaPencilAlt, FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useApi } from "../../../context/OrganizationContaxt";
import { FiDelete, FiEdit3 } from "react-icons/fi";
import { useAuth } from "../../../context/AuthContext";
import useHttp from "../../../../common/hooks/useHttp";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../common/hooks/useNotification";
import PropTypes from "prop-types";

const TemplatePage = ({ onSort }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { sendRequest } = useHttp();
  const { authData } = useAuth();
  const [showCarDSection, setShowCarDSection] = useState(true);
  const notify = useNotification();
  const Navigate = useNavigate();
  const { getTamplate, getTamplateById, getTamplateList } = useApi();
  console.log(getTamplateList);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [activeSortColumn, setActiveSortColumn] = useState("template");
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null); // Ref for the dropdown container
  const navigate = useNavigate();

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => (prev === index ? null : index)); // Toggle dropdown for the clicked item
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick); // Listen for outside clicks
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick); // Cleanup event listener
    };
  }, []);

  const toggleCheckbox = (templateId) => {
    setSelectedCheckbox((prevState) => ({
      ...prevState,
      [templateId]: !prevState[templateId],
    }));
  };

  const handleRowClick = (e, item) => {
    // Prevent the sidebar from opening when clicking on the checkbox or button
    if (
      e.target.closest("input[type='checkbox']") ||
      e.target.closest("button")
    ) {
      return;
    }
    toggleSidebar(item);
  };

  const toggleSidebar = (template) => {
    setSelectedTemplate(template);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const [selectedFields, setSelectedFields] = useState({
    template: true,
    lastPublished: true,
    access: true,
    owner: true, // New field
    status: true, // New field
  });

  const toggleSettingsDropdown = () => {
    setIsSettingsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isSettingsDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSettingsDropdownOpen]);

  // const getTamplateList = {
  //   data: {
  //     templates: [
  //       {
  //         id: 1,
  //         title: "Untitled template",
  //         lastPublishedAt: "14 Nov 2024",
  //         published: true,
  //         owner: "User A",
  //         status: "Active",
  //       },
  //       {
  //         id: 2,
  //         title: "Untitled template",
  //         lastPublishedAt: "10 Nov 2024",
  //         published: false,
  //         owner: "User B",
  //         status: "Inactive",
  //       },
  //     ],
  //   },
  // };

  const toggleSortOrder = (column) => {
    if (activeSortColumn === column) {
      setIsAscending(!isAscending);
    } else {
      setActiveSortColumn(column);
      setIsAscending(true);
    }
    onSort(isAscending, column);
  };

  const toggleFieldSelection = (field) => {
    setSelectedFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  const deleteTamplate = async (templateId) => {
    try {
      await sendRequest(
        {
          url: "v1/template/delete",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            templateId: templateId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");

              getTamplate();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const filteredData = getTamplateList?.data?.templates.filter((item) =>
    item?.title?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = getTamplateList.data?.templates.map((item) => item.id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const isAllSelected =
    selectedItems.length === getTamplateList.data?.templates.length;

  const handlGotoTamplate = (id) => {
    getTamplateById(id);
    Navigate(`/dashboard/start-inspection/${id}`);
  };

  const data = [
    {
      id: 1,

      icon: <FaPlus className="text-[#6559ff]" />,

      link: "",
      title: "Start from scratch",
      description: "Get started with a blank template.",
      completed: true,
    },
    {
      id: 2,
      icon: <FiEdit3 size={34} color="orange" />,
      title: "Describe topic",
      description: "Enter a text prompt about your template.",
      completed: false,
    },
    {
      id: 3,
      icon: <FaSearch className="text-[#6559ff]" />,
      title: "Find Pre-made template",
      description: "Choose from over 100,000 editable templates.",
      completed: true,
    },
  ];

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [ShowTamplate, setShowTamplate] = useState(false);
  return (
    <div className=" pt-5 pe-10 mb-20">
      {showCarDSection && (
        <div className="bg-[#F8F9FC] p-8 rounded-lg shadow-md">
          <div className="flex items-start "></div>
          <div className="flex justify-between ">
            <p
              className="text-lg mb-4"
              style={{
                fontWeight: 300,
                fontWeight: "100 !important",
              }}
            >
              Create your template from one of the options below.
            </p>
            <button
              onClick={() => {
                setShowCarDSection(false);
              }}
              className="flex flex-col items-end text-indigo-400 "
              style={{
                fontWeight: 300,
                fontWeight: "100 !important",
              }}
            >
              <svg
                viewBox="0 0 24 24"
                width="16"
                height="16"
                class="multi-select-filter-shared-styled__RemoveFilterSvg-iauditor__sc-eyloht-2 doVBZY"
                fill="#6559ff"
                focusable="false"
                data-anchor="cross-svg"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {data.map((item) => (
              <div
                key={item.id}
                className="flex flex-col hover:bg-purple-200  items-center justify-center bg-white p-9 rounded-lg shadow-md text-center"
              >
                <Link to={"/dashboard/create-template"}>
                  <div className="text-2xl flex justify-center item-center ">
                    {item?.icon}
                  </div>
                  <h3 className="mt-4 font-semibold text-[15px] text-[#545f70] mt-2">
                    {item?.title}
                  </h3>
                  <p className="mt-2 text-[17px] text-gray-500">
                    {item?.description}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      <div>
        <h1 className="text-gray-800 font-medium text-md text-start pt-6">
          Templates ({"1 -  " + getTamplateList?.data?.templates.length})
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          <div className="flex items-center space-x-4">
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="Search all templates"
                className="ml-2 outline-none bg-transparent"
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Link
              to={"/dashboard/create-template"}
              className="bg-[#6559ff] text-white px-4 py-2 rounded-lg"
            >
              + Create
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full  mx-auto p-4 bg-white rounded-lg shadow-md">
        {/* Header Row */}

        <div className="w-full mx-auto p-4 bg-white rounded-lg shadow-md border border-gray-300">
          <div className="flex items-center text-start border-b p-4 text-gray-500 text-sm font-medium border border-gray-300">
            {selectedFields.template && (
              <div className="flex w-[45%] items-center">
                <input type="checkbox" className="mr-4" />
                <span
                  className="flex underline cursor-pointer"
                  onClick={() => toggleSortOrder("template")}
                >
                  Template
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    className="ml-2"
                    focusable="false"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path
                      fill="#3f495a"
                      d={
                        activeSortColumn === "template"
                          ? isAscending
                            ? "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                            : "M4 12l1.41-1.41L11 16.17V4h2v12.17l5.58-5.58L20 12l-8 8-8-8z"
                          : "M4 12h16"
                      }
                    ></path>
                  </svg>
                </span>
              </div>
            )}

            {selectedFields.lastPublished && (
              <span
                className="text-start flex underline w-[22%] cursor-pointer"
                onClick={() => toggleSortOrder("lastPublished")}
              >
                Last Published
                <svg
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  className="ml-2"
                  focusable="false"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path
                    fill="#3f495a"
                    d={
                      activeSortColumn === "lastPublished"
                        ? isAscending
                          ? "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
                          : "M4 12l1.41-1.41L11 16.17V4h2v12.17l5.58-5.58L20 12l-8 8-8-8z"
                        : "M4 12h16"
                    }
                  ></path>
                </svg>
              </span>
            )}

            {selectedFields.owner && (
              <span className="ml-4 w-[20%]">Access</span>
            )}
            {selectedFields.status && (
              <span className="ml-4 w-[20%]">Status</span>
            )}
            <span
              onClick={toggleSettingsDropdown}
              className="cursor-pointer absolute right-20"
            >
              <IoIosSettings />
            </span>
            <div className="relative" ref={dropdownRef}>
              {isSettingsDropdownOpen && (
                <div className="absolute right-4 mt-2 w-48 bg-white shadow-lg z-50 text-left ">
                  <ul className="py-2">
                    {["template", "lastPublished", "owner", "status"].map(
                      (field) => (
                        <li
                          key={field}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => toggleFieldSelection(field)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedFields[field]}
                            onChange={() => toggleFieldSelection(field)}
                            className="mr-2"
                          />
                          {field.charAt(0).toUpperCase() + field.slice(1)}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div>
            {/* Table Rows */}
            {(searchValue === ""
              ? getTamplateList?.data?.templates
              : filteredData
            ).map((item, index) => (
              <div key={index} className="divide-y">
                <div
                  className="flex items-center p-2 hover:bg-gray-50 border border-gray-300 cursor-pointer"
                  onClick={(e) => handleRowClick(e, item)}
                >
                  <div className="w-[45%] flex text-start">
                    <input
                      type="checkbox"
                      checked={selectedCheckbox[item.id] || false}
                      onChange={() => toggleCheckbox(item.id)}
                      className="mr-4"
                    />
                    <div>
                      <p className="font-semibold text-sm text-start w-48 text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
                        {item?.title}
                      </p>
                    </div>
                  </div>
                  {selectedFields.lastPublished && (
                    <span className="text-sm text-gray-500 w-[22%] text-start">
                      {item?.lastPublishedAt}
                    </span>
                  )}
                  {selectedFields.owner && (
                    <span className="text-sm text-gray-500 w-[20%] text-start">
                      {item?.owner}
                    </span>
                  )}
                  {selectedFields.status && (
                    <span className="text-sm text-gray-500 w-[20%] text-start">
                      {item?.status}
                    </span>
                  )}
                  <div>
                    <button
                      type="button"
                      data-anchor="templates-list-start-button"
                      className="border border-gray-300 text-indigo-500 rounded-md w-32 p-2 hover hover:bg-indigo-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlGotoTamplate(item?.id);
                      }}
                    >
                      Start inspection
                    </button>
                  </div>
                  <div className="relative" ref={dropdownRef}>
                    <button
                      type="button"
                      aria-label="More actions"
                      className="ml-2 text-indigo-500"
                      data-anchor="templates-list-overflow-actions-button"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from bubbling
                        toggleDropdown(index);
                      }}
                    >
                      <svg
                        width="1.125rem"
                        height="1.125rem"
                        viewBox="0 0 24 24"
                        color="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.89543 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                    {dropdownOpen === index && (
                      <div className="absolute top-full right-0 mt-2 bg-white border border-gray-300 rounded">
                        <ul className="W-48">
                          <li className="p-2 hover:bg-gray-100 cursor-pointer">
                            Option 1
                          </li>
                          <li className="p-2 hover:bg-gray-100 cursor-pointer">
                            Option 2
                          </li>
                          <li className="p-2 hover:bg-gray-100 cursor-pointer">
                            Option 3
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isSidebarOpen && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end">
              <div className="w-96 bg-white p-6 shadow-lg">
                <button onClick={closeSidebar} className="text-red-500">
                  Close
                </button>
                <h2 className="text-xl font-semibold mb-4">Template Details</h2>
                {selectedTemplate && (
                  <div>
                    <div className="mb-4">
                      <strong>Title: </strong>
                      <input
                        type="text"
                        value={selectedTemplate.title}
                        className="w-full border p-2 rounded"
                        readOnly
                      />
                    </div>
                    <div className="mb-4">
                      <strong>Last Published: </strong>
                      <input
                        type="text"
                        value={selectedTemplate.lastPublishedAt}
                        className="w-full border p-2 rounded"
                        readOnly
                      />
                    </div>
                    <div className="mb-4">
                      <strong>Owner: </strong>
                      <input
                        type="text"
                        value={selectedTemplate.owner}
                        className="w-full border p-2 rounded"
                        readOnly
                      />
                    </div>
                    <div className="mb-4">
                      <strong>Status: </strong>
                      <input
                        type="text"
                        value={selectedTemplate.status}
                        className="w-full border p-2 rounded"
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center mt-6 space-x-4">
          <button
            className="text-gray-500 bg-gray-200 border border-gray-400 rounded p-2"
            aria-label="Previous page"
          >
            &lt;
          </button>
          <span className="text-gray-600"> 1 / 1</span>
          <button className="text-gray-500" aria-label="Next page">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};
TemplatePage.propTypes = {
  onSort: PropTypes.func.isRequired,
};

export default TemplatePage;
