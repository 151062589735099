import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
const userdevice = require("../../../assets/userdevice/notconnect.jpg");
const UserDevice = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility

  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };

    navigate(routes[tab]);
  };

  const handleClick = () => {
    setIsPopupOpen(true); // Open popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close popup
  };
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };
  const tableHeaders = ["Device Name", "Registration Date"];
  return (
    <div className="min-h-screen p-4">
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {(
            authData.userName.charAt(0) +
            authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()}
          <FaEdit
            onClick={handleClick} // Opens popup
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold">{authData?.userName}</h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
              <input
                type="text"
                placeholder="Enter new name"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleClosePopup} // Closes popup
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>
      <div role="tabpanel" className="px-2 sm:px-4 md:px-8 lg:px-0">
        <div className="mt-6 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <input
            type="text"
            placeholder="Search"
            className="border rounded-md p-2 w-full sm:w-64"
          />
        </div>

        <div className="mt-4 rounded-md overflow-x-auto">
          <table className="w-full min-w-max text-left border border-gray-300">
            <thead>
              <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
                {tableHeaders.map((header) => (
                  <th
                    key={header}
                    className="w-32 p-2 text-xs sm:text-sm md:text-base"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={userdevice}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      This user hasn't connected any devices
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      You do not have any assigned courses or certificates.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default UserDevice;
