import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FiPlusCircle, FiPenTool, FiUploadCloud } from "react-icons/fi";
import DescribetopicModal from "./describetopic";
import { Link, useNavigate } from "react-router-dom";
import { useApi } from "../../components/context/OrganizationContaxt";

const Createtemplate = () => {
  const [describeTopicOpen, setDescribetopicOpen] = useState(false);
  const { getTamplateList } = useApi();
  const [publishedTemplates, setPublishedTemplates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (getTamplateList?.data?.templates) {
      const filteredTemplates = getTamplateList?.data?.templates?.filter(
        (template) => template.published === true
      );
      setPublishedTemplates(filteredTemplates);
    }
  }, [getTamplateList?.data?.templates]);

  const options = [
    "Agriculture",
    "Cleaning",
    "Community services",
    "Construction",
    "Dept of Defence",
    "Education",
    "Emergency services",
    "Financial services",
    "Food and hospitality",
    "General",
    "Health services",
    "Horticultural",
    "ICT",
    "Local Government",
    "Manufacturing",
    "Maritime",
    "Mining",
    "Professional services",
    "Publishing",
    "Transport and logistics",
  ];

  return (
    <div className="p-4 md:p-8 bg-gray-100 h-screen">
      {describeTopicOpen && <DescribetopicModal />}
      {/* Back icon */}
      <div className="text-gray-600 mb-6">
        <button
          className="text-start flex"
          onClick={() => {
            navigate("/dashboard/templates");
          }}
        >
          <BiArrowBack size={24} />
        </button>
      </div>

      <div className="flex justify-center items-center bg-gray-100">
        <div className="container w-full max-w-3xl p-4">
          {/* Template Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
            <div className="flex flex-col items-center justify-center bg-[#e0e4ff] rounded-md p-6 text-center">
              <Link
                className="flex justify-center flex-col items-center"
                to={"/createTamplateBlank"}
              >
                <FiPlusCircle size={30} className="text-[#6659ff] mb-4" />
                <h3 className="text-base font-semibold text-[#6659ff]">Blank template</h3>
              </Link>
            </div>
            <div
              className="flex flex-col items-center justify-center bg-[#dbf8ff] rounded-md p-6 text-center"
              onClick={() => setDescribetopicOpen(!describeTopicOpen)}  // Toggle the state
            >
              <FiPenTool size={30} className="text-[#0d75b5] mb-4" />
              <h3 className="text-base font-semibold text-[#0d75b5]">Describe topic</h3>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#fff1cc] rounded-md p-6 text-center">
              <FiUploadCloud size={30} className="text-[#9e4a00] mb-4" />
              <h3 className="text-base font-semibold text-[#9e4a00]">Convert an image/PDF</h3>
            </div>
          </div>

          {/* Text and input */}
          <h2 className="text-lg text-start text-gray-700 mb-2">Need inspiration?</h2>
          <p className="text-gray-700 text-3xl text-start font-[400] mb-4">
            Find and customize a template from the Public Library
          </p>

          {/* Search and Custom Dropdown */}
          <div className="flex flex-col md:flex-row gap-4 mb-8">
            {/* Search Input with 60% Width */}
            <div className="relative md:w-[60%] w-full">
              <span className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <svg
                  viewBox="0 0 267 267"
                  width="18"
                  className="text-gray-400"
                  height="18"
                  focusable="false"
                >
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h267v267H0z"></path>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#a)">
                    <path
                      fill="currentColor"
                      d="M172.438 155.75h-8.789l-3.115-3.004A71.993 71.993 0 0 0 178 105.688c0-39.938-32.375-72.313-72.312-72.313-39.938 0-72.313 32.375-72.313 72.313 0 39.937 32.375 72.312 72.312 72.312 17.912 0 34.377-6.564 47.059-17.466l3.004 3.115v8.789l55.625 55.513 16.576-16.576-55.513-55.625zm-66.75 0c-27.702 0-50.063-22.361-50.063-50.062 0-27.702 22.361-50.063 50.063-50.063 27.701 0 50.062 22.361 50.062 50.063 0 27.701-22.361 50.062-50.062 50.062z"
                    ></path>
                    <path d="M0 0h267v267H0V0z" fill="none"></path>
                  </g>
                </svg>
              </span>
              <input
                type="text"
                placeholder="Search inspection templates"
                className="w-full p-3 pl-10 border border-gray-300 rounded-md outline-none"
              />
            </div>

            {/* Dropdown with 40% Width */}
            <div className="relative md:w-[40%] w-full">
              <div
                className="p-3 border border-gray-300 bg-white rounded-md cursor-pointer flex items-center justify-between"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span>{selectedOption || "Filter by industry"}</span>
                <svg
                  viewBox="0 0 24 24"
                  width="13"
                  height="13"
                  className="mr-4"
                  focusable="false"
                >
                  <path
                    d="M12.819 17.633l8.866-9.52a1.323 1.323 0 0 0-.028-1.745 1.113 1.113 0 0 0-1.625-.03l-7.663 8.228a.509.509 0 0 1-.755 0L3.968 6.354a1.113 1.113 0 0 0-1.625.03 1.323 1.323 0 0 0-.028 1.745l8.85 9.504c.22.235.517.368.827.367a1.12 1.12 0 0 0 .827-.367z"
                    fill="#545f70"
                    fillRule="nonzero"
                  ></path>
                </svg>
              </div>
              {isDropdownOpen && (
                <ul className="absolute z-10 w-full border border-gray-300 bg-white rounded-md mt-1 shadow-lg text-left max-h-72 overflow-y-auto">
                  {options.map((option, index) => (
                    <li
                      key={index}
                      className="p-3 hover:bg-gray-50 cursor-pointer"
                      onClick={() => {
                        setSelectedOption(option);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                  <li className="p-3 cursor-pointer text-indigo-600 border border-t-2">
                    <button
                      className="w-16 text-center border border-gray-500 p-2 rounded-lg hover hover:bg-indigo-50"
                      onClick={() => {
                        setSelectedOption("");
                        setIsDropdownOpen(false);
                      }}
                    >
                      Clear
                    </button>
                  </li>
                </ul>
              )}  
            </div>
          </div>
          {/* Template List */}
          <div>
            {publishedTemplates?.map((template, index) => (
              <div key={index} className="p-4 border border-gray-200 text-start bg-white">
                <h3 className="text-[#6659ff]">{template.title}</h3>
                <p className="text-gray-500 text-sm">
                  {template?.description} · {template.downloads}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createtemplate;
