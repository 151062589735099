import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useApi } from "../../../context/OrganizationContaxt";

const Template = ({
  template,
  setTemplate,
  setShowTemplate,
  showTamplateNAmes,
}) => {
  const [isTemplateDropdownOpen, setIsTemplateDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { getTamplateList } = useApi();

  const dropdownRef = useRef(null);

  // Safely fetch and filter template options
  const filteredTemplateOptions =
    getTamplateList?.data?.templates?.filter((option) =>
      option?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
    ) || [];

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsTemplateDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mt-6" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700">
        Template
      </label>
      <div className="relative">
        <button
          onClick={() => setIsTemplateDropdownOpen(!isTemplateDropdownOpen)}
          className="mt-2 block w-full border border-gray-300 rounded-md h-12 px-3 text-gray-400 bg-white focus:ring-0 focus:border-indigo-500 hover:border-indigo-500 transition text-left flex items-center justify-between"
        >
          {showTamplateNAmes || "Add template..."}
          {isTemplateDropdownOpen ? (
            <FaChevronUp className="text-gray-500" />
          ) : (
            <FaChevronDown className="text-gray-500" />
          )}
        </button>
        {isTemplateDropdownOpen && (
          <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search templates..."
                className="w-full h-12 pl-10 pr-3 border-b border-gray-300 focus:outline-none"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <ul className="max-h-48 overflow-y-auto">
              {filteredTemplateOptions.length > 0 ? (
                filteredTemplateOptions.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setShowTemplate(option?.title);
                      setTemplate(option?.id);
                      setIsTemplateDropdownOpen(false);
                      setSearchTerm("");
                    }}
                    className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition overflow-hidden whitespace-nowrap"
                  >
                    {option?.title}
                  </li>
                ))
              ) : (
                <li className="px-3 py-2 text-gray-500">No templates found</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Template;
