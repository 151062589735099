import React from "react";

const InspectionHistory = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center w-full min-h-screen p-6 lg:p-12 bg-gray-50 text-left">
      {/* Left Section */}
      <div className="lg:w-1/2 mb-8 lg:mb-0">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          Get access to your inspection history
        </h1>
        <p className="text-gray-600 mb-6">
          With a Premium account you can:
        </p>
        <ul className="list-disc list-inside text-gray-600 mb-8">
          <li>View all updates and revisions made to an inspection.</li>
          <li>See who made the changes.</li>
          <li>Know the date and time they happened.</li>
        </ul>
        <button className="bg-indigo-500 text-white font-semibold px-6 py-3 rounded-lg shadow-lg hover:bg-indigo-600">
          🚀 View inspection history with Premium
        </button>
        <a
          href="#"
          className="block text-indigo-500 mt-4 underline hover:text-indigo-600"
        >
          Learn more about inspection history
        </a>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 space-y-6">
        {/* Card 1 */}
        <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-sm">
          <p className="text-sm text-red-600 font-medium mb-2">
            Marcus Henry <span className="text-gray-500">removed</span> a response an hour ago
          </p>
          <div className="border-t border-gray-200 mt-4 pt-4">
            <p className="text-sm text-gray-700 font-semibold mb-1">
              Question
            </p>
            <p className="text-sm text-gray-600 mb-3">
              The work site has appropriate signage
            </p>
            <p className="text-sm text-gray-700 font-semibold mb-1">
              Response
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-red-500 line-through">No</span>
              <span className="text-gray-500">→</span>
              <span className="text-green-500">Yes</span>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-sm">
          <p className="text-sm text-blue-600 font-medium mb-2">
            Kelly Watson <span className="text-gray-500">edited</span> a response 2 hours ago
          </p>
          <div className="border-t border-gray-200 mt-4 pt-4">
            <p className="text-sm text-gray-700 font-semibold mb-1">
              Question
            </p>
            <p className="text-sm text-gray-600 mb-3">
              The work site has appropriate signage
            </p>
            <p className="text-sm text-gray-700 font-semibold mb-1">
              Response
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-green-500">Yes</span>
              <span className="text-gray-500">→</span>
              <span className="text-red-500">No</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectionHistory;
