import React, { useState } from "react";
import { FiSearch, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const InviteLinks = ({ onClose }) => {
  const items = []; // Replace with actual data if needed\
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({
    inviteName: "",
    linkPassword: false,
    expiration: true,
    inviteLimit: false,
    groups: "",
    sites: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggle = (field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="p-6 text-left mr-14">
      {/* Header */}
      <div className="mb-4">
      <button
        className="text-sm text-blue-500 hover:underline"
        onClick={() => navigate(-1)} // Navigate back to the previous page
      >
        &larr; Back to users list
      </button>
    </div>
      
      {/* Title and Create Button */}
      <div className="flex items-center justify-between mb-4 mt-20">
        <h1 className="text-2xl font-semibold">Invite links</h1>
        <button
            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 flex items-center"
            onClick={toggleSidebar}
        >
            <FiPlus className="mr-2" /> Create
        </button>
      </div>
      <div
        className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg z-50 transform transition-transform duration-1000 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 mt-4">
      <div className="bg-white w-96 rounded-lg shadow-lg">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Create invite link</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            &times;
          </button>
        </div>

        {/* Content */}
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <p className="text-sm text-gray-500">
            Set up a link for users to join your organization with ease.
          </p>

          {/* Invite Name */}
          <div>
            <label className="block text-sm font-medium mb-1">
              Invite name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="inviteName"
              value={formData.inviteName}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          {/* Link Password */}
          <div className="flex items-center justify-between">
            <span className="text-sm">Link password</span>
            <input
              type="checkbox"
              checked={formData.linkPassword}
              onChange={() => handleToggle("linkPassword")}
              className="toggle-checkbox"
            />
          </div>

          {/* Expiration */}
          <div className="flex items-center justify-between">
            <span className="text-sm">Expiration</span>
            <input
              type="checkbox"
              checked={formData.expiration}
              onChange={() => handleToggle("expiration")}
              className="toggle-checkbox"
            />
          </div>

          {/* Expiration Date */}
          {formData.expiration && (
            <div>
              <label className="block text-sm font-medium mb-1">Expiration Date</label>
              <input
                type="date"
                name="expirationDate"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
          )}

          {/* Invite Limit */}
          <div className="flex items-center justify-between">
            <span className="text-sm">Invite limit</span>
            <input
              type="checkbox"
              checked={formData.inviteLimit}
              onChange={() => handleToggle("inviteLimit")}
              className="toggle-checkbox"
            />
          </div>

          {/* Add User Membership */}
          <div className="border border-gray-200 rounded p-4">
            <h3 className="text-sm font-semibold mb-2">Add user membership</h3>
            <p className="text-sm text-gray-500 mb-4">
              Users joining via this link will be automatically assigned the selected
              permission set, groups, and sites.
            </p>

            <div className="space-y-4">
              {/* Groups */}
              <div>
                <label className="block text-sm font-medium mb-1">Groups</label>
                <select
                  name="groups"
                  value={formData.groups}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select</option>
                  <option value="group1">Group 1</option>
                  <option value="group2">Group 2</option>
                </select>
              </div>

              {/* Sites */}
              <div>
                <label className="block text-sm font-medium mb-1">Sites</label>
                <select
                  name="sites"
                  value={formData.sites}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select</option>
                  <option value="site1">Site 1</option>
                  <option value="site2">Site 2</option>
                </select>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          >
            Create
          </button>
        </form>
      </div>
    </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        />
      )}

      {/* Search Bar */}
      <div className="relative mb-6 flex justify-between">
        <FiSearch className="absolute top-2.5 left-3 text-gray-400" />
        <input
          type="text"
          placeholder="Search"
          className="w-48 pl-10 pr-4 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:border-blue-500"
          onChange={(e) => console.log("Search:", e.target.value)}
        />
        <div className="mt-4 text-gray-500">{items.length} items</div>
      </div>

      {/* Table */}
      <div className="border border-gray-200 rounded shadow-sm">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left">
                <input type="checkbox" />
              </th>
              <th className="p-3 font-normal flex items-center">
                  <span className="relative inline-block mr-2">
                    Name
                    <span className="absolute left-0 right-0 bottom-0 h-[1px] bg-black"></span>
                  </span>

                  <button type="button" data-anchor="sort-icon" class="icon-button__StyledButton-iauditor__sc-1ev4xf3-1 cOPNnz advanced-table-styled__StyledIconButton-iauditor__sc-lzzta2-0 kgZBuZ"><svg viewBox="0 0 24 24" width="18" height="18" focusable="false"><path fill="none" d="M0 0h24v24H0V0z"></path><path fill="#3f495a" d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path></svg></button>
                </th>
                <th className=" py-2 text-left font-normal">Status</th>
                <th className=" py-2 text-left font-normal">Expiration</th>
                <th className=" py-2 text-left font-normal">Usage</th>
                <th className=" py-2 text-left font-normal">Created by</th>
            </tr>
          </thead>
          <tbody>
            {items.length === 0 ? (
              <tr>
                <td colSpan="6" className="p-6 text-center text-gray-500">
                  <div className="flex flex-col items-center">
                  <svg fill="none" width="150" height="150" viewBox="0 0 428 594"><path d="M63 575h302L228.885 132.973c-4.559-14.631-25.21-14.631-29.77 0L63 575z" fill="#6E6BFF"></path><path d="M405.248 565H22.618C10.17 565 0 575.299 0 587.902V594h428v-6.098C428 575.164 417.83 565 405.248 565z" fill="#574CF0"></path><path d="M148 298h132l-24.683-80h-82.634L148 298zm-48 157h228l-24.634-80H124.634L100 455z" fill="#FFE14C"></path><path d="M138.846 100.16 126.361.357 60.256 43.549l78.59 56.611z" fill="#FF7E05"></path><path d="M116.851 142.599 52.614 113.24l1.05 55.672 63.187-26.313z" fill="#FFAE00"></path><path d="m346 514-78.5 51H362l-16-51z" fill="#6559FF"></path><path d="M359.5 560h-129a2.5 2.5 0 1 0 0 5h131.495l-2.495-5z" fill="#6559FF"></path></svg>
                    <p className="font-medium">No invite links have been created</p>
                  </div>
                </td>
              </tr>
            ) : (
              items.map((item, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">
                    <input type="checkbox" />
                  </td>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2">{item.status}</td>
                  <td className="px-4 py-2">{item.expiration}</td>
                  <td className="px-4 py-2">{item.usage}</td>
                  <td className="px-4 py-2">{item.createdBy}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InviteLinks;
