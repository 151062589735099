import React, { useState, useEffect, useRef  } from "react";
import { FaSearch, FaFolderOpen } from "react-icons/fa";

const Archieve = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const filterDropdownRef = useRef(null); // Reference for the filter dropdown
  const filterButtonRef = useRef(null); // Reference for the filter button
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter); // Set selected filter
    setIsFilterOpen(false); // Close the dropdown
  };

  const handleRemoveFilter = () => {
    setSelectedFilter(""); // Remove the selected filter
  };

  // Close dropdown if clicked outside of filter dropdown or filter button
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current && !filterDropdownRef.current.contains(event.target) &&
        filterButtonRef.current && !filterButtonRef.current.contains(event.target)
      ) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Example data to search from
  const inspections = [
    { id: 1, name: "Inspection 1", docNumber: "DOC123", score: "85%", conducted: "2025-01-01", completed: "2025-01-10" },
    { id: 2, name: "Inspection 2", docNumber: "DOC124", score: "90%", conducted: "2025-01-05", completed: "2025-01-15" },
    { id: 3, name: "Inspection 3", docNumber: "DOC125", score: "75%", conducted: "2025-01-10", completed: "2025-01-20" },
  ];

  // Filter inspections based on the search query and selected filter
  const filteredInspections = inspections.filter((inspection) =>
    inspection.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="pt-5 pe-10">
      <div>
        <h1 className="text-gray-800 font-bold text-2xl text-start">
          Inspection archive
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                placeholder="Search"
                className="ml-2 outline-none bg-transparent"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {/* Filter button and dropdown */}
            <div className="relative">
              <button
                ref={filterButtonRef} // Attach ref to the button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover hover:bg-indigo-50 p-3 rounded-lg"
              >
                {selectedFilter ? selectedFilter : "+ Add filter"}
              </button>
              {isFilterOpen && !selectedFilter && (
                <div
                  ref={filterDropdownRef} // Attach ref to the dropdown
                  className="absolute mt-2 w-48 rounded-md shadow-lg bg-white"
                >
                  <ul className="py-1 text-gray-700">
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 1")}
                    >
                      Filter 1
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 2")}
                    >
                      Filter 2
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleFilterSelect("Filter 3")}
                    >
                      Filter 3
                    </li>
                  </ul>
                </div>
              )}
              {/* If a filter is selected, show the "remove filter" option */}
              {selectedFilter && (
                <button
                  onClick={handleRemoveFilter}
                  className="text-red-600 text-sm ml-2"
                >
                  Remove filter
                </button>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <p className="text-gray-600 text-lg">
              {filteredInspections.length === 0 ? "No results" : ""}
            </p>
          </div>
        </div>
      </div>

      {/* Header */}
      <div className="flex items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium">
        <input type="checkbox" className="mr-4" />
        <span className="flex-grow text-gray-800 underline">Inspection</span>
        <span className="flex-grow text-gray-800">Actions</span>
        <span className="flex-grow text-gray-800">Doc number</span>
        <span className="flex-grow text-gray-800 underline">Score</span>
        <span className="flex-grow text-gray-800 underline">Conducted</span>
        <span className="flex-grow text-gray-800 underline">Completed</span>
        <button
         ref={buttonRef}
         onClick={toggleDropdown}
        className="ml-4 text-gray-400 hover:text-gray-600">
        <svg viewBox="0 0 24 24" width="18" height="18" fill="#545f70" xmlns="http://www.w3.org/2000/svg"><path d="M18.506 12c0 .306-.027.594-.063.882l1.9 1.485a.454.454 0 01.107.576l-1.8 3.114a.439.439 0 01-.549.198l-2.24-.9a6.915 6.915 0 01-1.522.882l-.342 2.385a.439.439 0 01-.44.378h-3.6a.439.439 0 01-.442-.378l-.342-2.385a6.575 6.575 0 01-1.52-.882l-2.242.9a.452.452 0 01-.549-.198l-1.8-3.114a.454.454 0 01.108-.576l1.9-1.485A7.138 7.138 0 015.005 12c0-.297.027-.594.063-.882L3.17 9.633a.444.444 0 01-.108-.576l1.8-3.114a.439.439 0 01.55-.198l2.24.9a6.915 6.915 0 011.521-.882l.342-2.385A.439.439 0 019.956 3h3.6c.225 0 .414.162.441.378l.342 2.385a6.575 6.575 0 011.521.882l2.241-.9a.452.452 0 01.55.198l1.8 3.114a.454.454 0 01-.109.576l-1.899 1.485c.036.288.063.576.063.882zm-9.9 0a3.153 3.153 0 003.15 3.15 3.153 3.153 0 003.15-3.15 3.153 3.153 0 00-3.15-3.15A3.153 3.153 0 008.606 12z" fill="#545f70"></path><mask id="a" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18"><path d="M18.506 12c0 .306-.027.594-.063.882l1.9 1.485a.454.454 0 01.107.576l-1.8 3.114a.439.439 0 01-.549.198l-2.24-.9a6.915 6.915 0 01-1.522.882l-.342 2.385a.439.439 0 01-.44.378h-3.6a.439.439 0 01-.442-.378l-.342-2.385a6.575 6.575 0 01-1.52-.882l-2.242.9a.452.452 0 01-.549-.198l-1.8-3.114a.454.454 0 01.108-.576l1.9-1.485A7.138 7.138 0 015.005 12c0-.297.027-.594.063-.882L3.17 9.633a.444.444 0 01-.108-.576l1.8-3.114a.439.439 0 01.55-.198l2.24.9a6.915 6.915 0 011.521-.882l.342-2.385A.439.439 0 019.956 3h3.6c.225 0 .414.162.441.378l.342 2.385a6.575 6.575 0 011.521.882l2.241-.9a.452.452 0 01.55.198l1.8 3.114a.454.454 0 01-.109.576l-1.899 1.485c.036.288.063.576.063.882zm-9.9 0a3.153 3.153 0 003.15 3.15 3.153 3.153 0 003.15-3.15 3.153 3.153 0 00-3.15-3.15A3.153 3.153 0 008.606 12z" fill="none"></path></mask></svg>
        </button>
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef} // Attach ref to the dropdown
          className="absolute mt-2 w-48 rounded-md shadow-lg bg-white"
        >
          <ul className="py-1 text-gray-700">
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Option 1
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Option 2
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Option 3
            </li>
          </ul>
        </div>
      )}

      {/* Inspection list */}
      <div>
        {filteredInspections.length > 0 ? (
          filteredInspections.map((inspection) => (
            <div
              key={inspection.id}
              className="flex items-center border-b px-4 py-3 text-gray-800"
            >
              <input type="checkbox" className="mr-4" />
              <span className="flex-grow">{inspection.name}</span>
              <span className="flex-grow">{inspection.docNumber}</span>
              <span className="flex-grow">{inspection.score}</span>
              <span className="flex-grow">{inspection.conducted}</span>
              <span className="flex-grow">{inspection.completed}</span>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-16 border-2">
            <svg width="176" height="176" viewBox="0 0 191 191" fill="none"><path d="M130.497 26.5931L73.319 53.1913C72.8127 53.4373 72.1906 53.4599 71.6745 53.2689L59.3162 48.8051C58.7633 48.5915 58.1482 48.6438 57.6123 48.8968L37.4217 58.7224L27.1554 63.7074C26.6194 63.9604 26.2291 64.4298 26.0452 64.9756L22.3648 76.1434C22.188 76.7188 21.768 77.1953 21.2321 77.4484L7.97632 83.5841C6.84504 84.1043 6.38535 85.4688 6.96499 86.586L58.6401 184.265L182.685 121.42L133.366 27.5733C132.807 26.5452 131.546 26.1237 130.497 26.5931Z" fill="#574CF0"></path><path d="M182.685 121.421L51.9911 156.856L20.1699 94.7395C19.7641 93.9575 20.0937 92.9692 20.8828 92.5931L137.975 36.3921L182.685 121.421Z" fill="#4740D4"></path><path d="M181.588 124.852L58.6331 184.237L37.821 98.6345C37.51 97.3279 38.1323 95.9874 39.3456 95.4163L160.965 36.8825C162.008 36.3835 163.245 36.9676 163.513 38.096L183.302 121.243C183.648 122.698 182.942 124.215 181.588 124.852Z" fill="#6559FF"></path><path d="M147.92 37.551L179.181 21.7206L159.034 4.46903L147.92 37.551Z" fill="#4CD5FF"></path><path d="M143.799 34.7997L150.751 10.7087L133.394 14.5802L143.799 34.7997Z" fill="#FFE97A"></path></svg>
            <p className="text-gray-600">There are no archived inspections</p>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center mt-6 space-x-4">
          <button
            className="text-gray-500 bg-gray-200 border border-gray-400 rounded p-2"
            aria-label="Previous page"
          >
            &lt;
          </button>
          <span className="text-gray-600"> 1 / 1</span>
          <button className="text-gray-500" aria-label="Next page">
            &gt;
          </button>
        </div>
    </div>
  );
};

export default Archieve;
