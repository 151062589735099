import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

function CreateGroupModal({ setCreateGroupOpen, onClose }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupName, setGroupName] = useState('');
  const [selectedUser, setSelectedUser] = useState(false); // Track user selection
  const [newUser, setNewUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
  }); // New user details

  // Check if the Create button should be enabled
  const isCreateButtonDisabled = !groupName || !selectedUser;

  // Handle Done button click for user selection
  const handleDoneButtonClick = () => {
    setIsDropdownOpen(false); // Close the dropdown
    setSelectedUser(true); // Mark user as selected
  };

  // Handle Add User Modal form submission
  const handleAddUser = () => {
    // Check if all fields are filled
    if (newUser.email && newUser.firstName && newUser.lastName) {
      // Add user logic (could be saving to a list or making an API call)
      console.log("Added user:", newUser);

      // Close the Add User modal
      setIsAddUserModalOpen(false);

      // You can update the selected user state here if necessary
      setSelectedUser(true);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start pt-6 justify-center z-40 px-4 sm:px-0">
      <div className="text-start bg-white rounded-lg w-full max-w-md p-6 md:p-8 relative">
        {/* Close button */}
        {/* <button
          onClick={() => onClose()} // Ensure this triggers the onClose function passed from the parent
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          &times;
        </button> */}
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Create group
        </h2>

        {/* Group Name */}
        <div className="mb-4">
          <label className="text-md font-medium text-gray-700">Group name</label>
          <input
            type="text"
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)} // Update group name
            className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Add Users Dropdown */}
        <div className="relative mb-4">
          <label className="text-md font-medium text-gray-700">Add users</label>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center"
          >
            <span>Select users...</span>
            <svg
              className={`transform transition-transform ${
                isDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              width="20"
              height="20"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5A1 1 0 0110 3z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg p-4">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700"
              />
              <div className="text-center text-gray-500">No results</div>
              {/* Add User by Email */}
              <div className="mb-4">
                <p className="text-gray-400 text-start my-2">
                  Give access by email
                </p>
                <p
                  className="text-sm text-blue-600 font-medium cursor-pointer"
                  onClick={() => setIsAddUserModalOpen(true)} // Open Add User Modal
                >
                  + Add user to team
                </p>
              </div>
              {/* Users List */}
              <div className="mb-6">
                <label className="text-sm font-medium text-gray-700">Users</label>
                <div className="flex items-center space-x-2 mt-2">
                  <input
                    type="checkbox"
                    onChange={() => setSelectedUser(!selectedUser)} // Toggle user selection
                    className="text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-gray-700">arab khan (you)</span>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleDoneButtonClick} // Close dropdown and mark user as selected
                  className="bg-[#6559ff] rounded-lg px-3 py-2 text-white"
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Done Button */}
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => setCreateGroupOpen(false)}
            className="text-sm border border-[#bfc6d4] rounded-lg font-medium text-[#4740d4] hover:bg-[#e9edf6] py-3 px-5 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={() => setIsAddUserModalOpen(false)}
            className={`flex items-center bg-[#4740d4] text-white text-sm font-medium py-2 px-5 rounded-lg transition ${isCreateButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isCreateButtonDisabled} // Disable button if conditions are not met
          >
            Create group
          </button>
        </div>
      </div>

      {/* Add User to Team Modal */}
      {isAddUserModalOpen && (
        <div className="fixed text-start inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-0 z-50">
          <div className="bg-white rounded-lg w-full max-w-md p-6 md:p-8 relative">
            <button
              onClick={() => setIsAddUserModalOpen(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Add a user to your team
            </h2>

            {/* Email Input */}
            <div className="mb-4">
              <label className="text-md font-medium text-gray-700">Email</label>
              <input
                type="email"
                placeholder="Enter email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* First Name */}
            <div className="mb-4">
              <label className="text-md font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                placeholder="Enter first name"
                value={newUser.firstName}
                onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Last Name */}
            <div className="mb-4">
              <label className="text-md font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Enter last name"
                value={newUser.lastName}
                onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                className="w-full border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Seats Available */}
            <p className="text-sm text-gray-500 mb-4">Seats available: 9</p>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsAddUserModalOpen(false)}
                className="text-sm border border-[#bfc6d4] rounded-lg font-medium text-[#4740d4] hover:bg-[#e9edf6] py-3 px-5"
              >
                Cancel
              </button>
              <button
                onClick={handleAddUser} // Add user logic
                className="text-sm bg-[#4740d4] text-white rounded-lg font-medium py-3 px-5"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateGroupModal;
