import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";

const Credentials = () => {
  const navigate = useNavigate();

  // State to manage the popup
  const [isAddTypePopupOpen, setIsAddTypePopupOpen] = useState(false);
  const [isDotOpen, setIsDotOpen] = useState(false);  // State for dot menu
  const [customizeLabel, setCustomizelabel] = useState(false);  // State for customizing labels
  const [sitePermissionOpen, setSitePermissionOpen] = useState(false);  // State for site permission settings

  // Handlers for opening and closing the popup
  const handleOpenAddTypePopup = () => {
    setIsAddTypePopupOpen(true);
  };

  const handleCloseAddTypePopup = () => {
    setIsAddTypePopupOpen(false);
  };

  const dotMenuOpen = () => {
    setIsDotOpen(!isDotOpen);  // Toggle dot menu
  };

  return (
    <div className="py-3">
      <div className="flex flex-col">
        {/* Header Section */}
        <div className="flex items-center justify-between p-4 border-b border-gray-300">
          {/* Left side heading */}
          <h2 className="text-xl font-semibold">Credentials</h2>

          {/* Right side buttons */}
          <div className="space-x-2">
            <button
              onClick={handleOpenAddTypePopup} // Open this popup on button click
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              + Create type
            </button>
            <button
              className="p-2 rounded-md border border-gray-300"
              onClick={dotMenuOpen} // Open the dot menu on button click
            >
              <FiMoreVertical />
            </button>
            <div className="relative">
              {isDotOpen && (
                <div className="absolute z-50 text-start space-y-3 ps-4 py-3 cursor-pointer left-[-250px] right-0 mt-10 bg-white w-[270px]">
                  <p onClick={() => navigate("/dashboard/items-without-site")}>
                    Items without sites
                  </p>
                  <p onClick={() => navigate("/dashboard/bulk-upload-sites")}>
                    Bulk Upload sites (CSV)
                  </p>
                  <p>Download sites (CSV)</p>
                  <p
                    onClick={() => {
                      setCustomizelabel(true);
                      setIsDotOpen(false);
                    }}
                  >
                    Customize site labels
                  </p>
                  <p
                    onClick={() => {
                      setSitePermissionOpen(true);
                      setIsDotOpen(false);
                    }}
                  >
                    Site selection settings
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Add New Credential Type Popup */}
        {isAddTypePopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-bold mb-4 text-left">
                Create New Type
              </h2>
              <input
                type="text"
                placeholder="Enter type name"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleCloseAddTypePopup} // Close popup on button click
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="p-6 min-h-screen">
        {/* Header Cards */}
        <div className="grid grid-cols-3 gap-2 mb-6">
          {["Expired", "Expiring soon", "Total credentials"].map((label, index) => (
            <div
              key={label}
              className="flex items-start justify-start p-4 bg-white rounded-lg shadow-sm border border-gray-200"
            >
              {/* Icons for different labels */}
              <div>
                {label === "Expired" && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    color="#c62f35"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5706 7.19553C10.5326 6.61866 10.9903 6.12988 11.5684 6.12988H12.4325C13.0106 6.12988 13.4683 6.61866 13.4303 7.19553L13.025 13.3561C13.0112 13.5664 12.8366 13.7299 12.6259 13.7299H11.375C11.1643 13.7299 10.9897 13.5664 10.9759 13.3561L10.5706 7.19553Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12 17.73C12.8284 17.73 13.5 17.0584 13.5 16.23C13.5 15.4016 12.8284 14.73 12 14.73C11.1716 14.73 10.5 15.4016 10.5 16.23C10.5 17.0584 11.1716 17.73 12 17.73Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                <div>
                  {label === "Expiring soon" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      color="#d97502"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V12C11 12.3788 11.214 12.725 11.5528 12.8944L15.5528 14.8944C16.0468 15.1414 16.6474 14.9412 16.8944 14.4472C17.1414 13.9532 16.9412 13.3526 16.4472 13.1056L13 11.382V6Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}
                </div>
                <div>
                  {label === "Total credentials" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      color="#4740d4"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V7C23 7.55228 22.5523 8 22 8C21.4477 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V17C3 17.5523 3.44772 18 4 18H12C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20H4C2.34315 20 1 18.6569 1 17V4Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 10.5003C5 9.94802 5.44772 9.50031 6 9.50031H10C10.5523 9.50031 11 9.94802 11 10.5003C11 11.0526 10.5523 11.5003 10 11.5003H6C5.44772 11.5003 5 11.0526 5 10.5003Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}
                </div>
                <p className="text-sm text-gray-500 mt-4">{label}</p>
              </div>
              <p className="text-red-600 text-2xl font-bold">0</p>
              <span className="text-gray-400 text-xl ml-auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  color="lightgray"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div>
          ))}
        </div>

        {/* Rest of your code remains the same */}
      </div>
    </div>
  );
};

export default Credentials;
