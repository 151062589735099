import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { FaCog } from "react-icons/fa";

const IssuePage = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const popupRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("Issues");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [data, setData] = useState([
    // Sample data (replace with your actual data)
    { id: 1, category: 'Electronics' }
  ]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  // const handleFilterSelect = (filter) => {
  //   if (!selectedFilters.includes(filter)) {
  //     setSelectedFilters([...selectedFilters, filter]);
  //   }
  // };

  const initialOptions = [
    { id: 1, label: "Unique ID", checked: true },
    { id: 2, label: "Issue", checked: true },
    { id: 3, label: "Category", checked: true, isNew: true },
    { id: 4, label: "Status", checked: true, isNew: true },
    { id: 5, label: "Priority", checked: false },
    { id: 6, label: "Modified date", checked: false },
    { id: 7, label: "Assigned to", checked: false },
    { id: 8, label: "Site", checked: false },
    { id: 9, label: "Brand", checked: false },
    { id: 10, label: "Cat ID", checked: false },
  ];

  const [options, setOptions] = useState(initialOptions);
  const [filteredOptions, setFilteredOptions] = useState(initialOptions);

  const filteredData = data.filter((item) =>
    item.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNavigate = () => {
    navigate('/dashboard/category-page'); // Navigate to the specified route
  };

  const handleClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };

  const handleFilterRemove = (filter) => {
    setSelectedFilters(selectedFilters.filter((item) => item !== filter));
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const togglePopup = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleCheckbox = (id) => {
    setFilteredOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, checked: !option.checked } : option
      )
    );
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };
  const handleReportIssueClick = () => {
    navigate('/dashboard/report-issue');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };

  const handleFilterSelect = (filter) => {
    if (!selectedFilters.includes(filter)) {
      setSelectedFilters((prev) => [...prev, filter]);
    }
    setIsOpen(false); // Close popup after selection
  };

  const removeFilter = (filter) => {
    setSelectedFilters((prev) => prev.filter((item) => item !== filter));
  };

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center py-4 ">
      {/* Header */}
      <div className="w-full px-4 border-b">
        <div className="flex items-center justify-start space-x-8">
          <button
            className={`mb-4 ${
              activeButton === "Issues"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => handleClick("Issues", "/dashboard/issue-page")}
          >
            Issues
          </button>
          <button
            className={`mb-4 ${
              activeButton === "Categories"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => handleClick("Categories", "/dashboard/category-page")}
          >
            Categories
          </button>
          <button
            className={`mb-4 ${
              activeButton === "QR codes"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => handleClick("QR codes", "/dashboard/qr-page")}
          >
            QR codes
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between w-full max-w-7xl px-4 mt-4">
        <div className="text-3xl font-medium text-gray-900 ml-4">Issues</div>
        <button
          className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 mr-10"
          onClick={handleReportIssueClick}
        >
          + Report Issue
        </button>
        </div>

      {/* Dropdown */}
      <div className="relative w-full ml-24 mt-4">
        <div
          className="flex justify-start items-center space-x-2 cursor-pointer"
          onClick={toggleDropdown}
        >
          <span className="text-blue-500 font-medium border-b-2 border-blue-500">
            All
          </span>
          <FaPlus className="text-indigo-500 text-sm" />
        </div>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute left-0 mt-2 bg-white shadow-md rounded-lg w-72 border border-gray-300"
          >
            <div className="px-4 py-2">
              <div className="text-left text-gray-700 mb-4">New view</div>
              <div className="text-left">View name</div>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center space-x-2 ml-4 border-b">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-gray-300"
              >
                <path d="M1 12s3-9 11-9 11 9 11 9-3 9-11 9-11-9-11-9z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
              <span className="text-gray-300 font-medium">This view is only visible to you</span>
            </div>
            <div className="px-4 py-2 flex justify-between space-x-2 mb-4 mt-2">
              <button
                onClick={() => setIsDropdownOpen(false)}
                className="px-3 py-1 text-gray-600 border rounded hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={() => console.log('Create clicked')} // Replace with your create logic
                className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Create
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Search and Filters */}
      <div className="w-full max-w-7xl px-4 mt-6">
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Search"
              className="w-64 max-w-xs px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={handleSearchChange} // Update search query on input change
            />
            <div className="relative inline-block">
            <button
              className="text-blue-500 font-medium hover:underline ml-4"
              onClick={togglePopup}
            >
              + Add filter
            </button>
              
            {selectedFilters.map((filter) => (
              <span
                key={filter}
                className="ml-2 bg-blue-100 text-blue-600 px-2 py-1 rounded cursor-pointer"
                onClick={() => removeFilter(filter)}
              >
                {filter} 
              </span>
            ))}
              {/* Render selected filters next to the button */}
              {/* <div className="ml-4 flex space-x-2">
                {selectedFilters.map((filter, index) => (
                  <span
                    key={index}
                    className="px-2 py-1 text-sm bg-gray-200 rounded-full cursor-pointer"
                    onClick={() => handleFilterRemove(filter)}
                  >
                    {filter} <span className="ml-1 text-red-500">x</span> 
                  </span>
                ))}
              </div> */}
              {isOpen && (
                  <div
                    ref={popupRef}
                    className="popup-container absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-50"
                  >
                    <ul
                      className={`py-2 text-left transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'max-h-60' : 'max-h-0'}`}
                      style={{
                        maxHeight: isOpen ? '15rem' : '0',
                        overflowY: 'auto',  // Add scrollbar when content overflows
                      }}
                    >
                      {['Assignee', 'Category', 'Status', 'Priority', 'Creator', 'Due date', 'Created', 'Date Occurred', 'Site'].map((filter) => (
                        <li
                          key={filter}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleFilterSelect(filter)}
                        >
                          {filter}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>

          {/* Result with Dropdown */}
          <div className="flex items-center space-x-4 mr-10">
            <span className="text-gray-500 font-medium">0 results</span>
            <div className="relative group">
            <svg width="1.125rem" height="1.125rem" viewBox="0 0 24 24" color="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V12.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289L13 12.5858V3Z" fill="currentColor"></path><path d="M3 14C3.55229 14 4 14.4477 4 15V17.8C4 18.3766 4.00078 18.7488 4.02393 19.0322C4.04613 19.3038 4.0838 19.4045 4.109 19.454C4.20487 19.6422 4.35785 19.7951 4.54601 19.891C4.59546 19.9162 4.69618 19.9539 4.96784 19.9761C5.25118 19.9992 5.62345 20 6.2 20H17.8C18.3766 20 18.7488 19.9992 19.0322 19.9761C19.3038 19.9539 19.4045 19.9162 19.454 19.891C19.6422 19.7951 19.7951 19.6422 19.891 19.454C19.9162 19.4045 19.9539 19.3038 19.9761 19.0322C19.9992 18.7488 20 18.3766 20 17.8V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V17.8386C22 18.3657 22 18.8205 21.9694 19.195C21.9371 19.5904 21.8658 19.9836 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.9836 21.8658 19.5904 21.9371 19.195 21.9694C18.8205 22 18.3657 22 17.8385 22H6.16146C5.63431 22 5.17955 22 4.80497 21.9694C4.40963 21.9371 4.01641 21.8658 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2.13419 19.9836 2.06287 19.5904 2.03057 19.195C1.99997 18.8205 1.99998 18.3657 2 17.8386V15C2 14.4477 2.44772 14 3 14Z" fill="currentColor"></path></svg>
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-700 text-white text-sm rounded px-2 py-1">
                Download CSV
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="w-full max-w-6xl mt-6 bg-white rounded-md shadow-sm mr-6">
        <table className="w-full table-auto text-left border border-gray">
          <thead className="border-b">
            <tr>
              <th className="bg-indigo-50 text-gray-700 font-thin h-14 w-full flex">
                {filteredOptions
                  .filter((option) => option.checked)
                  .map((option) => (
                    <th
                      key={option.id}
                      className="p-4 flex-1 text-left font-medium"
                      style={{ marginRight: "20px" }}
                    >
                      {option.label}
                    </th>
                  ))}
              </th>
              <th className="p-2 flex-1 text-left font-medium bg-indigo-50">
                <div className="flex justify-end items-center">
                  <button onClick={toggleSettingsDropdown} className="relative">
                    <FaCog className="text-gray-500 text-xl" />
                  </button>
                </div>
                {isSettingsOpen && (
                  <div
                    className="w-72 border rounded-lg shadow-sm bg-white p-4"
                    style={{ position: "absolute", right: "100px" }}
                  >
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                    <div className="overflow-y-auto max-h-60">
                      <ul>
                        {filteredOptions.map((item) => (
                          <li
                            key={item.id}
                            className="flex items-center mb-2 hover:bg-gray-100 rounded-md p-1 transition"
                          >
                            <label className="flex items-center cursor-pointer w-full">
                              <input
                                type="checkbox"
                                checked={item.checked}
                                onChange={() => toggleCheckbox(item.id)}
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 rounded"
                              />
                              <span className="ml-3 text-gray-700">
                                {item.label}
                              </span>
                              {item.isNew && (
                                <span className="ml-auto bg-purple-100 text-purple-600 text-xs font-semibold px-2 py-0.5 rounded">
                                  NEW
                                </span>
                              )}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
          {filteredData.length === 0 ? (
          <div className="flex flex-col items-center justify-center text-center h-64 rounded-md mt-20">
            <svg viewBox="0 0 138 138" fill="none" width="150" mt-10 height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>
            <p className="text-gray-600 mt-4 text-lg font-medium">
              No issues found
            </p>
            <p className="text-gray-500 text-sm mt-2 mb-10">
            Try again with different search terms or filters
            </p>
          </div>
          ) : (
           <tr>
              <td colSpan="1" className="text-center py-10 text-gray-500">
                <div className="flex flex-col items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    width="200"
                    height="200"
                  >
                    <circle cx="100" cy="100" r="90" fill="#F3F4F6" />
                    <g transform="translate(50, 50)">
                      <circle
                        cx="50"
                        cy="50"
                        r="35"
                        fill="white"
                        stroke="#7B61FF"
                        strokeWidth="4"
                      />
                      <path
                        d="M30 50 h40 M50 30 v40"
                        stroke="#7B61FF"
                        strokeWidth="2"
                      />
                      <path
                        d="M50 40 l-5 10 a5 5 0 1 0 10 0 l-5 -10z"
                        fill="#7B61FF"
                      />
                      <rect
                        x="65"
                        y="65"
                        width="50"
                        height="10"
                        rx="5"
                        transform="rotate(45 65 65)"
                        fill="#7B61FF"
                      />
                    </g>
                    <g transform="translate(15, 120)">
                      <rect
                        x="0"
                        y="10"
                        width="15"
                        height="30"
                        fill="#7B61FF"
                      />
                      <rect
                        x="20"
                        y="0"
                        width="15"
                        height="40"
                        fill="#8B5CF6"
                      />
                      <rect
                        x="40"
                        y="20"
                        width="15"
                        height="20"
                        fill="#A78BFA"
                      />
                    </g>
                    <path
                      d="M20 170 Q 50 160, 80 175 T 140 170"
                      stroke="#60A5FA"
                      strokeWidth="3"
                      fill="none"
                    />
                  </svg>
                  <p className="mt-4 font-medium text-2xl text-gray-700">
                  Empower your team to report issues
                  </p>
                  <p className="mt-1 text-gray-500 text-lg">
                  Anyone can report an issue and quickly capture the critical <br/> details about what happened
                  </p>
                  <button
                    className="mt-4 px-4 py-2 border border-gray-300 bg-white text-indigo-600 rounded-md shadow hover:bg-indigo-200"
                    onClick={handleNavigate} // Trigger navigation on click
                  >
                    Add/Edit Categories
                  </button>
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IssuePage;