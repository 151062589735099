import React, { useState, useEffect, useRef } from "react";
import Missed from "./Tabs/missed";
import Manage from "./Tabs/manage";
import MySchedules from "./Tabs/mySchedules";
import ScheduleInspection from "./inspection/scheduleInspection";

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("My Schedules");
  const [showNewSchedule, setShowNewSchedule] = useState(false);

  const modalRef = useRef(null); // Ref for the popup/modal

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNewScheduleClick = () => {
    setShowNewSchedule(true);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       setShowNewSchedule(false); // Close the modal
  //     }
  //   };

  //   if (showNewSchedule) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showNewSchedule]);

  return (
    <div className="font-sans w-11/12 mx-auto">
      <div className="max-w-7xl mx-auto p-4 md:p-8 lg:p-12">
        <div className="flex justify-between items-center mb-6">
          {!showNewSchedule && (
            <>
              <h1 className="text-2xl font-semibold">Schedule</h1>
              <button
                onClick={handleNewScheduleClick}
                className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm md:text-base"
              >
                + Schedule Inspections
              </button>
            </>
          )}
        </div>

        {!showNewSchedule ? (
          // Main content with tabs
          <div>
            <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center">
              <div className="w-full">
                <div className="border-b border-gray-500">
                  <ul
                    className="flex flex-col md:flex-row md:space-x-12"
                    role="tablist"
                  >
                    <li
                      role="tab"
                      onClick={() => handleTabClick("My Schedules")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-600 px-4 py-2 rounded-sm ${
                        activeTab === "My Schedules"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      My Schedules
                    </li>
                    <li
                      role="tab"
                      onClick={() => handleTabClick("Manage Schedules")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-600 px-4 py-2 rounded-sm ${
                        activeTab === "Manage Schedules"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      Manage Schedules
                    </li>
                    <li
                      role="tab"
                      onClick={() => handleTabClick("Missed/Late Inspections")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-600 px-4 py-2 rounded-sm ${
                        activeTab === "Missed/Late Inspections"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      Missed/Late Inspections
                    </li>
                  </ul>
                </div>

                <div className="mt-4">
                  {activeTab === "My Schedules" && <MySchedules />}
                  {activeTab === "Manage Schedules" && <Manage />}
                  {activeTab === "Missed/Late Inspections" && <Missed />}
                </div>
              </div>
            </div>

            {/* Conditionally render pagination */}
            {activeTab !== "My Schedules" && (
              <div className="flex justify-end items-center mt-6 space-x-4">
                <button
                  className="text-gray-500 bg-gray-200 border border-gray-400 rounded p-2"
                  aria-label="Previous page"
                >
                  &lt;
                </button>
                <span className="text-gray-600"> 1 / 1</span>
                <button
                  className="text-gray-500"
                  aria-label="Next page"
                >
                  &gt;
                </button>
              </div>
            
            
            )}
          </div>
        ) : (
          <div ref={modalRef}>
            <ScheduleInspection />
          </div>
        )}
      </div>
    </div>
  );
};

export default Schedule;
