import React, { useState, useRef, useEffect } from "react";
import { FaEdit, FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useApi } from "../../../context/OrganizationContaxt";

const Asset = ({ asset, setAsset, assetOptions }) => {
  const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const [assetSearchTerm, setAssetSearchTerm] = useState("");
  const { assests } = useApi();
  const filteredAssetOptions = assests.filter((option) =>
    option?.displayName?.toLowerCase().includes(assetSearchTerm.toLowerCase())
  );
  const [showAssits, setShowAssests] = useState();

  // Reference for the dropdown container
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAssetDropdownOpen(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700 mt-12">
        Asset
      </label>
      <button
        onClick={() => setIsAssetDropdownOpen(!isAssetDropdownOpen)}
        className="block w-full border border-gray-300 rounded-md h-12 px-3 text-gray-400 bg-white text-left flex items-center justify-between focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
      >
        <span>{asset || "Select"}</span>
        {isAssetDropdownOpen ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </button>

      {isAssetDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-4">
          {/* Search Input with Icon */}
          <div className="relative mb-4">
            <input
              type="text"
              value={assetSearchTerm}
              onChange={(e) => setAssetSearchTerm(e.target.value)}
              placeholder="Search"
              className="w-full h-12 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Filtered Results */}
          {filteredAssetOptions.length > 0 ? (
            <ul className="max-h-48 overflow-y-auto mb-4">
              {filteredAssetOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setAsset(option?.id);
                    setShowAssests(option?.displayName);
                    setIsAssetDropdownOpen(false);
                    setAssetSearchTerm("");
                  }}
                  className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition rounded-md overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {option?.displayName}
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-500 mb-4">
              <p className="text-lg font-semibold">No assets found</p>
              <p>Try a different search term.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Asset;
