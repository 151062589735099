import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";
import { useNotification } from "../../../common/hooks/useNotification";
import { FaEllipsisV } from "react-icons/fa";
const UserCredential = () => {
  const { sendRequest, loading } = useHttp();
  const { authData } = useAuth();
  const [Licence, setAssetTypeName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const notify = useNotification();
  const [isAddTypePopupOpen, setIsAddTypePopupOpen] = useState(false);
  const [isDeactivatedChecked, setIsDeactivatedChecked] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [assetTypes, setAssetTypes] = useState([]);
  const dropdownButtonRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const handleCardClick = () => {
    navigate("/dashboard/credential-table");
  };

  const handleNavigation = (tab) => {
    const routes = {
      Users: "/dashboard/user-setting",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Companies: "/dashboard/user-company",
      Permissions: "/dashboard/user-permission",
      Credentials: "/dashboard/user-credential",
    };
  
    if (routes[tab]) {
      navigate(routes[tab]);
    } else {
      console.error(`No route found for tab: ${tab}`);
    }
  };
  

  const handleOpenAddTypePopup = () => {
    setIsAddTypePopupOpen(true); // Open the new popup
  };
  
  const handleCloseAddTypePopup = () => {
    setIsAddTypePopupOpen(false); // Close the new popup
  };
  const handleCheckboxChange = () => {
    setIsDeactivatedChecked(!isDeactivatedChecked);
  };
  
  const handleInputChange = (e) => {
    setTypeName(e.target.value); // Update the state with the new input value
  };

  const getCredentialTypes = async () => {
    try {
      await sendRequest(
        {
          url: "v1/credential/types/details",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response, "these are asset type");
          setAssetTypes(response.data.credentialTypesDetails);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const filteredAssetTypes = assetTypes.filter((type) =>
    type.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleCreateType = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/credential/type/create`,
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            credentialTypeName: typeName,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setAssetTypeName("");
              setSearchTerm("");
              handleCloseAddTypePopup();
              getCredentialTypes();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  useEffect(() => {
    getCredentialTypes();
  }, []);

  return (
    <div className="min-h-screen p-4 bg-white">
      <div className="bg-white shadow-sm">
        <div className="container mx-auto px-4 py-2 flex items-center space-x-6 h-20 border-b">
          {["Users", "Groups", "Sites", "Companies", "Permissions", "Credentials"].map(
            (tab, index) => (
              <button
                key={index}
                onClick={() => handleNavigation(tab)}
                className={`text-md font-medium ${
                  tab === "Credentials"
                    ? "text-indigo-600 border-b-2 border-indigo-600"
                    : "text-gray-600 hover:text-indigo-600"
                }`}
              >
                {tab}
              </button>
            )
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {/* Header Section */}
        <div className="flex items-center justify-between p-4 border-b border-gray-300">
          {/* Left side heading */}
          <h2 className="text-3xl font-semibold">Credentials</h2>

          {/* Right side buttons */}
          <div className="space-x-2">
            {/* <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
              Send Feedback
            </button> */}
            <button
              onClick={handleOpenAddTypePopup} // Open this popup on button click
              className={`px-4 py-2 rounded-md ${
                isAddTypePopupOpen
                  ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                  : "bg-indigo-600 text-white hover:bg-indigo-700"
              }`}
              disabled={isAddTypePopupOpen}
            >
              + Create type
            </button>
          </div>
        </div>

        {/* Add New Credential Type Popup */}
        {isAddTypePopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-bold mb-4 text-left">Create New Type</h2>
              
              {/* Label with left alignment */}
              <label className="text-left block mb-2">Enter type name</label>
              <input
                type="text"
                value={typeName} // Bind input field to the state
                onChange={handleInputChange} // Handle input changes
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleCloseAddTypePopup} // Close popup on button click
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateType}
                  className={`px-4 py-2 rounded text-white ${
                    typeName
                      ? "bg-green-600 hover:bg-green-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!typeName} // Disable the button if input is empty
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className="p-6 min-h-screen">
        {/* Header Cards */}
        <div className="grid grid-cols-5 gap-4 mb-6">
          {["Expired", "Expiring soon", "Total credentials"].map(
            (label, index) => (
              <div
                key={label}
                onClick={handleCardClick}
                className="flex items-start justify-start p-4 bg-white rounded-lg shadow-sm border border-gray-200 w-56 "
              >
                <div>
                  {label === "Expiring soon" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      color="#d97502"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V12C11 12.3788 11.214 12.725 11.5528 12.8944L15.5528 14.8944C16.0468 15.1414 16.6474 14.9412 16.8944 14.4472C17.1414 13.9532 16.9412 13.3526 16.4472 13.1056L13 11.382V6Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}
                </div>
                <div>
                  {label === "Expired" && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      color="#c62f35"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5706 7.19553C10.5326 6.61866 10.9903 6.12988 11.5684 6.12988H12.4325C13.0106 6.12988 13.4683 6.61866 13.4303 7.19553L13.025 13.3561C13.0112 13.5664 12.8366 13.7299 12.6259 13.7299H11.375C11.1643 13.7299 10.9897 13.5664 10.9759 13.3561L10.5706 7.19553Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 17.73C12.8284 17.73 13.5 17.0584 13.5 16.23C13.5 15.4016 12.8284 14.73 12 14.73C11.1716 14.73 10.5 15.4016 10.5 16.23C10.5 17.0584 11.1716 17.73 12 17.73Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  <div>
                  {label === "Total credentials" && (
                    <svg width="24" height="24" viewBox="0 0 24 24" color="#4740d4" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V7C23 7.55228 22.5523 8 22 8C21.4477 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V17C3 17.5523 3.44772 18 4 18H12C12.5523 18 13 18.4477 13 19C13 19.5523 12.5523 20 12 20H4C2.34315 20 1 18.6569 1 17V4Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.5003C5 9.94802 5.44772 9.50031 6 9.50031H10C10.5523 9.50031 11 9.94802 11 10.5003C11 11.0526 10.5523 11.5003 10 11.5003H6C5.44772 11.5003 5 11.0526 5 10.5003Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5 6.50031C5 5.94802 5.44772 5.50031 6 5.50031H16C16.5523 5.50031 17 5.94802 17 6.50031C17 7.05259 16.5523 7.50031 16 7.50031H6C5.44772 7.50031 5 7.05259 5 6.50031Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 14.5002C15.5 12.8435 16.843 11.5005 18.4997 11.5005C20.1564 11.5005 21.4994 12.8435 21.4994 14.5002C21.4994 16.1569 20.1564 17.4999 18.4997 17.4999C16.843 17.4999 15.5 16.1569 15.5 14.5002ZM18.4997 9.50049C15.7384 9.50049 13.5 11.7389 13.5 14.5002C13.5 15.8988 14.0743 17.1634 15 18.0708V22.1589C15 22.9814 15.8696 23.5129 16.6016 23.1379L18.5445 22.1425L20.4874 23.1379C21.2194 23.5129 22.089 22.9814 22.089 22.1589V17.9807C22.962 17.0806 23.4994 15.8531 23.4994 14.5002C23.4994 11.7389 21.261 9.50049 18.4997 9.50049ZM20.089 19.242C19.5897 19.4093 19.0553 19.4999 18.4997 19.4999C17.9772 19.4999 17.4734 19.4197 17 19.2711V20.6866L18.0429 20.1523C18.3578 19.9909 18.7311 19.9909 19.0461 20.1523L20.089 20.6866V19.242Z" fill="currentColor"></path></svg>
                  )}
                </div>
              </div>
                <div>
                  <p className="text-black text-2xl font-bold">0</p>
                  <p className="text-sm text-gray-500">{label}</p>
                </div>
                <span className="text-gray-400 text-xl ml-auto"><svg width="24" height="24" viewBox="0 0 24 24" color="lightgray" data-anchor="chevron-right-icon-Expired" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z" fill="currentColor"></path></svg></span>
              </div>
            )
          )}
        </div>

        {/* Search Bar and Toggle */}
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search"
            className="w-96 p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex items-center">
            <span className="mr-2 text-gray-600 text-sm">
              Include deactivated users
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                className="hidden" 
                checked={isDeactivatedChecked} 
                onChange={handleCheckboxChange} 
              />
              <span
                className={`w-10 h-5 rounded-full shadow-inner relative transition-all ${
                  isDeactivatedChecked ? "bg-blue-500" : "bg-gray-300"
                }`}
              >
                <span
                  className={`w-4 h-4 bg-white rounded-full shadow absolute left-1 top-1 transition-transform transform ${
                    isDeactivatedChecked ? "translate-x-5" : "translate-x-0"
                  }`}
                ></span>
              </span>
            </label>
          </div>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg">
            <thead>
              <tr className="bg-indigo-50">
                <th className="p-4 text-gray-800">Type</th>
                <th className="p-4 text-gray-800">Total</th>
                <th className="p-4 text-gray-800">Expiry Soon</th>
                <th className="p-4 text-gray-800">Expired</th>
                <th className="p-4 text-gray-800 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAssetTypes.length > 0 ? (
                filteredAssetTypes.map((type, index) => (
                  <tr
                    key={type.id}
                    className="hover:bg-purple-50 cursor-pointer transition duration-200 border border-gray-300"
                    onClick={() =>
                      navigate(`/dashboard/assets/types/${type.id}`)
                    }
                  >
                    <td className="p-4 text-gray-800">
                      {type.name.length > 30
                        ? `${type.name.slice(0, 30)}...`
                        : type.name}
                    </td>
                    <td className="p-4 text-gray-600">{type.total}</td>
                    <td className="p-4 text-gray-600">{type.total}</td>
                    <td className="p-4 text-gray-600">{type.total}</td>
                    <td className="p-4 text-center relative">
                      <div className="relative">
                        <button
                          ref={dropdownButtonRef}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdown(
                              openDropdown === type.id ? null : type.id
                            );
                          }}
                          className="text-gray-600 hover:text-purple-600"
                        >
                          <FaEllipsisV />
                        </button>
                        {openDropdown === type.id && (
                          <div
                            ref={dropdownRef}
                            className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                          >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("View clicked for:", type.id);
                              }}
                              className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                            >
                              Rename type
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("Edit clicked for:", type.id);
                                navigate('/dashboard/add-credentials-process');
                              }}
                              className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                            >
                             + Add credentials
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                console.log("Delete clicked for:", type.id);
                                // Confirm delete logic here
                              }}
                              className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center p-6">
                    <div className="flex flex-col items-center mt-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-12 h-12 mx-auto mb-4 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11 4a7 7 0 100 14 7 7 0 000-14zM21 21l-4.35-4.35"
                        />
                      </svg>
                      <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                        No matching found text
                      </p>
                      <p className="text-gray-600 text-sm sm:text-base md:text-sm text-center">
                        Try again with different search terms.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserCredential;
