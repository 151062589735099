import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai"; // Information icon

const Modal = ({ isOpen, onClose }) => {
  const [Input, setinput] = useState("");
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-96 p-6 relative">
        {/* Modal Header */}
        <h2 className="text-xl text-start font-semibold text-gray-800 mb-4">
          Describe topic
        </h2>

        {/* Input Label */}
        <label className="block text-start text-sm text-gray-700 mb-2">
          What's the topic of your template?
        </label>

        {/* Input Field */}
        <input
          onChange={(e) => {
            setinput(e.target.value);
          }}
          value={Input}
          type="text"
          placeholder=""
          className="w-full p-3 border border-blue-300 rounded-md outline-none focus:border-blue-500"
        />

        {/* Info Box */}
        <div className="bg-[#e5faff] rounded-md p-4 mt-4">
          <div className="flex">
            <AiOutlineInfoCircle className="text-[#0d75b5] mr-3" size={24} />
            <h1 className="text-[#0d75b5] font-semibold">
              How does this work?
            </h1>
          </div>

          <p className="text-sm text-[#0d75b5] leading-tight text-start ps-10">
            Describe your template’s topic in a few key words - then, we’ll use
            a third-party AI provider to generate suggested content. Please
            avoid submitting personal or commercially sensitive data. <br />
            <br />
            You’re the expert! After this step, review and edit the template to
            ensure it accurately reflects your needs.
          </p>
        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[#4740d4] border border-[#bfc6d4] bg-white rounded-lg hover:bg-[#e9edf6]"
          >
            Cancel
          </button>
          <button
            disabled={Input === ""}
            className={`px-4 py-2 text-white rounded-lg transition ${
              Input === ""
                ? "bg-[#b1abff] cursor-not-allowed"
                : "bg-[#6559ff] hover:bg-[#5748e5]"
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const DescribetopicModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true); // Set true by default to show modal directly.

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className="h-screen flex items-center justify-center bg-gray-100">
          <Modal isOpen={isModalOpen} onClose={handleClose} />
        </div>
      )}
    </>
  );
};

export default DescribetopicModal;
