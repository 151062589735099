import { useState , useRef, useEffect } from "react";
import axios from 'axios';
import { FaRocketchat, FaEye, FaAngleDown } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { GoFileMedia } from "react-icons/go";
import { FiYoutube } from "react-icons/fi";
import { SiAdobeacrobatreader } from "react-icons/si";
import { IoMdLink } from "react-icons/io";
import { BsQrCode } from "react-icons/bs";
import { FaComment, FaRegSmile } from "react-icons/fa";
import { BsEmojiSunglasses } from "react-icons/bs";
import { useAuth } from "../../context/AuthContext";
import { FiThumbsUp } from "react-icons/fi";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useNotification } from "../../../common/hooks/useNotification";
import useHttp from "../../../common/hooks/useHttp";
function CreateHeadsUp() {
  const notify = useNotification();
  const fileInputRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [requestAcknowledgment, setRequestAcknowledgment] = useState(true);
  const [commentsEnabled, setCommentsEnabled] = useState(true);
  const [reactionsEnabled, setReactionsEnabled] = useState(true);
  const [viewMode, setViewMode] = useState("tablet");
  const { authData } = useAuth();
  const { sendRequest } = useHttp();
  const [link, setLink] = useState(
    "https://app.au.safetyculture.com/placeholder-link"
  );
  const [showQRCode, setShowQRCode] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);


  

  const handleCreateLink = () => {
    setShowQRCode(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard");
  };

  const handleDisableLink = () => {
    setShowQRCode(false);
    alert("Link has been disabled");
  };
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  
  const handleFileChange = (event) => {
    const files = event.target.files;
    setUploadedFiles(files);  
    console.log("Selected files:", files); 
  };
  
  const handleFileUpload = async () => {
    if (!uploadedFiles || uploadedFiles.length === 0) {
      alert('Please select at least one file first.');
      return;
    }
  
    const formData = new FormData();

    Array.from(uploadedFiles).forEach((file) => {
      formData.append('files[]', file); 
    });

    // formData.append('file', file);
    formData.append('asd', "213");
  
    console.log("formdata", formData);
  
    try {
      setUploading(true);
      await sendRequest(
        {
          url: 'v1/files/upload',
          method: 'POST',
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: formData,
        },
        (response) => {
          console.log("API Response:", response);
          if (response) {
            if (response.responseCode === '200') {
              setMediaUrls((prev) => [...prev, ...response.data.publicUrls]);
              notify(response.responseDesc, 'success');
            } else {
              notify(response.responseDesc, 'error');
            }
          }
        }
      );
    } catch (error) {
      console.error('Error in handleFileUpload:', error.response || error.message);
    } finally {
      setUploading(false);
    }
  };
  


  const handleSave = async () => {
    try {
      await sendRequest(
        {
          url: 'v1/heads-up/create',
          method: 'POST',
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            title: title,
            description: description,
            mediaUrls: mediaUrls, // Include the uploaded file URLs here
            commentsEnabled: true,
            reactionsEnabled: true,
            acknowledgementRequired: true,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode === '200') {
              setTitle('');
              setDescription('');
              setMediaUrls([]); // Reset media URLs
              notify(response.responseDesc, 'success');
            } else {
              notify(response.responseDesc, 'error');
            }
          }
        }
      );
    } catch (error) {
      console.error('Error in handleSave:', error.response);
    }
  };
  const handleChange = (e) => {
    const regex = /^[A-Za-z\s]*$/;
    const value = e.target.value;
    const name = e.target.name; 
  
    if (regex.test(value)) { 
      if (name === "title") {
        setTitle(value); 
      } else if (name === "description") {
        setDescription(value); 
      }
    } else {
      console.log("Special characters are not allowed!");
    }
  };
  
  return (
    <div className="min-h-screen bg-[#e9edf6] ">
      {/* Header Section */}
      <div className="flex items-center space-x-2 bg-white py-2">
        <button>
          <FaChevronLeft className="w-4 h-4 text-gray-700" />
        </button>
        <h1 className="text-lg ">Back</h1>
      </div>

      {/* Main Container */}
      <div className="flex mt-4 space-x-6 px-6">
        {/* Left Section */}
        <div className="w-1/2 bg-[#f8f9fc] p-6 rounded-lg shadow-md">
          {/* Media Upload Section */}
          <div className="h-[73vh] overflow-y-auto">
            {/* <div
              className="flex flex-col items-center mb-4 bg-white py-4 cursor-pointer"
              onClick={handleDivClick}
            >
              <div className="flex space-x-2">
                <button className="bg-gray-200 p-2 rounded-full">
                  <GoFileMedia className="w-6 h-6" />
                </button>
                <button className="bg-gray-200 p-2 rounded-full">
                  <FiYoutube className="w-6 h-6" />
                </button>
                <button className="bg-gray-200 p-2 rounded-full">
                  <SiAdobeacrobatreader className="w-6 h-6" />
                </button>
              </div>
              <p className="text-sm text-gray-600 mt-2">+ Add media</p>
              <p className="text-xs text-gray-500">
                Up to 6 media items and 3-minute videos
              </p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple 
                accept="image/*,video/*,application/pdf" 
              />
            </div> */}
            <div>
              <h2>Upload a File</h2>
              <input
                type="file"
                ref={fileInputRef}
                multiple // Allow multiple file selection
                onChange={handleFileChange}
              />
              <button onClick={handleFileUpload} disabled={uploading || !uploadedFiles}>
                {uploading ? 'Uploading...' : 'Upload'}
              </button>
              {uploading && <p>Uploading files, please wait...</p>}
              {uploadResponse && (
                <div>
                  <h3>Upload Response:</h3>
                  <pre>{JSON.stringify(uploadResponse, null, 2)}</pre>
                </div>
              )}
            </div>

            {/* Form Fields */}
            <div className="space-y-4 text-start">
              <label className="block">
                <span className="text-gray-700">Title*</span>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength={130}
                  className="mt-1 block w-full border-gray-300 py-2 ps-3 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Title"
                />
              </label>
              <label className="block">
                <span className="text-gray-700">Description*</span>
                <textarea
                  className="mt-1 block w-full border-gray-300 ps-3 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  rows="4"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={130}
                  placeholder="Description"
                ></textarea>
              </label>
            </div>
            <div className="text-start pt-3">
              <h2 className="text-xl font-semibold">People</h2>
              <h2 className="text-md pt-2">Assign to*</h2>
              <select className="w-full mt-2 border-2 py-1 rounded-md bg-[#f8f9fc]">
                <option>Choose people or sites</option>
                <option>Sites</option>
                <option>Users</option>
                <option>Groups</option>
              </select>
            </div>
            <div className="mb-6 flex justify-between text-start mt-4">
              <div>
                <h3 className="text-gray-800 text-lg font-semibold">
                  Share externally
                </h3>
                <p className="text-sm text-gray-600">
                  Anyone with this link can view
                </p>
              </div>

              <button
                className="text-indigo-600 text-sm font-semibold flex items-center space-x-1 mt-1"
                onClick={() => setShowQRCode(true)}
              >
                <IoMdLink className="me-1" />

                <span>Create link</span>
              </button>
            </div>
            {showQRCode && (
              <>
                <div className="flex items-center space-x-2">
                  <BsQrCode className="w-24 h-24" />
                  <input
                    type="text"
                    value={link}
                    readOnly
                    className="w-full border border-gray-300 rounded-lg p-2"
                  />
                  <button
                    onClick={handleCopy}
                    className="bg-indigo-600 text-white px-3 py-2 rounded-md"
                  >
                    Copy
                  </button>
                </div>

                <div className="flex space-x-4 mt-1 mb-5">
                  <button
                    onClick={() => alert("Downloading QR Code...")}
                    className="bg-white border border-[#bfc6d4] text-gray-700 px-4 py-2 rounded-lg hover:bg-[#e0e4ff]"
                  >
                    Download QR code
                  </button>
                  <button
                    onClick={handleDisableLink}
                    className="bg-white  border border-[#bfc6d4] text-gray-700 px-4 py-2 rounded-lg hover:bg-[#e0e4ff]"
                  >
                    Disable link
                  </button>
                </div>
              </>
            )}
            <h3 className="text-xl text-start font-semibold text-gray-800 mb-4">
              Settings
            </h3>
            {/* Settings Section */}
            <div className="border border-gray-300 rounded-lg p-4">
              {/* Request Acknowledgment */}
              <div className="flex items-center justify-between py-2 border-b border-gray-200">
                <span className="text-gray-700">Request Acknowledgment</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={requestAcknowledgment}
                    onChange={() =>
                      setRequestAcknowledgment(!requestAcknowledgment)
                    }
                  />
                  <div className="w-10 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-indigo-500 peer-checked:bg-indigo-600"></div>
                  <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition peer-checked:translate-x-full"></div>
                </label>
              </div>

              {/* Comments Enabled */}
              <div className="flex items-center justify-between py-2 border-b border-gray-200">
                <span className="text-gray-700">Comments enabled</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={commentsEnabled}
                    onChange={() => setCommentsEnabled(!commentsEnabled)}
                  />
                  <div className="w-10 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-indigo-500 peer-checked:bg-indigo-600"></div>
                  <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition peer-checked:translate-x-full"></div>
                </label>
              </div>

              {/* Reactions Enabled */}
              <div className="flex items-center justify-between py-2">
                <span className="text-gray-700">Reactions enabled</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={reactionsEnabled}
                    onChange={() => setReactionsEnabled(!reactionsEnabled)}
                  />
                  <div className="w-10 h-6 bg-gray-200 rounded-full peer peer-focus:ring-2 peer-focus:ring-indigo-500 peer-checked:bg-indigo-600"></div>
                  <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition peer-checked:translate-x-full"></div>
                </label>
              </div>
            </div>
          </div>

          {/* Publish Button with Dropdown */}
          <div className="mt-4 relative flex ">
            <button className="bg-[#6559ff] text-white py-2 px-4 rounded-md text-center w-full"
            onClick={handleSave}

            >
              Publish
            </button>
            <div className="bg-[#6559ff] py-2 px-3 ms-2 rounded-md">
              <FaAngleDown
                className="w-5 h-5    text-white"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              />
            </div>
            {dropdownOpen && (
              <div className="absolute w-full mt-1 bg-white border rounded-md shadow-lg z-10">
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700">
                  Draft
                </button>
                <button className="block w-full text-left px-4 py-2 text-sm text-gray-700">
                  Schedule
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Right Section - Preview */}
        <div className="w-1/2  p-6 ">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Preview</p>
            <div>
              <div className="flex  mb-4">
                <button
                  className={`px-4 py-2 rounded ${
                    viewMode === "tablet"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  onClick={() => setViewMode("tablet")}
                >
                  Tablet
                </button>
                <button
                  className={`px-4 py-2 rounded ${
                    viewMode === "mobile"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  onClick={() => setViewMode("mobile")}
                >
                  Mobile
                </button>
              </div>
            </div>
          </div>
          <div
            className={`bg-white p-6 rounded-lg shadow-lg py-10 ${
              viewMode === "tablet" ? "w-[500px]" : "w-[400px]"
            }`}
          >
            <div className="bg-white ">
              <div className="flex items-center justify-end mb-4 border-b-2 pb-4">
                <button className="bg-[#6559ff] text-white py-2 px-4 rounded-lg flex">
                    <IoCheckmarkDoneCircleOutline className="me-1 mt-1"/>
                  Acknowledge
                </button>
              </div>

              <div className="flex items-center space-x-3 mb-6 mt-5">
                <div className="bg-gray-300 w-8 h-8 rounded-full flex items-center justify-center text-white">
                  UK
                </div>
                <div>
                  <p className="text-sm font-semibold text-start">Untitled</p>
                  <p className="text-xs text-gray-500 text-start">
                    uzair khan • 0 minutes ago
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4 text-gray-500 text-sm mb-6">
                <div className="flex items-center space-x-1">
                  <FaEye className="w-4 h-4" />
                  <span>0 views</span>
                </div>
                <span>|</span>
                <div className="flex items-center space-x-1">
                  <FaComment className="w-4 h-4" />
                  <span>0 acknowledged</span>
                </div>
                <span>|</span>
                <div className="flex items-center space-x-1">
                  <FaComment className="w-4 h-4" />
                  <span>0 comments</span>
                </div>
              </div>

              <div className="flex items-center space-x-2 mb-6 mt-6">
                <button className="bg-gray-100 p-2 rounded-full">
                  <BsEmojiSunglasses className="w-5 h-5 text-yellow-500" />
                </button>
                <button className="bg-gray-100 p-2 rounded-full">
                  <FiThumbsUp className="w-5 h-5 text-yellow-500" />
                </button>
                <button className="bg-gray-100 p-2 rounded-full">
                  <FaRegSmile className="w-5 h-5 text-yellow-500" />
                </button>
              </div>

              <div className="bg-yellow-100 p-4 rounded-lg text-gray-700 mb-4 text-sm my-2">
                This space looks a bit empty 👀 add a{" "}
                <span className="text-blue-500 cursor-pointer">
                  comment to start a chat with your teammates
                </span>
                .
              </div>

              <textarea
                className="w-full border border-gray-300 rounded-md p-2 text-sm resize-none mt-2"
                rows="2"
                placeholder="Write a comment..."
              ></textarea>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
}

export default CreateHeadsUp;
