import React, { useState } from "react";
import Assign from "./assign";
import Template from "./template";
import Site from "./site";
import Asset from "./asset";
import Frequency from "./frequency";
import Time from "./time";
import Title from "./title";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import useHttp from "../../../../common/hooks/useHttp";
import { useNotification } from "../../../../common/hooks/useNotification";

const ScheduleInspection = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  const { sendRequest } = useHttp();

  const notify = useNotification();
  const [template, setTemplate] = useState("");
  const [site, setSite] = useState("");
  const [asset, setAsset] = useState("");
  const [assignee, setAssignee] = useState([]);
  const [frequency, setFrequency] = useState("Every Day");
  const [allowLateSubmission, setAllowLateSubmission] = useState(true);
  const [title, setTitle] = useState("");
  const [showTemplate, setShowTemplate] = useState("");
  const [showSite, setShowSite] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showNameAsign, setSshoNameofAsign] = useState();
  const [showModal, setShowModal] = useState(false);
  const [endDate, setEndDate] = useState(new Date());

  const handleCancel = () => {
    setShowModal(true); // Show the modal
  };

  const handleDiscard = () => {
    setShowModal(false); // Close the modal
    navigate(-1); // Go back two pages in the browser history
  };
  

  const extractTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const frequencyOptions = ["DAILY", "MONTHLY", "YEARLY", "QUARTERLY", "WEEKLY"];
  const formattedStartTime = extractTime(startDate);
  const formattedEndTime = extractTime(endDate);

  const handleSubmit = async () => {
    try {
      await sendRequest(
        {
          url: "v1/schedule/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            templateId: template,
            siteId: site,
            assetId: null,
            assigneeId: assignee,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            frequency: frequency,
            title: title,
            allowLateSubmission: allowLateSubmission,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode === "200") {
              notify(response.responseDesc, "success");
              navigate("/dashboard/schedule");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  console.log(startDate, endDate);

  return (
    <>
      <div className="text-left ml-36">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">Schedule Inspection</h2>
      </div>
      <div className="flex justify-center items-center text-left p-4">
        <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg shadow-lg p-8">
          <div className="space-y-4">
            {/* Template Dropdown */}
            <Template
              setShowTemplate={setShowTemplate}
              template={template}
              setTemplate={setTemplate}
              showTamplateNAmes={showTemplate}
            />

            {/* Site Dropdown */}
            <Site
              setShoSite={setShowSite}
              siteShow={showSite}
              site={site}
              setSite={setSite}
              siteOptions={[]}
            />

            {/* Asset Dropdown */}
            <Asset asset={asset} setAsset={setAsset} assetOptions={[]} />

            {/* Assignee Dropdown */}
            <Assign
              setSshoNameofAsign={setSshoNameofAsign}
              nameofAsign={showNameAsign}
              assignee={assignee}
              setAssignee={setAssignee}
            />

            {/* Frequency Dropdown */}
            <Frequency
              frequency={frequency}
              setFrequency={setFrequency}
              setTitle={setTitle}
              frequencyOptions={frequencyOptions}
            />

            {/* Time Range */}
            <Time
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />

            {/* Allow Late Submission */}
            <div className="flex items-center mt-6">
              <input
                type="checkbox"
                id="allowLateSubmission"
                checked={allowLateSubmission}
                onChange={(e) => setAllowLateSubmission(e.target.checked)}
                className="h-4 w-4 text-indigo-700 border border-black rounded focus:ring-indigo-500"
              />
              <label htmlFor="allowLateSubmission" className="ml-2 text-sm text-gray-700">
                Allow inspections to be submitted after the due date
              </label>
              <span className="relative group">
                <svg
                  viewBox="0 0 24 24"
                  width="21"
                  className="ml-1"
                  height="21"
                  focusable="false"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path
                    fill="#545f70"
                    d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                  ></path>
                </svg>
                <span className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 w-max px-3 py-1 bg-gray-700 text-white text-left text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                  Assignees can submit overdue<br/> inspections until the start of the next<br/> occurrence.
                </span>
              </span>
            </div>

            {/* Title Input */}
            <div>
              <Title title={title} setTitle={setTitle} />
            </div>

            <div className="w-full border-t border-gray-300 mb-6"></div>

            {/* Buttons */}
            <div className="flex justify-start space-x-4 mt-6">
              <button
                onClick={handleSubmit}
                disabled={
                  template === "" || site === "" || assignee.length === 0
                    ? true
                    : false
                }
                className={`px-4 py-2 rounded-md transition text-white 
                ${
                  template === "" || site === "" || assignee.length === 0
                    ? " bg-[#b1abff] hover:bg-[#b1abff] cursor-not-allowed"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }`}
              >
                Create
              </button>

              <button
                type="button"
                className="border border-gray-300 text-indigo-800 px-4 py-2 rounded-md hover:bg-indigo-50 transition"
                onClick={handleCancel}
              >
                Cancel
              </button>

              {/* Modal */}
              {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-2/5 relative">
                    {/* Close Icon */}
                    <button
                      className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                      onClick={() => setShowModal(false)} // Close modal on click
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>

                    {/* Modal Content */}
                    <h2 className="text-lg font-semibold mb-4">Discard unsaved changes?</h2>
                    <p className="text-gray-600 mb-6">
                      You’ve made changes to this page that haven’t been saved yet. All changes will be lost if you leave this page.
                    </p>

                    {/* Modal Buttons */}
                    <div className="flex justify-end space-x-4">
                      <button
                        className="px-4 py-2 border rounded-md text-indigo-500 hover:bg-gray-200 transition"
                        onClick={() => setShowModal(false)} // Close modal
                      >
                        Cancel
                      </button>
                      <button
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
                        onClick={handleDiscard}
                      >
                        Yes, discard changes
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleInspection;
