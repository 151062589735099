import React from "react";

const Title = ({ title, setTitle }) => {
  const handleTitleChange = (e) => {
    const value = e.target.value;
    // Capitalize the first letter and make the rest lowercase
    const formattedTitle = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    setTitle(formattedTitle);
  };

  return (
    <div className="mt-6">
      <label className="block text-sm font-medium text-gray-700">Title</label>
      <input
        type="text"
        value={title}
        onChange={handleTitleChange} // Handle the title change
        placeholder="Title field"
        className="mt-1 block w-full border border-gray-300 rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
      />
    </div>
  );
};

export default Title;
