import { useApi } from "../../../context/OrganizationContaxt";
import React, {useEffect, useRef ,useState } from "react";
import Filter from "./filter";
import { BiCross } from "react-icons/bi";
import { useAuth } from "../../../context/AuthContext";
const ScheduleImage1 = require("../../../../assets/schedule/3.jpg");
const ScheduleImage2 = require("../../../../assets/schedule/4.jpg");
const Missed = () => {
  const { sheduleMissed, getTamplateList } = useApi();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showSelectedTemplate, setShowSelectedTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const dropdownRef = useRef(null);
  const handleClearSearch = () => {
    setSearchValue("");
  };
  const removeFilter = () => {
    setSelectedTemplate("");
    setShowSelectedTemplate([]);
    setTitle("");
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showTitle, setTitle] = useState();
  const { authData } = useAuth();

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsFilterOpen(false); // Close the dropdown if clicked outside
    }
  };

  // Attach event listener on mount and remove it on unmount
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const filteredData = sheduleMissed?.data?.schedules.filter(
    (item) =>
      item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.assigneeName?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleTemplateSelect = (templateTitle) => {
    setTitle(templateTitle);
    // Filter schedules based on the selected template's title
    const filtered = sheduleMissed?.data?.schedules.filter(
      (schedule) => schedule?.templateTitle === templateTitle
    );
    console.log(filtered);
    setSelectedTemplate(filtered);
    setShowSelectedTemplate(filtered);
    setIsFilterOpen(false); // Close the
  };
  const options = [
    { value: "", label: "Today" },
    { value: "30", label: "Last 30 days" },
    { value: "60", label: "Last 60 days" },
    { value: "90", label: "Last 90 days" },
    { value: "4", label: "weeks" },
    { value: "this", label: " this month" },
    { value: "last", label: " last month" },
    { value: "Year to", label: " Year to date" },
  ];

  const tableHeaders = ["Title", "Status", "Template", "Assignees", "Due on"];

  return (
    <div className="px-2 sm:px-4 md:px-8 lg:px-0">
      <div className="mt-6 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="relative w-full sm:w-64">
      {/* Search Icon */}
      <svg
        width="21"
        height="21"
        viewBox="0 0 24 24"
        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2931 2.37207C5.96319 2.37207 2.45312 5.88213 2.45312 10.212C2.45312 14.5419 5.96319 18.052 10.2931 18.052C12.0558 18.052 13.6826 17.4702 14.992 16.4883L19.8393 21.3355C20.2298 21.726 20.863 21.726 21.2535 21.3355C21.644 20.945 21.644 20.3118 21.2535 19.9213L16.4271 15.0949C17.4949 13.7554 18.133 12.0582 18.133 10.212C18.133 5.88213 14.6229 2.37207 10.2931 2.37207ZM4.45312 10.212C4.45312 6.9867 7.06776 4.37207 10.2931 4.37207C13.5184 4.37207 16.133 6.9867 16.133 10.212C16.133 13.4373 13.5184 16.052 10.2931 16.052C7.06776 16.052 4.45312 13.4373 4.45312 10.212Z"
          fill="currentColor"
        ></path>
      </svg>

      {/* Search Input */}
      <input
        type="text"
        value={searchValue}
        placeholder="Search"
        onChange={(e) => setSearchValue(e.target.value)}
        className=" border rounded-md p-2 pl-10 w-full focus:border-indigo-500 focus:outline-none"
      />

      {/* Clear Icon (X) */}
      {searchValue && (
        <button
          onClick={handleClearSearch}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6L18 18M6 18L18 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </div>

        <div className="relative w-full sm:w-auto">
          <select
            onClick={handleDropdownClick}
            onBlur={() => setIsDropdownOpen(false)}
            onChange={handleOptionChange}
            className="border rounded-md bg-transparent hover:border-indigo-400 focus:bg-indigo-200 focus:text-white p-2 pr-8 w-full sm:w-auto appearance-none"
            >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {/* Arrow Icon */}
          <span
            className={`absolute right-3 top-4 transform ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            } transition-transform duration-200`}
          >
           <svg viewBox="0 0 24 24" width="13" height="13" class="date-range-picker-styled__StyledArrowDownSvg-iauditor__sc-u5o6ce-2 iEpiSc" focusable="false"><path d="M12.819 17.633l8.866-9.52a1.323 1.323 0 0 0-.028-1.745 1.113 1.113 0 0 0-1.625-.03l-7.663 8.228a.509.509 0 0 1-.755 0L3.968 6.354a1.113 1.113 0 0 0-1.625.03 1.323 1.323 0 0 0-.028 1.745l8.85 9.504c.22.235.517.368.827.367a1.12 1.12 0 0 0 .827-.367z" fill="#545f70" fill-rule="nonzero"></path></svg>
          </span>
        </div>

        <div>
          <div className="flex items-center  ">
            <div className="relative" ref={dropdownRef}>
              <div className="flex gap-5 text-center ">
                <button
                  onClick={toggleFilterDropdown}
                  className="text-[#6559ff] hover:text-blue-600 hover:bg-indigo-50 px-2 py-2 rounded-md"
                >
                  + Add filter
                </button>
                {showSelectedTemplate?.length === 0 && (
                  <>
                    {showTitle === "" ? (
                      <></>
                    ) : (
                      <button
                        onClick={removeFilter}
                        class=" gap-3 text-center items-center text-blue-800 text-xs font-medium me-2  flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                      >
                        {showTitle}
                        {/* {showTitle === "" ? <></> : <BiCross />} */}
                      </button>
                    )}
                  </>
                )}
                {showSelectedTemplate?.map((item, index) => (
                  <>
                    <button
                      onClick={removeFilter}
                      class="bg-blue-100 gap-3 text-center items-center text-blue-800 text-xs font-medium me-2  flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                    >
                      {item?.title}
                      <BiCross />
                    </button>
                  </>
                ))}
              </div>
              {isFilterOpen && (
                <div className="absolute mt-2 h-80 overflow-scroll w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-start text-gray-700">
                    {getTamplateList?.data?.templates.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleTemplateSelect(item?.title)}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        {item?.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              {tableHeaders.map((header) => (
                <th
                  key={header}
                  className="p-2 text-xs sm:text-sm md:text-base"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sheduleMissed ? (
              selectedTemplate === "" ? (
                searchValue === "" ? (
                  sheduleMissed?.data?.schedules.length > 0 ? (
                    sheduleMissed?.data?.schedules?.map((item, index) => (
                      <tr key={index}>
                        {/* Yahan apne schedule ki information ko render karein */}
                        <td>{item?.title}</td>
                        <td>
                          <span   className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">

                            {item?.status}
                          </span>
                        </td>
                        <td>{item?.templateName}</td>
                        <td> {item?.assignees} </td>
                        <td> {item?.dueDate} </td>
                        {/* Aap jo bhi fields display karna chahte hain */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center p-4 sm:p-6">
                        <div className="flex flex-col items-center">
                          <svg viewBox="0 0 138 138" fill="none" className="mt-4" width="150" height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>
                          <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                            No results
                          </p>
                          <p className="text-gray-500 text-xs sm:text-sm mb-24">
                          Try again with different search terms or filters
                          </p>
                        </div>
                      </td>
                    </tr>
                  )
                ) : filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      {/* Yahan apne schedule ki information ko render karein */}
                      <td>{item?.title}</td>
                      <td>
                        <span   className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">

                          {item?.status}
                        </span>
                      </td>
                      <td>{item?.templateName}</td>
                      <td> {item?.assignees} </td>
                      <td> {item?.dueDate} </td>
                      {/* Aap jo bhi fields display karna chahte hain */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center text-gray-600">
                      <div className="flex justify-center items-center">
                      <svg viewBox="0 0 138 138" fill="none" className="mt-4" width="150" height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>
                      </div>
                      <p className="text-gray-600 mt-4 text-lg font-medium">
                        No results
                      </p>
                      <p className="text-gray-500 text-sm mt-2">
                      Try again with different search terms or filters
                      </p>
                    </td>
                  </tr>
                )
              ) : selectedTemplate?.length > 0 ? (
                selectedTemplate?.map((item, index) => (
                  <tr key={index}>
                    {/* Yahan apne schedule ki information ko render karein */}
                    <td>{item?.title}</td>
                    <td>
                      <span className="bg-[#fffae5] text-[#c8762e] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full border border-[#c8762e]">

                        {item?.status}
                      </span>
                    </td>
                    <td>{item?.templateName}</td>
                    <td> {item?.assignees} </td>
                    <td> {item?.dueDate} </td>
                    {/* Aap jo bhi fields display karna chahte hain */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center text-gray-600">
                    <div className="flex justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 120 120"
                      >
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="57.266"
                          opacity=".35"
                          transform="rotate(-45.001 84.45 88.451)"
                        ></rect>
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="53.266"
                          fill="#0037ff"
                          transform="rotate(-45.001 84.45 84.451)"
                        ></rect>
                        <circle cx="49" cy="53" r="37" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="37" fill="#0075ff"></circle>
                        <circle cx="49" cy="53" r="28" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="28" fill="#a4e2f1"></circle>
                      </svg>
                    </div>
                    <p className="text-gray-600 mt-4 text-lg font-medium">
                      No results
                    </p>
                    <p className="text-gray-500 text-sm mt-2">
                    Try again with different search terms or filters
                    </p>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={6} className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 120 120"
                      >
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="57.266"
                          opacity=".35"
                          transform="rotate(-45.001 84.45 88.451)"
                        ></rect>
                        <rect
                          width="15"
                          height="62.367"
                          x="76.95"
                          y="53.266"
                          fill="#0037ff"
                          transform="rotate(-45.001 84.45 84.451)"
                        ></rect>
                        <circle cx="49" cy="53" r="37" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="37" fill="#0075ff"></circle>
                        <circle cx="49" cy="53" r="28" opacity=".35"></circle>
                        <circle cx="49" cy="49" r="28" fill="#a4e2f1"></circle>
                      </svg>
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No scheduled inspections due in the next 7 days
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm md:text-base text-center">
                      You don't have any scheduled inspections starting in the
                      next 7 days. Learn more.
                    </p>
                  </div>
                </td>
              </tr>
            )}

            {/* {sheduleMissed.data.schedules.length > 0 ? (
              sheduleMissed.data.schedules.map((item, index) => (
                <tr key={index}>
                  Yahan apne schedule ki information ko render karein
                  <td>{item?.title}</td>
                  <td>
                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
                      {item?.status}
                    </span>
                  </td>
                  <td>{item?.templateName}</td>
                  <td> {item?.assignees} </td>
                  <td> {item?.dueDate} </td>
                  Aap jo bhi fields display karna chahte hain
                </tr>
              ))
            ) : selectedOption ? (
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage1}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base">
                      No results
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      Try again with different search terms or filters.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage2}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No missed schedules
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      Yay! No scheduled inspections have been missed.
                    </p>
                  </div>
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Missed;
