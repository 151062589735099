import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";

const Frequency = ({ frequency, setFrequency, frequencyOptions, setTitle }) => {
  const [isFrequencyDropdownOpen, setIsFrequencyDropdownOpen] = useState(false);
  const [frequencySearchTerm, setFrequencySearchTerm] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState(frequency);
  const dropdownRef = useRef(null);

  const filteredFrequencyOptions = frequencyOptions.filter((option) =>
    option.toLowerCase().includes(frequencySearchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFrequencyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700 mt-12 mb-2">
        How often
      </label>
      <button
        onClick={() => setIsFrequencyDropdownOpen(!isFrequencyDropdownOpen)}
        className="block w-full border border-gray-300 rounded-md h-12 px-3 bg-white text-left flex items-center justify-between focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
      >
        <span>
          {frequency
            ? frequency.charAt(0).toUpperCase() + frequency.slice(1).toLowerCase()
            : "Every Day"}
        </span>
        {isFrequencyDropdownOpen ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </button>


      {isFrequencyDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-4">
          {/* Search Input with Icon */}
          {/* <div className="relative mb-4">
            <input
              type="text"
              value={frequencySearchTerm}
              onChange={(e) => setFrequencySearchTerm(e.target.value)}
              placeholder="Search"
              className="w-full h-12 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div> */}

          {/* Filtered Results */}
          {filteredFrequencyOptions.length > 0 ? (
            <ul className="max-h-48 overflow-y-auto mb-4">
              {filteredFrequencyOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setFrequency(option);
                    setSelectedFrequency(option);
                    setIsFrequencyDropdownOpen(false);
                    setFrequencySearchTerm("");
                    setTitle(option);
                  }}
                  className={`px-3 py-2 cursor-pointer transition rounded-md 
                    ${selectedFrequency === option ? 'bg-indigo-100 text-black' : 'hover:bg-indigo-50 hover:text-black'}`}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()}  {/* Capitalize first letter */}
                </li>
              
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-500 mb-4">
              <p className="text-lg font-semibold">No frequencies found</p>
              <p>Try a different search term.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Frequency;
