import React, { useState } from "react";
import TemplatePage from "./template/home";
import GlobalResponseSets from "./responses";
import Archieve from "./archieve/archive";
import TemplateLibrary from "./publicLibrary/publicLibrary";
import { useNavigate } from "react-router-dom";
const Templates = () => {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Templates");
  const renderContent = () => {
    switch (activeTab) {
      case "Templates":
        return <TemplatePage onSort={handleSort} />
      case "Responses":
        return <GlobalResponseSets />;
      case "Archive":
        return <Archieve />;

      default:
        return null;
    }
  };

  const handleSort = (isAscending) => {
    console.log("Sorting order:", isAscending ? "Ascending" : "Descending");
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className="container mx-auto ">
      {/* Tabs */}
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["Templates", "Responses", "Public Library", "Archive"].map(
            (tab) => (
              <button
                key={tab}
                onClick={() => {
                  tab === "Public Library"
                    ? navigate("/dashboard/template-library")
                    : setActiveTab(tab);
                }}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : ""
                }`}
              >
                {tab}
              </button>
            )
          )}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Templates;
