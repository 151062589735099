import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import { useEffect } from "react";
import { useApi } from "../../context/OrganizationContaxt";
import { useAuth } from "../../context/AuthContext";
import { useNotification } from "../../../common/hooks/useNotification";
import useHttp from "../../../common/hooks/useHttp";
const AssetPrompt = () => {
  const navigate = useNavigate(); // Hook for navigation

  const { assetTypes, assetFields } = useApi();
  console.log(assetFields);

  const { authData } = useAuth();
  console.log(assetTypes);
  return (
    <>
      {assetTypes && assetTypes.length == 0 ? (
        <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
          {/* Back arrow with text */}
          <div className="absolute top-4 left-4 flex items-center cursor-pointer">
            <FaArrowLeft className="text-black text-2xl mr-2" />
            <span className="text-black text-lg font-semibold">Add Assets</span>
          </div>

          <div className="max-w-3xl w-full bg-white p-6 rounded-lg shadow-md text-center">
            <h2 className="text-xl text-gray-800 mb-4">
              No asset types created yet
            </h2>
            <p className="text-gray-600 mb-4">
              You'll need to create an asset type before adding assets. Use
              types to categorize your assets and define the details you want to
              capture.
            </p>
            <button
              onClick={() => navigate("/dashboard/assets/types")}
              className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow hover:bg-indigo-600"
            >
              + Create a type
            </button>
          </div>
        </div>
      ) : (
        <>
          <AssetForm authData={authData} assetTypes={assetTypes} />
        </>
      )}
    </>
  );
};

const AssetForm = ({ assetTypes, authData }) => {
  const [displayName, setDisplayName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [AssetTypeFields, setAssetTypeFields] = useState([]);
  const [fieldValues, setFieldValues] = useState({}); // Track user inputs for dynamic fields
  const notify = useNotification();
  const { sendRequest } = useHttp();

  const assetTypeId = selectedType?.value;

  // Fetch asset type fields dynamically based on selected type
  const getAssetTypeFields = async () => {
    if (!assetTypeId) return;

    try {
      const response = await sendRequest({
        url: `v1/asset/type/fields?assetTypeId=${assetTypeId}`,
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
      });
      console.log(response);

      if (response && response.data) {
        setAssetTypeFields(response.data.assetTypeFields);
      }
    } catch (error) {
      console.error("Error fetching asset fields:", error);
    }
  };

  useEffect(() => {
    if (assetTypeId) {
      getAssetTypeFields();
    }
  }, [assetTypeId]);

  // Handle dynamic input changes
  const handleFieldChange = (fieldId, value) => {
    setFieldValues((prev) => ({ ...prev, [fieldId]: value }));
  };

  const handleSubmit = async () => {
    // Prepare the dynamic fields array
    const fields = AssetTypeFields.map((field) => ({
      fieldId: field.fieldId,
      name: field.name,
      valueType: field.valueType,
      stringValue: fieldValues[field.value] || "", // Get user input or fallback to an empty string
    }));

    const payload = {
      assetTypeId: assetTypeId,
      code: uniqueId,
      displayName: displayName,
      fields: fields,
    };
    console.log(payload);

    try {
      const response = await sendRequest({
        url: "v1/asset/create",
        method: "POST",
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
        payload: payload,
      });

      if (response && response.responseCode === "200") {
        notify(response.responseDesc, "success");
      } else {
        notify(response.responseDesc, "error");
      }
    } catch (err) {
      console.error("Error creating asset:", err);
    }
  };

  return (  
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="mb-4">
          <label className="block text-left text-gray-700 text-sm font-medium mb-1">
            Unique ID
          </label>
          <input
            type="text"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            placeholder="Enter unique ID"
            className="w-full border rounded-md p-2 text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-left text-sm font-medium mb-1">
            Display Name
          </label>
          <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter display name"
            className="w-full border rounded-md p-2 text-sm"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm text-left font-medium mb-1">
            Select Type
          </label>
          <select
            onChange={(e) =>
              setSelectedType({
                value: e.target.value,
                index: e.target.selectedIndex,
              })
            }
            className="w-full border rounded-md p-2 text-sm"
          >
            <option disabled selected>
              Select type
            </option>
            {assetTypes?.map((type) => (
              <option key={type.assetType.id} value={type.assetType.id}>
                {type.assetType.name}
              </option>
            ))}
          </select>
        </div>

        {AssetTypeFields?.map((field) => (
          <div key={field.fieldId} className="mb-4">
            <label className="block text-left text-gray-700 text-sm font-medium mb-1">
              {field.name}
            </label>
            <input
              type="text"
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
              placeholder={`Enter ${field.name}`}
              className="w-full border rounded-md p-2 text-sm"
            />
          </div>
        ))}

        <button
          onClick={handleSubmit}
          className="w-full bg-indigo-500 text-white py-2 rounded-md hover:bg-indigo-600"
        >
          Save
        </button>
      </div>
    </div>
  );
};
export default AssetPrompt;

