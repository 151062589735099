import { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useApi } from "../../../context/OrganizationContaxt";
import { useNavigate } from "react-router-dom";

const Assign = ({
  assignee,
  setAssignee,
  setSshoNameofAssign,
  nameofAssign,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [setSelected] = useState([]);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [showNameOfAssign, setShowNameOfAssign] = useState(nameofAssign || "");
  const { users } = useApi();
  const navigate = useNavigate();
  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  
  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const handleAddUser = () => {
    // Logic to add user to team
    closePopup();
  };

  const handleSelect = (value) => {
    if (value === "Users") {
      setShowUserOptions(true);
      setShowGroupOptions(false);
    } else if (value === "Groups") {
      setShowGroupOptions(true);
      setShowUserOptions(false);
    } else {
      setSelected(value);
      setShowUserOptions(false);
      setShowGroupOptions(false);
      setIsOpen(false);
    }
  };

  const handleDoneClick = () => {
    setShowUserOptions(false);
    setShowGroupOptions(false);
    setIsOpen(false);
  };

  // Close dropdown or popup when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setIsOpen(false);
        setShowUserOptions(false);
        setShowGroupOptions(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () =>
      document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <div className="mt-2 dropdown-container">
      <label className="block text-sm font-medium text-gray-700 mt-12">
        Assigned to
      </label>
      <div className="relative">
        {/* Dropdown Button */}
        <button
          onClick={toggleDropdown}
          className="block w-full border border-gray-300 rounded-md h-12 px-3 text-gray-700 bg-white text-left flex items-center justify-between focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
        >
          {showNameOfAssign || "Assignee"}
          {isOpen ? (
            <FaChevronUp className="text-gray-500" />
          ) : (
            <FaChevronDown className="text-gray-500" />
          )}
        </button>

        {/* Dropdown Options */}
        {isOpen && (
          <div className="absolute mt-1 w-full border border-gray-300 rounded-md bg-white shadow-lg z-10">
            <div
              onClick={() => handleSelect("Groups")}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition justify-between"
            >
              <div className="flex items-center">
                <span>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    className="mr-2"
                    color="#4740d4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.6264 9.33174C13.8679 8.70249 14.0003 8.01912 14.0003 7.30484C14.0003 7.09996 13.9894 6.89763 13.9682 6.69838C14.5713 5.64159 15.7088 4.9292 17.0126 4.9292C18.9472 4.9292 20.5155 6.49748 20.5155 8.43206C20.5155 10.3666 18.9472 11.9349 17.0126 11.9349C15.3891 11.9349 14.0235 10.8304 13.6264 9.33174ZM17.0126 6.9292C16.1826 6.9292 15.5098 7.60205 15.5098 8.43206C15.5098 9.26206 16.1826 9.93491 17.0126 9.93491C17.8426 9.93491 18.5155 9.26206 18.5155 8.43206C18.5155 7.60205 17.8426 6.9292 17.0126 6.9292Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M16.4262 20.1855H21.2988C21.828 20.1855 22.334 19.8618 22.6594 19.2886C23.0008 18.6872 23.1177 17.8431 22.8608 17.0148C22.556 16.0322 22.004 14.9087 21.0212 14.039C20.0424 13.1729 18.6778 12.5952 16.7852 12.5952C15.5316 12.5952 14.5097 12.8486 13.6781 13.2714C13.7872 13.3545 13.8934 13.4402 13.9967 13.5284C14.4626 13.926 14.8553 14.3617 15.1861 14.8125C15.625 14.6772 16.1505 14.5952 16.7852 14.5952C18.2355 14.5952 19.1157 15.0235 19.6959 15.5368C20.3179 16.0873 20.7139 16.8443 20.9506 17.6074C20.9968 17.7564 21.0081 17.9034 20.9948 18.0336C20.9888 18.0928 20.9782 18.1436 20.9662 18.1855H16.6521C16.739 18.8872 16.6529 19.5778 16.4262 20.1855Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.34262 2.64746C5.77042 2.64746 3.68524 4.73264 3.68524 7.30484C3.68524 9.87704 5.77042 11.9622 8.34262 11.9622C10.9148 11.9622 13 9.87704 13 7.30484C13 4.73264 10.9148 2.64746 8.34262 2.64746ZM5.68524 7.30484C5.68524 5.83721 6.87499 4.64746 8.34262 4.64746C9.81025 4.64746 11 5.83721 11 7.30484C11 8.77247 9.81025 9.96222 8.34262 9.96222C6.87499 9.96222 5.68524 8.77247 5.68524 7.30484Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.34273 12.6436C6.10666 12.6436 4.49444 13.3019 3.33793 14.289C2.17681 15.28 1.52464 16.5604 1.16449 17.6802C0.860914 18.6241 0.999083 19.5861 1.40242 20.2714C1.78689 20.9247 2.38469 21.2937 3.00989 21.2937H13.6755C14.3007 21.2937 14.8985 20.9247 15.283 20.2714C15.6863 19.5861 15.8245 18.6242 15.5209 17.6802C15.1608 16.5604 14.5086 15.28 13.3475 14.289C12.191 13.3019 10.5788 12.6436 8.34273 12.6436ZM4.63632 15.8102C5.39234 15.1649 6.52952 14.6436 8.34273 14.6436C10.156 14.6436 11.2931 15.1649 12.0491 15.8102C12.8429 16.4877 13.3305 17.4017 13.617 18.2925C13.7503 18.7071 13.666 19.0757 13.5593 19.257C13.5509 19.2713 13.543 19.2835 13.5358 19.2937H3.14955C3.1424 19.2835 3.1345 19.2713 3.12607 19.257C3.01939 19.0757 2.93511 18.7071 3.06845 18.2925C3.35495 17.4017 3.84257 16.4877 4.63632 15.8102Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.34262 4.64746C6.87499 4.64746 5.68524 5.83721 5.68524 7.30484C5.68524 8.77247 6.87499 9.96222 8.34262 9.96222C9.81025 9.96222 11 8.77247 11 7.30484C11 5.83721 9.81025 4.64746 8.34262 4.64746ZM3.68524 7.30484C3.68524 4.73264 5.77042 2.64746 8.34262 2.64746C10.9148 2.64746 13 4.73264 13 7.30484C13 9.87704 10.9148 11.9622 8.34262 11.9622C5.77042 11.9622 3.68524 9.87704 3.68524 7.30484Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
                Groups
              </div>
              <span>
                <svg
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  focusable="false"
                  data-anchor="arrow-right-svg"
                >
                  <path
                    d="M17.633 11.181l-9.52-8.866a1.323 1.323 0 0 0-1.745.028 1.113 1.113 0 0 0-.03 1.625l8.228 7.663a.509.509 0 0 1 0 .755l-8.212 7.646c-.461.461-.448 1.18.03 1.625.479.446 1.25.458 1.745.028l9.504-8.85c.235-.22.368-.517.367-.827a1.12 1.12 0 0 0-.367-.827z"
                    fill="#4740d4"
                    fillRule="nonzero"
                  ></path>
                </svg>
              </span>
            </div>

            <div
              onClick={() => handleSelect("Users")}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition justify-between border-b-2 border-gray-200"
            >
              <span className="flex items-center">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 24 24"
                  className="mr-2"
                  color="#4740d4"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0008 2C9.1289 2 6.80078 4.32812 6.80078 7.2C6.80078 10.0719 9.1289 12.4 12.0008 12.4C14.8727 12.4 17.2008 10.0719 17.2008 7.2C17.2008 4.32812 14.8727 2 12.0008 2ZM8.80078 7.2C8.80078 5.43269 10.2335 4 12.0008 4C13.7681 4 15.2008 5.43269 15.2008 7.2C15.2008 8.96731 13.7681 10.4 12.0008 10.4C10.2335 10.4 8.80078 8.96731 8.80078 7.2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.3888 18.4389C20.7435 19.3692 20.5821 20.3172 20.1107 20.9926C19.6614 21.6364 18.9629 22 18.2323 22H5.76864C5.03804 22 4.33947 21.6364 3.89018 20.9925C3.41885 20.3171 3.25739 19.3691 3.61214 18.4388C4.033 17.3352 4.79512 16.0734 6.15198 15.0967C7.50345 14.1239 9.38747 13.4751 12.0005 13.4751C14.6135 13.4751 16.4976 14.1239 17.849 15.0967C19.2059 16.0734 19.968 17.3353 20.3888 18.4389ZM5.48087 19.1515C5.36462 19.4563 5.43382 19.7097 5.5303 19.848C5.61974 19.9761 5.71616 20 5.76864 20H18.2323C18.2848 20 18.3812 19.9762 18.4706 19.848C18.5671 19.7097 18.6363 19.4563 18.5201 19.1515C18.2036 18.3215 17.6486 17.4167 16.6806 16.7199C15.727 16.0335 14.2711 15.4751 12.0005 15.4751C9.72993 15.4751 8.27403 16.0335 7.32039 16.7199C6.35232 17.4167 5.79738 18.3215 5.48087 19.1515Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Users
              </span>
              <svg
                viewBox="0 0 24 24"
                width="14"
                height="14"
                focusable="false"
                data-anchor="arrow-right-svg"
              >
                <path
                  d="M17.633 11.181l-9.52-8.866a1.323 1.323 0 0 0-1.745.028 1.113 1.113 0 0 0-.03 1.625l8.228 7.663a.509.509 0 0 1 0 .755l-8.212 7.646c-.461.461-.448 1.18.03 1.625.479.446 1.25.458 1.745.028l9.504-8.85c.235-.22.368-.517.367-.827a1.12 1.12 0 0 0-.367-.827z"
                  fill="#4740d4"
                  fill-rule="nonzero"
                ></path>
              </svg>
            </div>

            <div className="flex justify-between px-4 py-2">
              <button
                onClick={handleDoneClick}
                className="mt-2 w-20 bg-indigo-600 text-center text-white py-2 rounded ml-auto"
              >
                Done
              </button>
            </div>
          </div>
        )}

        {/* User Options */}
        {showUserOptions && (
          <div className="absolute mt-1 w-full border border-gray-300 rounded-md bg-white shadow-lg z-10">
            <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300">
              <div
                onClick={() => {
                  setShowUserOptions(false);
                  setIsOpen(true);
                }}
                className="flex items-center cursor-pointer text-indigo-600"
              >
                <svg viewBox="0 0 24 24" width="20" height="20" className="mr-2">
                  <path
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z"
                    fill="currentColor"
                  />
                </svg>
                Back
              </div>
              <p className="absolute inset-x-0 text-center font-semibold text-sm text-indigo-500">
              Select User</p>
            </div>
            <div className="mt-2">
              <div className="relative w-full">
                {/* Icon inside the input field */}
                <svg
                  viewBox="0 0 267 267"
                  width="18"
                  height="18"
                  focusable="false"
                  className="absolute text-gray-400 left-4 top-1/2 transform -translate-y-1/2"
                >
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h267v267H0z"></path>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#a)">
                    <path
                      fill="currentColor"
                      d="M172.438 155.75h-8.789l-3.115-3.004A71.993 71.993 0 0 0 178 105.688c0-39.938-32.375-72.313-72.312-72.313-39.938 0-72.313 32.375-72.313 72.313 0 39.937 32.375 72.312 72.312 72.312 17.912 0 34.377-6.564 47.059-17.466l3.004 3.115v8.789l55.625 55.513 16.576-16.576-55.513-55.625zm-66.75 0c-27.702 0-50.063-22.361-50.063-50.062 0-27.702 22.361-50.063 50.063-50.063 27.701 0 50.062 22.361 50.062 50.063 0 27.701-22.361 50.062-50.062 50.062z"
                    ></path>
                    <path d="M0 0h267v267H0V0z" fill="none"></path>
                  </g>
                </svg>

                {/* Input Field */}
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full p-2 pl-10 border border-indigo-300 rounded mb-2"
                />
              </div>
              <p className="ml-2 text-gray-500 text-sm mb-4 mt-2">GIVE ACCESS BY EMAIL</p>
              <button
                className="text-indigo-600 mb-2 w-full text-left pl-4 hover:text-indigo-500 hover:underline"
                onClick={openPopup}
              >
                + Add user to team
              </button>
              <p className="ml-2 text-gray-500 text-sm mb-4 mt-2">USERS</p>

              {users &&
                users.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setShowNameOfAssign(item?.user?.firstName); // Temporarily show selected user
                      setAssignee(item?.user?.id); // Set selected user's ID
                    }}
                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition"
                  >
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={assignee === item?.user?.id} // Checkbox reflects the selected user
                      readOnly
                    />
                    {item?.user?.firstName}
                  </div>
                ))}
                {isPopupOpen && (
                  <div className="popup-overlay fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="popup-content bg-white p-6 rounded-lg w-1/3">
                      <div className="flex justify-between">
                        <p className="text-lg mb-4 font-semibold">Add a user to your team</p>
                        <p onClick={closePopup}>
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="#545f70"
                            focusable="false"
                            data-anchor="cross-svg"
                          >
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                          </svg>
                        </p>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="mt-1 p-2 border border-gray-300 rounded w-full focus:ring-1 focus:border-indigo-600"
                        />
                      </div>

                      <div className="flex space-x-4 mb-4">
                        <div className="w-1/2">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                          />
                        </div>
                        <div className="w-1/2">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="mt-1 p-2 border border-gray-300 rounded w-full"
                          />
                        </div>
                      </div>

                      <div className="text-sm text-indigo-700 bg-indigo-100 mb-4 border border-indigo-700 p-2 rounded-md flex items-center">
                        <svg viewBox="0 0 24 24" width="21" height="21" focusable="false" className="mr-2">
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path
                            fill="#4740d4"
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                          ></path>
                        </svg>
                        Seats available: 9
                      </div>

                      <div className="flex justify-end space-x-4">
                        <button
                          onClick={closePopup}
                          className="text-gray-500 px-4 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleAddUser}
                          className={`text-white px-4 py-2 rounded-md ${email && firstName && lastName ? "bg-indigo-600" : "bg-indigo-300 cursor-not-allowed"}`}
                          disabled={!email || !firstName || !lastName}
                        >
                          Add to Team
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              <div className="flex justify-between px-4 py-2">
                <button
                  onClick={handleDoneClick}
                  className="mt-2 w-20 bg-indigo-600 text-center text-white py-2 rounded ml-auto"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Group Options */}
        {showGroupOptions && (
          <div className="absolute mt-1 w-full border border-gray-300 rounded-md bg-white shadow-lg z-10">
            <div className="relative flex items-center justify-between px-4 py-2 border-b border-gray-300">
              <div
                className="flex items-center cursor-pointer text-black"
                onClick={() => {
                  setShowGroupOptions(false);
                  setIsOpen(true);
                }}
              >
                <svg viewBox="0 0 24 24" width="20" height="20" className="mr-2 text-indigo-600">
                  <path
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z"
                    fill="currentColor"
                  />
                </svg>
                Back
              </div>
              <p className="absolute inset-x-0 text-center font-semibold text-sm text-indigo-500">
                Select Group
              </p>
            </div>
            <div className="mt-2">
              <div className="relative w-full">
                {/* Icon inside the input field */}
                <svg
                  viewBox="0 0 267 267"
                  width="18"
                  height="18"
                  focusable="false"
                  className="absolute text-gray-400 left-4 top-1/2 transform -translate-y-1/2"
                >
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h267v267H0z"></path>
                    </clipPath>
                  </defs>
                  <g clipPath="url(#a)">
                    <path
                      fill="currentColor"
                      d="M172.438 155.75h-8.789l-3.115-3.004A71.993 71.993 0 0 0 178 105.688c0-39.938-32.375-72.313-72.312-72.313-39.938 0-72.313 32.375-72.313 72.313 0 39.937 32.375 72.312 72.312 72.312 17.912 0 34.377-6.564 47.059-17.466l3.004 3.115v8.789l55.625 55.513 16.576-16.576-55.513-55.625zm-66.75 0c-27.702 0-50.063-22.361-50.063-50.062 0-27.702 22.361-50.063 50.063-50.063 27.701 0 50.062 22.361 50.062 50.063 0 27.701-22.361 50.062-50.062 50.062z"
                    ></path>
                    <path d="M0 0h267v267H0V0z" fill="none"></path>
                  </g>
                </svg>

                {/* Input Field */}
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full p-2 pl-10 border border-indigo-300 rounded mb-2 focus:border-indigo-500 focus:outline-none"
                />

              </div>
              <p className="text-gray-500 text-center mb-6">No results</p>
              <div className="flex justify-between px-4 py-2">
                <button
                  onClick={handleDoneClick}
                  className="mt-2 w-20 bg-indigo-600 text-center text-white py-2 rounded ml-auto"
                >
                  Done
                </button>
              </div>
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Assign;
