import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";
import { useNotification } from "../../../../common/hooks/useNotification";
import useHttp from "../../../../common/hooks/useHttp";
import { useApi } from "../../../context/OrganizationContaxt";
const UserSettingsTab = ({ user }) => {
  console.log(user, "user");
  const [show, setShow] = useState(false);
  const { getOrganizationUsers } = useApi();
  const { authData } = useAuth();
  const [firstName, seFirstName] = useState();
  const [lastName, seLastName] = useState();
  const [Email, seEmail] = useState();

  const { sendRequest } = useHttp();
  const notify = useNotification();
  const handleSaveClick = async () => {
    try {
      const payloadUserData = {
        userId: user?.user?.id,
        firstName: firstName,
        lastName: lastName,
        Email: Email,
      };
      await sendRequest(
        {
          url: "v1/user/details/update",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: payloadUserData,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getOrganizationUsers();
              notify(response.responseDesc, "success");

              setShow(false);
            } else {
              setShow(true);
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log(err, "thisi sis error");
    }
    setShow(false);
    // Here you can handle saving the data, e.g. API call or local storage update.
  };
  return (
    <div className="container mx-auto  py-4">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Side - Details Card */}
        <div className="w-full lg:w-1/2 space-y-4">
          <div className=" bg-white text-start rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Details</h2>

              <button
                onClick={(e) => setShow(true)}
                className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"
              >
                <MdEdit className="me-1 mt-1" /> Edit
              </button>
            </div>
            {!show && (
              <div className="mt-4 space-y-4">
                <div className="text-sm">
                  <p className="font-semibold">Name</p>
                  <p className="truncate max-w-xs">{user?.user?.firstName}</p>
                </div>
                <div className="text-sm">
                  <p className="font-semibold">Email</p>
                  <p>{user?.user?.email}</p>
                </div>
                <div className="text-sm">
                  <p className="font-semibold">Status</p>
                  <p>{user?.user?.isActive === true ? "Active" : "Offline"} </p>
                </div>

                {/*
              <div className="text-sm">
                <p className="font-semibold">Date format</p>
                <p>d MMM yyyy (e.g. 1 Dec 2021)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Time format</p>
                <p>12-hour (e.g. 3:30 PM)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Temperature metric</p>
                <p>Celsius (°C)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Distance metric</p>
                <p>Kilometers (km)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Language</p>
                <p>English (US)</p>
              </div> */}
                <div className="text-sm">
                  <p className="font-semibold">User ID</p>
                  <div className="flex items-center justify-between bg-gray-100 p-2 rounded">
                    <span>{user?.user?.id}</span>
                    <button className="text-purple-500 hover:underline">
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            )}
            {show && (
              <div className="mt-4 space-y-4">
                <div className="flex justify-between space-x-4">
                  <div className="text-sm">
                    <input
                      type="text"
                      onChange={(e) => {
                        seFirstName(e.target.value);
                      }}
                      className="font-semibold w-full p-2 border border-gray-300 rounded mt-1 "
                      placeholder="Name"
                    />
                  </div>
                  <div className="text-sm">
                    <input
                      onChange={(e) => {
                        seLastName(e.target.value);
                      }}
                      type="text"
                      className="font-semibold w-full p-2 border border-gray-300 rounded mt-1 "
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="text-sm">
                  <input
                    onChange={(e) => {
                      seEmail(e.target.value);
                    }}
                    type="email"
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    placeholder="Email"
                  />
                </div>
                <div className="text-sm">
                  <p className="font-semibold">Status</p>
                  <p>{user?.user?.isActive === true ? "Active" : "Offline"} </p>
                </div>

                {/*
              <div className="text-sm">
                <p className="font-semibold">Date format</p>
                <p>d MMM yyyy (e.g. 1 Dec 2021)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Time format</p>
                <p>12-hour (e.g. 3:30 PM)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Temperature metric</p>
                <p>Celsius (°C)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Distance metric</p>
                <p>Kilometers (km)</p>
              </div>
              <div className="text-sm">
                <p className="font-semibold">Language</p>
                <p>English (US)</p>
              </div> */}
                <div className="text-sm">
                  <p className="font-semibold">User ID</p>
                  <div className="flex items-center justify-between bg-gray-100 p-2 rounded">
                    <span>{user?.user?.id}</span>
                    <button className="text-purple-500 hover:underline">
                      Copy
                    </button>
                  </div>
                </div>
                <div className="mt-6 flex space-x-4">
                  <button
                    onClick={() => setShow(false)}
                    className="bg-gray-300 text-white py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveClick}
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Password</h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3">
                <MdEdit className="me-1 mt-1" /> Edit
              </button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start pt-3">
              Password
            </p>
            <p className="text-start">*****************</p>
          </div> */}
        </div>

        {/* Right Side - Cards */}
        <div className="w-full lg:w-1/2 space-y-4">
          {/* Mobile Number Card */}
          {/* <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Mobile number</h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3">
                <MdEdit className="me-1 mt-1" /> Edit
              </button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start pt-3">
              Add your mobile number to enable SMS notifications.
            </p>
          </div> */}

          {/* Display Settings Card */}
          {/* <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Display Settings</h2>
              <span className="bg-blue-100 text-blue-500 text-xs px-2 py-1 rounded-full">
                EARLY ACCESS
              </span>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3">
                <MdEdit className="me-1 mt-1" /> Edit
              </button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start">Theme</p>
            <p className="text-sm text-black text-start">Light theme</p>
          </div> */}

          {/* Confirm Email Card */}
          {/* <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">
                Confirm your email address
              </h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3">
                <IoMdSend className="me-1 mt-1" /> Send
              </button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start">
              Your email address is required to send you notifications and
              invites. Please check your spam folder if it's not showing in your
              inbox.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserSettingsTab;
