import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";
import { useNotification } from "../../../common/hooks/useNotification";
const CategoriePage = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [ setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null);
  const [ setCategoryText] = useState('');
  const [siteFields, setSiteFields] = useState([]);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  // const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false); // State for category popup visibility
  const [activeButton, setActiveButton] = useState("Issues");
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [name, setCreateTypeName] = useState("");
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const dropdownRef = useRef(null);
  const popupRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term



  

  const handleClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };

  const handleCategoryClick = (index) => {
    setDropdownOpenIndex(dropdownOpenIndex === index ? null : index);
  };
  const handleEditClick = () => {
    navigate("/dashboard/edit-category");  // Redirect to a new page ("/edit-category" is an example)
  };

  const handleInputChange = (event) => {
    setCategoryText(event.target.value); // Update the input value
  };

  const handleSiteToggle = (index) => {
    const updatedFields = [...siteFields];
    updatedFields[index].isOn = !updatedFields[index].isOn; // Toggle the "On/Off" state for this specific site field
    setSiteFields(updatedFields);
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleAddSite = () => {
    setSiteFields([
      ...siteFields,
      { siteName: "", isOn: false, showFields: false } // Add a new site field with "off" state and hide fields initially
    ]);
  };

  const handleSiteNameChange = (index, event) => {
    const updatedFields = [...siteFields];
    updatedFields[index].siteName = event.target.value; // Update the site name for this specific site field
    setSiteFields(updatedFields);
  };


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };

  const toggleCategoryPopup = () => {
    setIsCategoryPopupOpen(!isCategoryPopupOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenIndex(null);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getCategory = async () => {
    try {
      const response = await sendRequest({
        url: "v1/categories/list?page=0&size=10&sortBy=name&sortDirection=ASC",
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
      });
      console.log(response, "Fetched category data");
      if (response && response.data) {
        setCategories(response.data.categories);
      } else {
        console.warn("No category data found in response");
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  // const filteredCategories = categories;

  // Filter categories based on searchTerm
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  useEffect(() => {
    getCategory();
  }, []);

  const handleSaveAssetType = async () => {
    try {
      await sendRequest(
        {
          url: "v1/categories/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            name: name,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setCreateTypeName("");
              setSearchTerm("");
              getCategory();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleChange = (e) => {
    // Regular expression to match only letters (a-z, A-Z) and spaces
    const regex = /^[A-Za-z\s]*$/;

    const value = e.target.value;

    if (regex.test(value)) {
      setCreateTypeName(value); // Update state if the value matches the regex
    } else {
      console.log("Special characters are not allowed!");
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div
        className={`${isSidebarOpen ? "w-2/6" : "w-0"} bg-white shadow-md fixed top-0 right-0 h-full transition-all ease-in-out duration-300 overflow-hidden z-50`}
      >
        <div className="h-20 bg-indigo-50">
          <h2 className="text-2xl font-semibold text-gray-700 p-4 text-left">
            Create issue QR code
          </h2>
        </div>
        <div className="p-6"> 
          <h2 className="text-sm text-gray-700 mb-2 text-left">QR code name (required)</h2>
          <input
            type="text"
            placeholder="Add name"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          />
          <div className="relative w-92">
            <input
              type="text"
              placeholder="Enter text"
              className="w-full pl-3 pr-12 py-2 border rounded-md"
            />
            <button
              onClick={() => handleCategoryClick(0)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-12 h-6 rounded-full bg-gray-400"
            >
              <div
                className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform`}
              ></div>
            </button>
            {dropdownOpenIndex === 0 && (
              <div className="absolute z-10 w-full mt-2 bg-white shadow-md rounded-md border">
                <div className="px-4 py-2">
                  {/* Search Input Field */}
                  <input
                    type="text"
                    placeholder="Enter text"
                    className="w-full pl-3 pr-12 py-2 border rounded-md"
                  />
                </div>
                <ul className="py-2">
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 1</li>
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 2</li>
                  <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Category 3</li>
                </ul>
              </div>
            )}
          </div>

          {/* Add Site Button */}
          <button
            onClick={handleAddSite}
            className="text-sm text-indigo-600 hover:underline mb-4"
          >
            + Add Site
          </button>

          {/* Render Site Fields */}
          {siteFields.map((siteField, index) => (
            <div key={index} className="mb-4">
              <input
                type="text"
                value={siteField.siteName}
                onChange={(event) => handleSiteNameChange(index, event)}
                placeholder="Enter site name"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
              />
              <button
                onClick={() => handleSiteToggle(index)}
                className={`w-12 h-6 rounded-full ${siteField.isOn ? 'bg-green-500' : 'bg-gray-400'}`}
              >
                <div
                  className={`w-6 h-6 bg-white rounded-full shadow-md transition-transform ${siteField.isOn ? 'transform translate-x-6' : ''}`}
                ></div>
              </button>

              {/* Show site fields if toggle is ON */}
              {siteField.isOn && (
                <div className="mt-4">
                  <div
                    className="relative"
                    onClick={() => handleCategoryClick(index + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="w-full pl-3 pr-12 py-2 border rounded-md text-gray-700">
                      Select site category
                    </div>
                    {dropdownOpenIndex === index + 1 && (
                      <div className="absolute z-10 w-full mt-2 bg-white shadow-md rounded-md border">
                        <div className="px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter category"
                            className="w-full pl-3 pr-12 py-2 border rounded-md"
                          />
                        </div>
                        <ul className="py-2">
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 1</li>
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 2</li>
                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Site Category 3</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="flex justify-end mt-4 space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={toggleSidebar} // Add logic here to handle cancel action
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-indigo-200 text-white rounded hover:bg-indigo-600"
              onClick={() => {
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-0 transition-all duration-300 ease-in-out">
        {/* Header */}
        <div className="w-full px-4 border-b mt-4">
          <div className="w-full px-4 border-b">
          <div className="flex items-center justify-start space-x-8">
              <button
                className={`mb-4 ${
                  activeButton === "Issues"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("Issues", "/dashboard/issue-page")}
              >
                Issues
              </button>
              <button
                className={`mb-4 ${
                  activeButton === "Categories"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("Categories", "/dashboard/category-page")}
              >
                Categories
              </button>
              <button
                className={`mb-4 ${
                  activeButton === "QR codes"
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => handleClick("QR codes", "/dashboard/qr-page")}
              >
                QR codes
              </button>
            </div>
          </div>
        </div>
        
        {/* QR Code Header */}
        <div className="flex items-center justify-between w-full max-w-7xl px-4 mt-4">
          <div className="text-3xl font-medium text-gray-900 ml-4">Manage issue categories</div>
          <button
            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 mr-10"
            onClick={toggleCategoryPopup} // Open Add Category Popup
          >
            + Add category
          </button>
        </div>

        {/* Add Category Popup */}
        {isCategoryPopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-2/6">
            <h2 className="text-2xl mb-4 text-left">Add new category</h2>
            <div className="mb-4">
              <label
                htmlFor="categoryName"
                className="block text-sm text-gray-700 mb-1 text-left"
              >
                Name
              </label>
              <input
                type="text"
                id="categoryName"
                value={name}
                onChange={handleChange}
                placeholder="Enter category name"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={130}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-white text-indigo-700 border border-gray-300 rounded-lg hover:bg-indigo-50"
                onClick={toggleCategoryPopup}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-indigo-300 text-white rounded hover:bg-indigo-600"
                onClick={() => {
                  handleSaveAssetType();
                  toggleCategoryPopup();
                }}
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      )}

        {/* Search and Filters */}
        <div className="w-full max-w-7xl px-4 mt-6">
          <div className="flex flex-col sm:flex-row justify-start items-center p-4 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="flex items-center justify-between w-full">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="flex-1 sm:flex-none sm:w-64 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <p className="mr-10 text-gray-600">10 results</p>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="w-full max-w-6xl mt-6 bg-white rounded-md shadow-sm ml-7">
            <table className="w-full table-auto text-left border border-gray">
              <thead>
                <tr className="bg-indigo-50 text-gray-700 border-b border-gray-300">
                <th className="p-2 text-sm underline flex items-center font-normal">
                  <span>Category name</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="48"
                    viewBox="0 0 24 48"
                    className="cursor-pointer"
                  >
                    <text x="0" y="25" fontSize="20" fill="currentColor">↓</text>
                    <text x="0" y="40" fontSize="0" fill="currentColor">↓</text>
                  </svg>

                </th>
                <th className="p-2 text-sm font-normal">Category access</th>
                <th className="p-2 text-sm font-normal">Notify</th>
                <th className="p-2 text-sm text-center fon"></th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories.length > 0 ? (
                  filteredCategories.map((type, index) => (
                    <tr
                      key={index}
                      className="border-b"
                      onClick={() => {
                        navigate(
                          `/dashboard/edit-category/${type.id}?name=${encodeURIComponent(
                            type.name
                          )}&id=${type.id}`
                        );
                      }}
                    >
                      <td className="p-4 text-sm text-gray-700">
                        {type.name.length > 30 ? `${type.name.slice(0, 30)}...` : type.name}
                      </td>
                      <td className="p-4 text-sm text-gray-700">
                        {type.isEnabled ? "All users" : "Disabled"}
                      </td>
                      <td className="p-4 text-sm text-gray-700">
                        {type.isVisibleToAll ? "None" : "Yes"}
                      </td>
                      <td className="p-4 text-sm text-center">
                        {/* Edit Button */}
                        <button
                          className="text-indigo-600 hover hover:bg-indigo-50 p-4 rounded mr-2"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleEditClick(type.id);
                          }}
                        >
                          Edit Category
                        </button>
                        {/* Dropdown Menu */}
                        <div className="inline-block relative">
                          <button
                            className="text-gray-600 focus:outline-none"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              toggleDropdown(index);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              className="cursor-pointer"
                            >
                              <circle cx="12" cy="5" r="2" fill="currentColor" />
                              <circle cx="12" cy="12" r="2" fill="currentColor" />
                              <circle cx="12" cy="19" r="2" fill="currentColor" />
                            </svg>
                          </button>
                          {dropdownVisible === index && (
                            <div className="absolute right-0 mt-2 bg-white border rounded shadow-lg w-32">
                              <ul>
                                <li
                                  className="p-2 hover:bg-indigo-100 text-sm text-gray-700"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click
                                    toggleDropdown(index);
                                  }}
                                >
                                  Delete Category
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="p-4 text-center text-gray-500">
                      No asset types found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

export default CategoriePage;
