import React from "react";

function EditType() {
  const handleCreateField = () => {
    console.log("Create a new field clicked");
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex items-center mb-6">
        <button className="text-blue-500 font-medium mr-2">&larr; Back</button>
        <h2 className="text-xl font-semibold">Edit type</h2>
      </div>

      {/* Action Section */}
      <div className="flex p-6">
        {/* Left Section */}
        <div className="w-3/5 bg-gray-50 p-6 rounded-lg shadow relative overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4 text-left">Action</h3>

            {/* System Fields */}
            <div>
            <h4 className="text-gray-600 font-medium mb-2 text-left">System Fields</h4>
            <div className="space-y-3">
                {[
                { name: "Title", type: "Text", required: true },
                { name: "Description", type: "Text" },
                { name: "Priority", type: "Single select" },
                { name: "Due date", type: "Date" },
                { name: "Assignees", type: "User" },
                { name: "Site", type: "Site" },
                { name: "Asset", type: "Asset" },
                { name: "Labels", type: "Multi select" },
                ].map((field, index) => (
                <div
                    key={index}
                    className="flex justify-between items-center p-2 bg-white rounded shadow-sm border"
                >
                    <div className="flex items-center">
                    <p className="font-medium">{field.name}</p>
                    {field.required && (
                        <span className="text-xs bg-gray-200 text-gray-700 ml-2 px-2 py-1 rounded">
                        Required
                        </span>
                    )}
                    </div>
                    <p className="text-gray-500">{field.type}</p>
                </div>
                ))}
            </div>
            </div>

            {/* Custom Fields */}
            <div className="mt-6">
            <h4 className="text-gray-600 font-medium mb-2">Custom Fields</h4>
            <p className="text-sm text-gray-400">No custom fields added</p>
            </div>
        </div>

        {/* Right Section */}
        <div className="w-2/5 bg-white p-6 rounded-lg shadow fixed right-2  h-[calc(100vh-48px)] overflow-y-auto">
            <h3 className="text-lg font-semibold">Add an existing field</h3>
            <p className="text-sm text-gray-400 mb-4">
            Reuse existing fields from other custom types to maintain consistency in your task details.
            </p>

            {/* Search Bar */}
            <div className="mb-4">
            <input
                type="text"
                placeholder="Search"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </div>

            {/* Existing Field */}
            <div className="flex justify-between items-center p-2 bg-white rounded shadow-sm border">
            <p className="font-medium">sherry</p>
            <p className="text-gray-500">Text</p>
            </div>

            {/* Create a new field button */}
            <button
            onClick={handleCreateField}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
            >
            + Create a new field
            </button>
        </div>
        </div>

    </div>
  );
}

export default EditType;
