import React, { useState, useRef, useEffect } from "react";
import { FaEdit, FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useApi } from "../../../context/OrganizationContaxt";

const Site = ({ site, setSite, showSiteName, setShoSite, siteShow }) => {
  const [isSiteDropdownOpen, setIsSiteDropdownOpen] = useState(false);
  const [siteSearchTerm, setSiteSearchTerm] = useState("");
  const { getSiteLists } = useApi();

  const filteredSiteOptions = getSiteLists.filter((option) =>
    option?.siteName?.toLowerCase().includes(siteSearchTerm.toLowerCase())
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSiteDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700 mt-12">
        Site
      </label>
      <button
        onClick={() => setIsSiteDropdownOpen(!isSiteDropdownOpen)}
        className="block w-full border border-gray-300 text-gray-400 rounded-md h-12 px-3 bg-white text-left flex items-center justify-between focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
      >
        {showSiteName || "Select"}
        {isSiteDropdownOpen ? (
          <FaChevronUp className="text-gray-500" />
        ) : (
          <FaChevronDown className="text-gray-500" />
        )}
      </button>

      {isSiteDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-4">
          {/* Search Input with Icon */}
          <div className="relative mb-4">
            <input
              type="text"
              value={siteSearchTerm}
              onChange={(e) => setSiteSearchTerm(e.target.value)}
              placeholder="Search"
              className="w-full h-12 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Filtered Results */}
          {filteredSiteOptions.length > 0 ? (
            <ul className="max-h-48 overflow-y-auto mb-4">
              {filteredSiteOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShoSite(option?.siteName);
                    setSite(option?.siteId);
                    setIsSiteDropdownOpen(false);
                    setSiteSearchTerm("");
                  }}
                  className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition rounded-md overflow-hidden whitespace-nowrap text-ellipsis"
                >
                  {option?.siteName}
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-500 mb-4">
              <p className="text-lg font-semibold">No sites</p>
              <p>You are not a member of any sites.</p>
            </div>
          )}

          {/* Footer with Buttons */}
          <div className="flex justify-end gap-3 mt-4">
            <button
              onClick={() => {
                setSite("");
                setSiteSearchTerm("");
              }}
              className="text-indigo-300 border-2 rounded-md p-2 "
            >
              Clear selections
            </button>
            <button
              onClick={() => setIsSiteDropdownOpen(false)}
              className="px-4 py-2 bg-indigo-500 text-white rounded-md"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Site;
