import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { useAuth } from "../../context/AuthContext";
import { FiSettings } from "react-icons/fi";

const UserProfile = () => {
  const [selectedTab, setSelectedTab] = useState("groups");
  const [activeTab, setActiveTab] = useState("credentials");
  const navigate = useNavigate();

  const goToSettings = () => {
    navigate("/dashboard/user-setting"); // Replace with the actual route for your user settings page
  };
  const handleBackClick = () => {
    navigate(-1); // This will go back to the previous page in the history stack
  };
  const { authData } = useAuth();
  console.log(authData);
  const handleTabClick = (tab) => {
    setSelectedTab(tab); // Update the state based on the tab clicked
  };
  const handleCredentialsClick = () => {
    navigate("/dashboard/user-credential");
  };
  const handleTabSelection = (tab) => {
    setActiveTab(tab); // Set the active tab when clicked
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="container mx-auto">
        {/* Back Button */}
        <div className="mb-4 text-left">
          <Link to={"/dashboard/user"} className="text-blue-500">
            &larr; Back
          </Link>
        </div>

        {/* Profile Header */}
        <div className="bg-gradient-to-r from-blue-200 to-green-200 p-6 rounded-lg relative flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-blue-300 w-24 h-24 rounded-full flex items-center justify-center text-3xl text-white font-semibold">
              {(
                authData.userName.charAt(0) +
                authData.userName.charAt(authData.userName.length - 1)
              ).toUpperCase()}
            </div>
            <div className="ml-4">
              <h2 className="text-2xl font-semibold">{authData.userName}</h2>
            </div>
          </div>
          <button
            onClick={goToSettings}
            className="bg-white p-2 rounded-md shadow text-blue-500"
          >
            <FiSettings /> Settings
          </button>
        </div>

        {/* Main Content */}
        <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* About Section */}
          <div className="col-span-1 bg-white p-6 rounded-lg shadow text-left">
            <h3 className="font-semibold text-lg mb-4">About</h3>
            <ul className="text-gray-700 space-y-4">
              <li>
                <strong>Email:</strong>
                <br /> {authData.userDetails?.email || "Add email"}
              </li>
              <li className="w-32">
                <strong>Phone:</strong>
                <br />
                <span class="truncate overflow-hidden whitespace-nowrap inline-block w-full">
                  {(authData.userDetails?.phoneNumber || "add number").slice(
                    0,
                    16
                  )}
                </span>
              </li>
              <li>
                <strong>Time Zone:</strong>
                <br /> Asia/Karachi
              </li>
              <li>
                <strong>Permissions:</strong>
                <br /> Administrator
              </li>
            </ul>
          </div>

          {/* Qualifications Section */}
          <div className="col-span-1 md:col-span-2 bg-white p-6 rounded-lg shadow">
            <h3 className="font-semibold text-lg mb-4 text-left">
              Qualifications
            </h3>

            {/* Tab Buttons */}
            <div className="flex justify-between mb-4">
              <div className="flex space-x-2">
                <button
                  className={`${
                    activeTab === "credentials"
                      ? "bg-purple-200"
                      : "bg-purple-50"
                  } text-purple-600 py-1 px-3 rounded`}
                  onClick={() => handleTabSelection("credentials")}
                >
                  Credentials
                </button>
                <button
                  className={`${
                    activeTab === "training" ? "bg-purple-200" : "bg-purple-50"
                  } text-purple-600 py-1 px-3 rounded`}
                  onClick={() => handleTabSelection("training")}
                >
                  Training
                </button>
              </div>
              <button
                className="bg-purple-500 text-white py-1 px-3 rounded"
                onClick={handleCredentialsClick}
              >
                + Add credential
              </button>
            </div>

            {/* Conditional Rendering Based on Active Tab */}
            {activeTab === "training" && (
              <>
                {/* Show the search and no credentials message when 'Training' tab is selected */}
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="flex flex-col items-center justify-center text-center text-gray-500 h-[400px] border rounded shadow-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    width="200"
                    height="200"
                  >
                    <circle cx="100" cy="100" r="90" fill="#F3F4F6" />
                    <g transform="translate(50, 50)">
                      <circle
                        cx="50"
                        cy="50"
                        r="35"
                        fill="white"
                        stroke="#7B61FF"
                        stroke-width="4"
                      />
                      <path
                        d="M30 50 h40 M50 30 v40"
                        stroke="#7B61FF"
                        stroke-width="2"
                      />
                      <path
                        d="M50 40 l-5 10 a5 5 0 1 0 10 0 l-5 -10z"
                        fill="#7B61FF"
                      />
                      <rect
                        x="65"
                        y="65"
                        width="50"
                        height="10"
                        rx="5"
                        transform="rotate(45 65 65)"
                        fill="#7B61FF"
                      />
                    </g>
                    <g transform="translate(15, 120)">
                      <rect
                        x="0"
                        y="10"
                        width="15"
                        height="30"
                        fill="#7B61FF"
                      />
                      <rect
                        x="20"
                        y="0"
                        width="15"
                        height="40"
                        fill="#8B5CF6"
                      />
                      <rect
                        x="40"
                        y="20"
                        width="15"
                        height="20"
                        fill="#A78BFA"
                      />
                    </g>
                    <path
                      d="M20 170 Q 50 160, 80 175 T 140 170"
                      stroke="#60A5FA"
                      stroke-width="3"
                      fill="none"
                    />
                  </svg>
                  <p className="font-semibold mt-4">No courses</p>
                  <p>
                    This user hasn't been assigned or completed any training
                    courses.
                  </p>
                </div>
              </>
            )}
            {activeTab === "credentials" && (
              <>
                {/* Content for 'Credentials' tab */}
                <div className="text-center text-gray-500">
                  <p className="font-semibold">No credentials</p>
                  <p>This user doesn’t have any credentials added.</p>
                </div>
              </>
            )}
          </div>

          {/* Membership Section */}
          <div className="col-span-1 bg-white p-6 rounded-lg shadow">
            <h3 className="font-semibold text-lg mb-4 text-left">
              Memberships
            </h3>

            {/* Buttons to switch between Groups and Sites */}
            <div className="flex space-x-2 mb-4">
              <button
                className={`py-1 px-3 rounded ${
                  selectedTab === "groups"
                    ? "bg-purple-200 text-purple-600"
                    : "bg-purple-50 text-purple-600"
                }`}
                onClick={() => handleTabClick("groups")}
              >
                Groups
              </button>
              <button
                className={`py-1 px-3 rounded ${
                  selectedTab === "sites"
                    ? "bg-purple-200 text-purple-600"
                    : "bg-purple-50 text-purple-600"
                }`}
                onClick={() => handleTabClick("sites")}
              >
                Sites
              </button>
            </div>

            {/* Conditionally render content based on selected tab */}
            {selectedTab === "groups" ? (
              <div className="flex flex-col items-center justify-center text-center text-gray-500 h-[400px] border rounded shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                  width="200"
                  height="200"
                >
                  <circle cx="100" cy="100" r="90" fill="#F3F4F6" />
                  <g transform="translate(50, 50)">
                    <circle
                      cx="50"
                      cy="50"
                      r="35"
                      fill="white"
                      stroke="#7B61FF"
                      stroke-width="4"
                    />
                    <path
                      d="M30 50 h40 M50 30 v40"
                      stroke="#7B61FF"
                      stroke-width="2"
                    />
                    <path
                      d="M50 40 l-5 10 a5 5 0 1 0 10 0 l-5 -10z"
                      fill="#7B61FF"
                    />
                    <rect
                      x="65"
                      y="65"
                      width="50"
                      height="10"
                      rx="5"
                      transform="rotate(45 65 65)"
                      fill="#7B61FF"
                    />
                  </g>
                  <g transform="translate(15, 120)">
                    <rect x="0" y="10" width="15" height="30" fill="#7B61FF" />
                    <rect x="20" y="0" width="15" height="40" fill="#8B5CF6" />
                    <rect x="40" y="20" width="15" height="20" fill="#A78BFA" />
                  </g>
                  <path
                    d="M20 170 Q 50 160, 80 175 T 140 170"
                    stroke="#60A5FA"
                    stroke-width="3"
                    fill="none"
                  />
                </svg>
                <p className="font-semibold mt-4">No groups</p>
                <p>This user is not a member of any groups.</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-center text-gray-500 h-[400px] border rounded shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                  width="200"
                  height="200"
                >
                  <circle cx="100" cy="100" r="90" fill="#F3F4F6" />
                  <g transform="translate(50, 50)">
                    <circle
                      cx="50"
                      cy="50"
                      r="35"
                      fill="white"
                      stroke="#7B61FF"
                      stroke-width="4"
                    />
                    <path
                      d="M30 50 h40 M50 30 v40"
                      stroke="#7B61FF"
                      stroke-width="2"
                    />
                    <path
                      d="M50 40 l-5 10 a5 5 0 1 0 10 0 l-5 -10z"
                      fill="#7B61FF"
                    />
                    <rect
                      x="65"
                      y="65"
                      width="50"
                      height="10"
                      rx="5"
                      transform="rotate(45 65 65)"
                      fill="#7B61FF"
                    />
                  </g>
                  <g transform="translate(15, 120)">
                    <rect x="0" y="10" width="15" height="30" fill="#7B61FF" />
                    <rect x="20" y="0" width="15" height="40" fill="#8B5CF6" />
                    <rect x="40" y="20" width="15" height="20" fill="#A78BFA" />
                  </g>
                  <path
                    d="M20 170 Q 50 160, 80 175 T 140 170"
                    stroke="#60A5FA"
                    stroke-width="3"
                    fill="none"
                  />
                </svg>
                <p className="font-semibold mt-4">No sites</p>
                <p>This user is not a member of any sites.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
