import { useEffect, useRef, useState } from 'react';
// import { BiCross } from 'react-icons/bi';
import { useApi } from "../../../context/OrganizationContaxt";
import Filter from "./filter";
import { SlCalender } from "react-icons/sl";
import { CiCalendar } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { BiCross } from "react-icons/bi";
const ScheduleImage = require("../../../../assets/schedule/1.jpg");

const MySchedules = () => {
  const { sheduleMy, getTamplateList } = useApi();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [showTitle, setTitle] = useState();
  // const [FilteredData, setFilteredData] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [showSelectedTemplate, setShowSelectedTemplate] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const dropdownContainerRef = useRef(null);

  const toggleDropdownVisibility = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  const clearFilter = () => {
    // Logic for clearing the filter
    setSelectedTitle('');
    setSelectedTemplates([]);
  };

  const selectTemplate = (title) => {
    setSelectedTitle(title); // Set the selected template title
    setIsDropdownOpen(false); // Close the dropdown after selecting a template
  };

  // Close dropdown when clicking outside of it
  const handleOutsideClick = (event) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown if clicked outside
    }
  };

  // Attach event listener on mount and remove it on unmount
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  
  const handleClearSearch = () => {
    setSearchValue("");
  };
  
  const handlShowmodal = (item) => {
    setData(item);
    setShowModal(true);
  };
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const [searchValue, setSearchValue] = useState("");
  const filteredData = sheduleMy?.data?.schedules.filter(
    (item) =>
      item?.templateTitle?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.frequency?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleTemplateSelect = (templateTitle) => {
    setTitle(templateTitle);
    // Filter schedules based on the selected template's title
    const filtered = sheduleMy?.data?.schedules.filter(
      (schedule) => schedule?.templateTitle === templateTitle
    );
    console.log(filtered);
    setSelectedTemplate(filtered);
    setShowSelectedTemplate(filtered);
    setIsFilterOpen(false); // Close the
  };
  const removeFilter = () => {
    setSelectedTemplate("");
    setShowSelectedTemplate([]);
    setTitle("");
  };
  // useMemo(() => {
  //   setShowSelectedTemplate(selectedTemplate);
  // }, [selectedTemplate]);
  // console.log(showSelectedTemplate);

  return (
    <div role="tabpanel" className="px-2 sm:px-4 md:px-8 lg:px-0">
      <div className="mt-6 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="relative w-full sm:w-64">
          {/* Search Icon */}
          <svg
            width="21"
            height="21"
            viewBox="0 0 24 24"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2931 2.37207C5.96319 2.37207 2.45312 5.88213 2.45312 10.212C2.45312 14.5419 5.96319 18.052 10.2931 18.052C12.0558 18.052 13.6826 17.4702 14.992 16.4883L19.8393 21.3355C20.2298 21.726 20.863 21.726 21.2535 21.3355C21.644 20.945 21.644 20.3118 21.2535 19.9213L16.4271 15.0949C17.4949 13.7554 18.133 12.0582 18.133 10.212C18.133 5.88213 14.6229 2.37207 10.2931 2.37207ZM4.45312 10.212C4.45312 6.9867 7.06776 4.37207 10.2931 4.37207C13.5184 4.37207 16.133 6.9867 16.133 10.212C16.133 13.4373 13.5184 16.052 10.2931 16.052C7.06776 16.052 4.45312 13.4373 4.45312 10.212Z"
              fill="currentColor"
            ></path>
          </svg>

          {/* Search Input */}
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
            className="border rounded-md p-2 pl-10 w-full focus:border-indigo-500 focus:outline-none"
          />


          {/* Clear Icon (X) */}
          {searchValue && (
            <button
              onClick={handleClearSearch}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6L18 18M6 18L18 6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
        <div>
        <div className="flex items-center">
        <div className="relative" ref={dropdownContainerRef}>
          <div className="flex gap-5 text-center">
            <button
              onClick={toggleDropdownVisibility}
              className="text-[#6559ff] hover:text-blue-600 hover:bg-indigo-50 px-2 py-2 rounded-md"
            >
              + Add filter
            </button>

            {selectedTemplates?.length === 0 && (
              <>
                {selectedTitle === "" ? (
                  <></>
                ) : (
                  <button
                    onClick={clearFilter}
                    className="gap-3 text-center items-center text-blue-800 text-xs font-medium me-2 flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                  >
                    {selectedTitle}
                  </button>
                )}
              </>
            )}

            {selectedTemplates?.map((item, index) => (
              <button
                key={index}
                onClick={clearFilter}
                className="bg-blue-100 gap-3 text-center items-center text-blue-800 text-xs font-medium me-2 flex px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
              >
                {item?.title}
                <BiCross />
              </button>
            ))}
          </div>

          {isDropdownOpen && (
            <div className="absolute mt-2 h-80 overflow-scroll w-48 rounded-md shadow-lg bg-white">
              <ul className="py-1 text-start text-gray-700">
                {getTamplateList?.data?.templates.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => selectTemplate(item?.title)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {item?.title}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
        </div>
      </div>
      {sheduleMy ? (
        selectedTemplate === "" ? (
          searchValue === "" ? (
            sheduleMy?.data?.schedules?.length > 0 ? (
              <>
                {sheduleMy.data.schedules.map((item, index) => (
                  <div
                    key={item.id || index}
                    style={{ borderRadius: "12px" }}
                    onClick={() => {
                      handlShowmodal(item);
                    }}
                    className="flex mt-12 z-10 items-center text-center border-2 border-bold hover:border-indigo-300 p-5 rounded-full"
                  >
                    <div>
                      <button
                        className="bg-gray-300 text-gray-800 text-xl font-bold me-2 px-2 py-2 rounded-full dark:bg-gray-700 dark:text-gray-300"
                      >
                        <CiCalendar size={22} fontWeight={50} />
                      </button>
                    </div>
                    <div className="flex flex-col text-start">
                      <h1 className="text-xl text-gray-600">
                        {item?.templateTitle} - {item?.assetName} - {item?.frequency}
                      </h1>
                      <p className="text-gray-600">
                        Starts on{" "}
                        {new Date(item?.createdAt).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        . Due{" "}
                        {new Date(item?.nextDue).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                ))}
                <h2 className="text-gray-400 mt-20">
                  Scheduled inspections assigned to you starting in the next 7 days will show here.
                </h2>
              </>
            ) : (
              <p className="text-gray-600 text-center mt-4">No data available</p>
            )
            
          ) : filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <div
                key={index}
                style={{ borderRadius: "12px" }}
                className="flex mt-12 z-10 items-center text-center border border-bold p-10 round-full"
              >
                <div>
                  <button
                    onClick={() => {
                      handlShowmodal(item);
                    }}
                    className="bg-gray-100 text-gray-800 text-xl font-bold me-2 px-4 py-4 rounded-full dark:bg-gray-700 dark:text-gray-300"
                  >
                    <CiCalendar size={30} fontWeight={50} />
                  </button>
                </div>
                <div className="flex flex-col text-start">
                  <h1 className="text-xl text-gray-600">
                    {item?.templateTitle} - {item?.assetName} -{" "}
                    {item?.frequency}
                  </h1>
                  <p className="text-gray-600">
                    Starts on{" "}
                    {new Date(item?.createdAt).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                    . Due{" "}
                    {new Date(item?.nextDue).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center text-center h-64  rounded-md mt-20">
              <svg viewBox="0 0 138 138" fill="none" width="150" mt-10 height="150" focusable="false"><path d="M68.468 77.95l-8.146-8.11-13.01 12.952 8.147 8.11L68.468 77.95z" fill="#6E6BFF"></path><path d="M54.23 75.864a56.27 56.27 0 003.852 4.28 56.332 56.332 0 004.298 3.834l6.087-6.06-8.15-8.08-6.087 6.026z" fill="#4740D4"></path><path d="M3.232 134.782c-4.298-4.28-4.298-11.263 0-15.543L41.68 80.965a2.686 2.686 0 013.817 0L57.29 92.707a2.657 2.657 0 010 3.8l-38.446 38.275c-4.299 4.279-11.314 4.279-15.612 0z" fill="#14CAFF"></path><path d="M92.332 90.859c25.203 0 45.634-20.34 45.634-45.43S117.535 0 92.332 0C67.13 0 46.7 20.34 46.7 45.43s20.43 45.429 45.633 45.429z" fill="#6E6BFF"></path><path d="M92.332 83.053c20.873 0 37.793-16.845 37.793-37.624 0-20.779-16.92-37.624-37.793-37.624C71.46 7.805 54.54 24.65 54.54 45.43c0 20.78 16.92 37.624 37.792 37.624z" fill="#4740D4"></path><path d="M92.367 80.144c19.258 0 34.87-15.543 34.87-34.715 0-19.172-15.612-34.713-34.87-34.713-19.258 0-34.87 15.542-34.87 34.713 0 19.172 15.612 34.715 34.87 34.715z" fill="#DFF8FE"></path><path d="M58.598 79.082l-.654 9.346 5.743-5.786-5.09-3.56z" fill="#4740D4"></path><path d="M52.289 101.478l-15.61-15.541-2.846 2.832 15.611 15.541 2.845-2.832z" fill="#0098E3"></path><path d="M43.054 95.138l-.516 16.056 8.322-8.285-7.806-7.77z" fill="#0098E3"></path></svg>
              <p className="text-gray-600 mt-4 text-lg font-medium">
                No results found
              </p>
              <p className="text-gray-500 text-sm mt-2 mb-10">
              Try again with different search terms or filters
              </p>
            </div>
          )
        ) : selectedTemplate.length > 0 ? (
          selectedTemplate.map((item, index) => (
            <div
              key={index}
              style={{ borderRadius: "12px" }}
              className="flex mt-12 z-10 items-center text-center border border-bold p-10 round-full"
            >
              <div>
                <button
                  onClick={() => {
                    handlShowmodal(item);
                  }}
                  className="bg-gray-100 text-gray-800 text-xl font-bold me-2 px-4 py-4 rounded-full dark:bg-gray-700 dark:text-gray-300"
                >
                  <CiCalendar size={30} fontWeight={50} />
                </button>
              </div>
              <div className="flex flex-col text-start">
                <h1 className="text-xl text-gray-600">
                  {item?.templateTitle} - {item?.assetName} - {item?.frequency}
                </h1>
                <p className="text-gray-600">
                  Starts on{" "}
                  {new Date(item?.createdAt).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}{" "}
                  . Due{" "}
                  {new Date(item?.nextDue).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center h-64  rounded-md ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 120 120"
            >
              <rect
                width="15"
                height="62.367"
                x="76.95"
                y="57.266"
                opacity=".35"
                transform="rotate(-45.001 84.45 88.451)"
              ></rect>
              <rect
                width="15"
                height="62.367"
                x="76.95"
                y="53.266"
                fill="#0037ff"
                transform="rotate(-45.001 84.45 84.451)"
              ></rect>
              <circle cx="49" cy="53" r="37" opacity=".35"></circle>
              <circle cx="49" cy="49" r="37" fill="#0075ff"></circle>
              <circle cx="49" cy="53" r="28" opacity=".35"></circle>
              <circle cx="49" cy="49" r="28" fill="#a4e2f1"></circle>
            </svg>
            <p className="text-gray-600 mt-4 text-lg font-medium">
              No results found
            </p>
            <p className="text-gray-500 text-sm mt-2">
            Try again with different search terms or filters
            </p>
          </div>
        )
      ) : (
        <div className="mt-4 rounded-md overflow-x-auto">
          <table className="w-full min-w-max text-left">
            <tbody>
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No scheduled inspections due in the next 7 days
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm md:text-base text-center">
                      You don't have any scheduled inspections starting in the
                      next 7 days. Learn more.
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

    <div
      id="drawer-right-example"
      className={`fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto ${
        showModal === true ? "" : "translate-x-full"
      } transition-transform bg-white w-[70vh] dark:bg-gray-400`}
      tabindex="-1"
      aria-labelledby="drawer-right-label"
    >
        <h5
        id="drawer-right-label"
        className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
      >
          Schedule Inspection
        </h5>
        <button
        
          onClick={() => {
            setShowModal(false);
          }}
          type="button"
          data-drawer-hide="drawer-right-example"
          aria-controls="drawer-right-example"
          className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 start-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
        <div className="flex flex-col items-start py-12 text-start space-y-4">
          <p className="text-2xl ">
            {data?.templateTitle} - {data?.assetName} - {data?.frequency}
          </p>

          <p className="text-gray-500">{data?.title}</p>

          <p className="mt-4 text-xs text-gray-500">Schedule Window</p>

          <p className="text-sm">
            Starts{" "}
            {new Date(data?.createdAt).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            {new Date(data?.createdAt).toLocaleTimeString("en-GB", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true, // Use 12-hour format (AM/PM)
            })}{" "}
            <span className="text-gray-500">. Due:</span>{" "}
            {new Date(data?.nextDue).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            {new Date(data?.nextDue).toLocaleTimeString("en-GB", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true, // Use 12-hour format (AM/PM)
            })}
          </p>

          <p className="text-sm text-gray-600">
            Assigned by: <br /> <span className="text-lg">{data?.assigneeName}</span> 
          </p>
        </div>

        <div class="grid p-2 border-8-black  rounded border border-gray-400  grid-cols-1 gap-4">
          <p className="flex justify-start text-sm text-gray-500">Assigned to </p>
          <p className="flex justify-start text-lg">{data?.assigneeName} </p>

          <a
            href="#"
            class="px-4 py-2 text-sm font-medium text-center text-white bg-indigo-500 hover:bg-indigo-600 border border-gray-200 rounded-lg focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-300 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Start inspection
          </a>
          <a
            href="#"
            class="px-4 py-2 text-sm font-medium text-center text-indigo-500 bg-white border border-gray-200 hover:bg-gray-100 rounded-lg focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-300 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Won't do
          </a>
        </div>
      </div>
    </div>
  );
};

export default MySchedules;
