import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../assets/home/SSC Logo Final svg.svg";
import useHttp from "../../common/hooks/useHttp";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../context/AuthContext";
import Loader from "../../common/loader/Loader";

const Login = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar"; // Check if the current language is Arabic
  const navigate = useNavigate();
  const { sendRequest, loading } = useHttp();
  const { setAuth, authData } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const openOrganizationModal = (organizations) => {
    setAvailableOrganizations(organizations);
    setModalVisible(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let deviceId;
    if (authData) {
      deviceId = authData.deviceId;
    } else {
      deviceId = uuidv4();
    }

    try {
      await sendRequest(
        {
          url: "v1/auth/login",
          method: "POST",
          payload: {
            email: formData.email,
            password: formData.password,
            deviceId: deviceId,
            deviceType: "web",
          },
        },
        (response) => {
          console.log(response, "this is login res");
          if (response) {
            if (response.responseCode == "200") {
              const { token, organizations, userDetails } =
                response?.data || {};

              if (token?.accessToken) {
                const accessToken = token.accessToken;
                const refreshToken = token.refreshToken;

                if (organizations?.length === 1) {
                  const organizationId = organizations[0].organizationId;
                  setAuth({
                    accessToken,
                    refreshToken,
                    deviceId,
                    userName: formData.email,
                    organizationId,
                    userDetails,
                  });
                  Swal.fire({
                    icon: "success",
                    title: response.responseDesc,
                    text: "You will be redirected shortly.",
                    timer: 2000,
                    showConfirmButton: false,
                  });

                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 2000);
                } else if (organizations?.length > 1) {
                  openOrganizationModal(organizations);
                }
              }
            } else {
              Swal.fire({
                icon: "error",
                title: response.responseDesc,
                text: "Login Failed",
              });
            }
          }
        }
      );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: `An error occurred during login. ${error}`,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          dir={isArabic ? "rtl" : "ltr"} // Set direction based on the current language
          className="flex flex-col items-center min-h-screen bg-[#E9EEF6]"
        >
          <div className="flex justify-center w-full my-6">
            <img src={Logo} alt="SafetyCulture Logo" className="h-24 w-auto" />
          </div>

          <div className="w-[90%] max-w-[500px] shadow-lg rounded-lg p-6">
            <h1 className="text-xl font-semibold text-gray-800 mb-6 text-start">
              {t("loginpage.login")}
            </h1>

            <form onSubmit={handleLogin}>
              <label
                htmlFor="email"
                className="block text-[#1f2533] mb-2 font-medium text-start"
              >
                {t("loginpage.email")}
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("loginpage.emailplaceholder")}
                required
              />

              <label
                className="text-start block text-[0.875rem] font-semibold text-[#3f495a] mb-1"
                htmlFor="password"
              >
                {t("loginpage.password")}
              </label>
              <div className="relative mb-4">
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500"
                  placeholder={t("loginpage.passwordplaceholder")}
                  required
                />
              </div>

              <div className="flex justify-between items-center mb-4">
                <span
                  className="text-sm text-[#605cf8] font-semibold hover:underline cursor-pointer"
                  onClick={() => navigate("/forgot-password")}
                >
                  {t("loginpage.forgetpassword")}
                </span>
              </div>

              <button
                type="submit"
                className="w-full bg-[#675df4] text-white font-semibold py-2.5 rounded-md hover:bg-[#5048c8] focus:outline-none"
              >
                {t("loginpage.continuebtn")}
              </button>
            </form>

            <p className="mt-5 text-sm text-gray-600 text-center">
              {t("loginpage.donotacc")}
              <span
                className="text-[#605cf8] font-semibold hover:underline cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                {t("loginpage.signup")}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
 