import React, { useState, useRef, useEffect } from "react";

function ReportIssue() {
  const [selectedIssue, setSelectedIssue] = useState(null); // Track selected issue type
  const formRef = useRef(null);

  const issueTypes = ["Hazard", "Incident", "Maintenance", "Near Miss", "Observation"];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    setCurrentDate(formattedDate);
  }, []);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const isFormValid = formData.title.trim() !== "" && formData.description.trim() !== "" && formData.site.trim() !== "";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSelectIssue = (issue) => {
    setSelectedIssue(issue);
  };

  const handleCloseForm = () => {
    setSelectedIssue(null);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleBackClick = () => {
    setIsPopupOpen(true); // Open the popup when back button is clicked
  };

  const handleConfirmExit = () => {
    setIsPopupOpen(false); // Close the popup
    window.history.back(); // Go back to the previous page
  };

  const handleCancel = () => {
    setIsPopupOpen(false); // Close the popup without any action
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setSelectedIssue(null);
      }
    };

    if (selectedIssue) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedIssue]);

  return (
    <>
    <div className="w-full flex items-center px-6 bg-white p-4">
        <button
        className="flex items-center space-x-2 text-indigo-500"
        onClick={handleBackClick}
      >
        <svg
          viewBox="0 0 24 24"
          width="1.125rem"
          height="1.125rem"
          fill="#4740d4"
          focusable="false"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
        </svg>
        <span className="text-md font-normal">Back</span>
      </button>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-xl w-full">
            <h2 className="text-2xl font-medium text-left text-gray-800 mb-4">
              Exit without submitting?
            </h2>
            <p className="text-left text-xl text-gray-600">Changes you made to this issue will not be saved. If you’ve finished editing, submit the issue to report it.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmExit}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

        {/* Title */}
        <h1 className="text-xl font-semibold text-gray-800 absolute left-1/2 transform -translate-x-1/2">
            Report Issue
        </h1>
    </div>
    <div className="bg-blue-50 min-h-screen flex flex-col items-center py-8 px-4">
      {/* Main Content */}
      <div className="w-full max-w-lg mt-6">
      <div className="bg-white rounded-lg shadow px-4 py-2 text-left mb-4">
        <span className="text-gray-800 text-lg">Date</span>
        <p className="text-gray-800 mt-1 text-lg">{currentDate}</p>
      </div>

        {/* Display Issue Types */}
        {!selectedIssue && (
          <div className="space-y-4 bg-white">
            <div className="text-left px-2">What type of issue?</div>
            <div className="px-4">
              {issueTypes.map((issueType, index) => (
                <button
                  key={index}
                  className="w-full border border-gray-300 bg-white rounded-lg py-3 px-2 text-blue-600 text-center hover:bg-indigo-100 mb-4"
                  onClick={() => handleSelectIssue(issueType)}
                >
                  {issueType}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Form Section */}
        {selectedIssue && (
          <div
            ref={formRef}
            className="bg-white rounded-lg shadow mt-6 p-6 space-y-4 relative"
          >
            <form className="space-y-6 text-left">
              {/* Date */}
              {/* <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="date">Date</label>
                <input
                  type="text"
                  id="date"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  value="7 Jan 2025"
                  readOnly
                />
              </div> */}

              {/* Type of issue */}
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="type">What type of issue?</label>
                <select
                  id="type"
                  className="mt-1 w-full hover:bg-white hover:indigo-400 border border-gray-300 bg-white rounded-md p-2 hover:border-indigo-500"
                  >
                  <option>Hazard</option>
                  <option>Incident</option>
                  <option>Maintenance</option>
                  <option>Near Miss</option>
                  <option>Observation</option>
                </select>
              </div>

              {/* Title */}
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="title">
                  Title (required)
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Add title"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Description */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="description">
                  Description (optional)
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Add description"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Site */}
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="site">Site (optional)</label>
                <select
                  id="site"
                  className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option>Select</option>
                </select>
              </div>

              {/* Add media */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Add images or video? (optional)
                </label>
                <input
                  type="file"
                  id="file-upload"
                  accept="image/*,video/*"
                  multiple
                  className="hidden"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length) {
                      console.log('Selected files:', files);
                    }
                  }}
                />
                <button
                  type="button"
                  className="mt-2 w-full flex justify-center items-center px-4 py-2 bg-indig-800 text-white rounded-md bg-indigo-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  onClick={() => document.getElementById('file-upload').click()}
                >
                  Add media
                </button>
                <p className="mt-2 text-sm text-gray-500">
                  You can only add up to 10 media files, with a maximum size of 1.5GB and a duration of 3 minutes for videos. The maximum image size for images is 10MB.
                </p>
              </div>

              {/* Location */}
              <div>
                {/* Location Input and Map Button */}
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="location"
                >
                  Location (optional)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    id="location"
                    placeholder="Location"
                    className="flex-grow mt-1 block border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <button
                    type="button"
                    onClick={toggleModal}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md  focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  >
                    Map
                  </button>
                </div>

                {/* Modal Popup */}
                {isModalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
                      <h2 className="text-lg font-semibold mb-4">Select location</h2>

                      {/* Address Search */}
                      <div className="flex items-center space-x-2 mb-4">
                        <input
                          type="text"
                          placeholder="Type address"
                          className="flex-grow border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none">
                          Search
                        </button>
                        <button className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none">
                          Locate me
                        </button>
                      </div>

                      {/* Map */}
                      <div className="mb-4">
                        <iframe
                          title="Map"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125229.51643095636!2d151.07594045312503!3d-33.847927150000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b0d0c73e0c69%3A0x5017d681632c9a0!2sSydney%20NSW%2C%20Australia!5e0!3m2!1sen!2sus!4v1619091451544!5m2!1sen!2sus"
                          className="w-full h-64 border rounded-md"
                          allowFullScreen=""
                          loading="lazy"
                        ></iframe>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex justify-end space-x-4">
                        <button
                          type="button"
                          onClick={toggleModal}
                          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        >
                          Save and apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* What caused it? */}
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="cause">What caused it? (optional)</label>
                <textarea
                  id="cause"
                  placeholder="Enter response..."
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="2"
                ></textarea>
              </div>

              {/* What needs to be done? */}
              <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="action">What needs to be done? (optional)</label>
                <textarea
                  id="action"
                  placeholder="Enter response..."
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="2"
                ></textarea>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-white text-indigo-700 border border-gray-500 rounded-md hover:bg-indigo-100 focus:ring-2 focus:ring-gray-300 focus:outline-none"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!isFormValid}
                  className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 ${
                    isFormValid
                      ? "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500"
                      : "bg-white text-gray-400 cursor-not-allowed border border-gray-300"
                  }`}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
    </>
  );
}

export default ReportIssue;
