import React from "react";
import { useLocation } from "react-router-dom";
import PortalSidebar from "../portalSidebar";

const PotalLayout = ({ children }) => {
  const location = useLocation();

  // Define routes where the sidebar should be hidden
  const routesWithoutSidebar = ["/dashboard/edit-category", "/dashboard/add-credentials-process","/dashboard/credential-upload-csv","/dashboard/category-access", "/dashboard/edit-type", "/dashboard/start-inspection", "/dashboard/inspectionpage-2", "/dashboard/create-template","/dashboard/create-headsup","/dashboard/report-issue"];

  // Check if the current route is in the listconst hideSidebar =
  const hideSidebar =
    routesWithoutSidebar.includes(location.pathname) ||
    location.pathname.includes("/dashboard/start-inspection");

  return (
    <div className="layout overflow-hidden">
      <main className="flex">
        {/* Conditionally render the sidebar */}
        {!hideSidebar && (
          <div className="w-[20%]">
            <PortalSidebar />
          </div>
        )}
        <div
          className={`container mx-auto ${hideSidebar ? "w-full" : "w-[83%]"}`}
        >
          {children}
        </div>
      </main>
    </div>
  );
};

export default PotalLayout;
