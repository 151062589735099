import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";
import Loader from "../../../common/loader/Loader";
import { useNotification } from "../../../common/hooks/useNotification";
import { FaEllipsisV } from "react-icons/fa";
// import { sendRequest, getAssetTypes, notify } from './api'; 

const Assetimg = require("../../../assets/adminassets/trolli.png");
const AssetType = () => {
  const { authData } = useAuth();
  const notify = useNotification();

  const { sendRequest, loading } = useHttp();
  const [assetTypeName, setAssetTypeName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [assetTypes, setAssetTypes] = useState([]);
  const [currentType, setCurrentType] = useState(null);
  const [isTypePopupOpen, setIsTypePopupOpen] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null); // Reference to the dropdown menu
  const dropdownButtonRef = useRef(null);
  

  const navigate = useNavigate();

  const handleSaveTypeRename = async () => {
    if (currentType && currentType.name) {
      try {
        await sendRequest(
          {
            url: "v1/asset/type/update",
            method: "PUT",
            accessToken: authData.accessToken,
            headers: {
              userName: authData.userName,
              organizationId: authData.organizationId,
              deviceId: authData.deviceId,
            },
            payload: {
              assetTypeId: currentType.id,
              assetTypeName: currentType.name,
            },
          },
          (response) => {
            if (response) {
              if (response.responseCode == "200") {
                setCurrentType(null);
                setIsTypePopupOpen(false);
                getAssetTypes();
                notify(response.responseDesc, "success");
              } else {
                notify(response.responseDesc, "error");
              }
            }
          }
        );
      } catch (error) {
        console.error("Error getting:", error.response);
      }
    }
  };

  const handleDeleteType = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/asset/field/delete?assetFieldId=${id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getAssetTypes();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  // Open popup
  const handleCreateTypeClick = () => {
    setShowPopup(true);
  };

  // Close popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Navigate to SaveField page
  const handleSaveClick = () => {
    setShowPopup(false); // Close popup before navigating
    navigate("/dashboard/asset-field"); // Adjust the route as needed
  };

  const handleEditType = (typeId, typeName) => {
    setCurrentType({ id: typeId, name: typeName }); // Set the field to be renamed
    setIsTypePopupOpen(true); // Open the rename popup
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // If the clicked element is outside both the dropdown and the dropdown button, close the dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !dropdownButtonRef.current.contains(e.target)
      ) {
        setOpenDropdown(null); // Close the dropdown
      }
    };

    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAssetTypes = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/types/details",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response, "these are asset type");
          setAssetTypes(response.data.assetTypesDetails);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const filteredAssetTypes = assetTypes.filter((type) =>
    type.assetType.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSaveAssetType = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/type/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            assetTypeName: assetTypeName,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setAssetTypeName("");
              setSearchTerm("");
              handleClosePopup();
              getAssetTypes();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleChange = (e) => {
    // Regular expression to match only letters (a-z, A-Z) and spaces
    const regex = /^[A-Za-z\s]*$/;

    const value = e.target.value;

    if (regex.test(value)) {
      setAssetTypeName(value); // Update state if the value matches the regex
    } else {
      // Optionally, you can show an error message or simply do nothing
      console.log("Special characters are not allowed!");
    }
  };
  useEffect(() => {
    getAssetTypes();
  }, [authData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mr-14">
          <div className="p-6 bg-white rounded-lg shadow-lg w-full mt-4">
            <div className="flex items-center mb-4">
              {/* Back Arrow Icon */}
              <FaArrowLeft
                className="text-black text-2xl cursor-pointer mr-2"
                onClick={() => navigate(-1)}
              />
              {/* Heading */}
              <h3 className="font-bold text-2xl text-left">Assets Settings</h3>
            </div>
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-2xl font-light">Manage types</h1>
              <div className="flex items-center space-x-4">
                <button
                  className="bg-purple-600 text-white px-4 py-2 rounded-md"
                  onClick={handleCreateTypeClick}
                >
                  Create type <span className="ml-1">+</span>
                </button>
              </div>
            </div>
            <div className="flex justify-left">
              <input
                type="text"
                placeholder="Search"
                className="w-72 px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="mt-4 rounded-md overflow-x-auto">
              <table className="w-full min-w-max text-left border-collapse bg-white">
                <thead>
                  <tr className="bg-indigo-50 text-gray-700 font-medium h-14 border-b border-gray-300">
                    <th className="p-4 text-sm">Type name</th>
                    <th className="p-4 text-sm">Active assets</th>
                    <th className="p-4 text-sm">Archived assets</th>
                    <th className="p-4 text-sm">Number of fields</th>
                    <th className="p-4 text-sm text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssetTypes.length > 0 ? (
                    filteredAssetTypes.map((type, index) => (
                      <tr
                        key={type.assetType.id}
                        className="hover:bg-purple-50 cursor-pointer transition duration-200 border border-grey-500"
                        onClick={() =>
                          navigate(
                            `/dashboard/assets/types/${type.assetType.id}`
                          )
                        }
                      >
                        <td className="p-4 text-gray-800">
                          {type.assetType.name.length > 30
                            ? `${type.assetType.name.slice(0, 30)}...`
                            : type.assetType.name}
                        </td>
                        <td className="p-4 text-gray-600">
                          {type.activeAssetCount}
                        </td>
                        <td className="p-4 text-gray-600">
                          {type.archivedAssetCount}
                        </td>
                        <td className="p-4 text-gray-600">
                          {type.fieldsCount}
                        </td>
                        <td className="p-4 text-center relative">
                          <div className="relative">
                            <button
                              ref={dropdownButtonRef}
                              onClick={(e) => {
                                e.stopPropagation(); // Stop event propagation
                                setOpenDropdown(
                                  openDropdown === type.assetType.id
                                    ? null
                                    : type.assetType.id
                                );
                              }}
                              className="text-gray-600 hover:text-purple-600"
                            >
                              <FaEllipsisV />
                            </button>
                            {openDropdown === type.assetType.id && (
                              <div
                                ref={dropdownRef}
                                className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                              >
                                <ul className="py-1 text-sm text-gray-700">
                                  <li
                                    className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Stop event propagation
                                      handleDeleteType(type.assetType.id);
                                    }}
                                  >
                                    {/* Dustbin Icon */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-5 h-5 text-gray-500"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 7l-1 12a2 2 0 01-2 2H8a2 2 0 01-2-2L5 7m5 4v6m4-6v6M9 7h6m-7 0a2 2 0 012-2h4a2 2 0 012 2m-7 0h8"
                                      />
                                    </svg>

                                    {/* Delete Text */}
                                    <span>Delete type</span>
                                  </li>

                                  <li
                                    className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={(e) => {
                                      handleEditType(
                                        type.assetType.id,
                                        type.assetType.name
                                      );
                                    }}
                                  >
                                    {/* Edit Icon */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-5 h-5 text-gray-500"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2v-5m-5.5-4.5L17 7m-2.5 2.5L7 17m7-7l2.5-2.5m-2.5 2.5L10.5 14"
                                      />
                                    </svg>

                                    {/* Rename Text */}
                                    <span>Rename type</span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center p-6">
                        <div className="flex flex-col items-center mt-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-12 h-12 mx-auto mb-4 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 4a7 7 0 100 14 7 7 0 000-14zM21 21l-4.35-4.35"
                            />
                          </svg>
                          <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                            No matching found text{" "}
                          </p>
                          <p className="text-gray-600 text-sm sm:text-base md:text-sm text-center">
                            Try again with different search terms.
                          </p>
                          <div className="flex items-center space-x-4 mt-4">
                            <button
                              className="bg-purple-600 text-white px-4 py-2 rounded-md"
                              onClick={handleCreateTypeClick}
                            >
                              + Create type
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {isTypePopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-96">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Rename Type
            </h3>
            <input
              type="text"
              value={currentType?.name || ""}
              onChange={(e) =>
                setCurrentType({ ...currentType, name: e.target.value })
              }
              className="w-full p-2 border rounded-md mb-4"
              placeholder="Enter new type name"
            />
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                onClick={() => setIsTypePopupOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={handleSaveTypeRename} // Save the renamed type
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 sm:w-96 max-w-lg mx-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 text-left">
              Create new type
            </h2>
            <label className="flex items-start mb-2"> Name of type </label>
            <input
              type="text"
              value={assetTypeName}
              onChange={handleChange}
              placeholder="Enter asset type"
              className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              maxLength={130}
            />
            {/* Optional: Show a message when invalid input is detected */}
            {/* <p className="text-red-500 text-sm">Special characters are not allowed.</p> */}

            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-md ${
                  assetTypeName.trim() ? " bg-[#6559ff]" : "bg-indigo-300 bg-[#b1abff]"
                } text-white`}
                onClick={handleSaveAssetType}
                disabled={!assetTypeName.trim()}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssetType;
