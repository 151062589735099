import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";
import { useNotification } from "../../../common/hooks/useNotification";

export function ProfileHeader() {
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const { authData } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleClick = () => {
    setIsPopupOpen(true);
  };
  const [audthID, setAuthId] = useState(authData.userDetails?.id);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result); // Store image as Base64 URL
      };
      reader.readAsDataURL(file);
      console.log(reader);
    }
    console.log(event.target.files[0]);
  };
  console.log({
    payload: {
      userId: audthID,
      image: selectedImage,
    },
  });

  const saveImage = async () => {
    try {
      await sendRequest(
        {
          url: `v1/user/uploadProfileImage`,
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            userId: audthID,
            image: selectedImage,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log("something wrong", err.response);
    }
  };
  return (
    <>
      <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="Profile"
            className="w-full h-full object-cover rounded-full"
          />
        ) : (
          (
            authData &&
            authData.userName.charAt(0) +
              authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()
        )}
        <FaEdit
          onClick={handleClick}
          className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
        />
      </div>
      <div className="ml-4">
        <h2
          className="text-xl text-left font-semibold truncate max-w-xs"
          title={`${authData?.userDetails?.firstName || ""} ${
            authData?.userDetails?.lastName || ""
          }`}
        >
          {authData?.userDetails?.firstName || ""}{" "}
          {authData?.userDetails?.lastName || ""}
        </h2>

        <p className="flex text-start text-gray-600">
          {authData?.userDetails?.email}
        </p>
      </div>
      {isPopupOpen && (
        <div
          style={{ zIndex: "99999999" }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-[40%] ">
            <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
            <div class="flex items-center justify-center w-full">
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click to upload</span> or drag
                    and drop
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <form onSubmit={saveImage}>
                  <input value={audthID} type="text" class="hidden" />
                  <input
                    onChange={handleImageChange}
                    accept="image/*"
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                  />
                  <div className="flex justify-end mt-10">
                    <button
                      onClick={() => setIsPopupOpen(false)}
                      className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={saveImage}
                      className="px-4 py-2 bg-blue-500 text-white rounded"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
