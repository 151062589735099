import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
const UserNotification = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility
  const { authData } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => setIsEditing(!isEditing);

  const handleGlobalToggle = (type) => {
    const updatedGlobal = { ...settings.global, [type]: !settings.global[type] };
    const updatedActions = settings.actions.map((action) => ({
      ...action,
      [type]: updatedGlobal[type],
    }));
    const updatedIssues = settings.issues.map((issue) => ({
      ...issue,
      [type]: updatedGlobal[type],
    }));
    const updatedInspections = settings.inspections.map((inspection) => ({
      ...inspection,
      [type]: updatedGlobal[type],
    }));
    let updatedSchedules = [];
    if (settings.schedules)
    {
      updatedSchedules = settings.schedules.map((schedule) => ({
        ...schedule,
        [type]: updatedGlobal[type],
      }));
    }
    else{
      updatedSchedules = [];
    }

    const updatedHeadsUp = settings.headsup.map((headsup) => ({
      ...headsup,
      [type]: updatedGlobal[type],
    }));

    const updatedCredentials = settings.credentials.map((credential) => ({
      ...credential,
      [type]: updatedGlobal[type], // Update the specific type (push/email/sms) for each credential
    }));
    
    setSettings({ global: updatedGlobal, actions: updatedActions, issues: updatedIssues, inspections: updatedInspections, schedules: updatedSchedules, headsup: updatedHeadsUp, credentials: updatedCredentials });
  };

  const handleActionToggle = (id, type) => {
    const updatedActions = settings.actions.map((action) =>
      action.id === id ? { ...action, [type]: !action[type] } : action
    );
    setSettings({ ...settings, actions: updatedActions });
  };

  const handleIssueToggle = (id, type) => {
    const updatedIssues = settings.issues.map((issue) =>
      issue.id === id ? { ...issue, [type]: !issue[type] } : issue
    );
    setSettings({ ...settings, issues: updatedIssues });
  };

  const handleInspectionToggle = (id, type) => {
    const updatedInspections = settings.inspections.map((inspection) =>
      inspection.id === id ? { ...inspection, [type]: !inspection[type] } : inspection
    );
    setSettings({ ...settings, inspections: updatedInspections });
  };

  const handleScheduleToggle = (id, type) => {
    if (settings.schedules) {
      const updatedSchedules = settings.schedules.map((schedule) =>
        schedule.id === id ? { ...schedule, [type]: !schedule[type] } : schedule
      );
      setSettings({ ...settings, schedules: updatedSchedules });
    }
  };

  const handleHeadsUpToggle = (id, type) => {
    const updatedHeadsUp = settings.headsup.map((item) =>
      item.id === id ? { ...item, [type]: !item[type] } : item
    );
    setSettings({ ...settings, headsup: updatedHeadsUp });
  };

  const handleCredentialsToggle = (id, type, updatedGlobal) => {
    const updatedCredentials = settings.credentials.map((credential) => ({
      ...credential,
      [type]: updatedGlobal[type], // Update the specific type (push/email/sms)
    }));
    setSettings({ ...settings, credentials: updatedCredentials });
  };



  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };

    navigate(routes[tab]);
  };

  const handleClick = () => {
    setIsPopupOpen(true); // Open popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close popup
  };
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };

  const loadSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem("notificationSettings"));
    if (savedSettings) {
      setSettings(savedSettings);
    }
  };

  useEffect(() => {
    loadSettings(); // Load settings on initial render
  }, []);

  const notifications = [
    { title: "Action assigned", description: "Push*, Email", tooltip: "Receive a notification when an action is assigned to you." },
    { title: "Action completed", description: "Push*, Email", tooltip: "Receive a notification when an action you created has been completed." },
    { title: "Comments added", description: "Push*, Email", tooltip: "Receive a notification when any comment added to an action you're following." },
    { title: "Media added", description: "Push*, Email", tooltip: "Receive a notification when any media added to an action you're following." },
    { title: "Actions reminders due soon", description: "Push*, Email", tooltip: "Receive a notification when an action created by you or assigned to you is due soon." },
    { title: "Actions reminders overdue", description: "Push*, Email", tooltip: "Receive a notification when an action created by you or assigned to you is overdue." },
  ];
  const [issuesData] = useState([
    { title: "New issue reported", channels: "Email, SMS", tooltip: "Receive a notification when a new issue has been reported." },
    { title: "Issue assigned or shared", channels: "Push, Email", tooltip: "Receive a notification when an issue is assigned or shared to you." },
    { title: "Issue resolved", channels: "Push, SMS", tooltip: "Receive a notification when an issue you created is resolved." },
    { title: "Comments, or answers added", channels: "Push, SMS", tooltip: "Receive a notification when any comment, or answer is added to an issue you're following." },
    { title: "Media added", channels: "Push, SMS", tooltip: "Receive a notification when any media added to an issue you're following." },
    { title: "Issue reminders due soon", channels: "Push, SMS", tooltip: "Receive a notification when an issue assigned to you is due soon." },
    { title: "Issue reminders overdue", channels: "Push, SMS", tooltip: "Receive a notification when an issue assigned to you is overdue." },
  ]);
  const [inspections] = useState([
    { title: "Inspection triggered notifications", description: "Notification when an inspection is scheduled", tooltip: "Receive notifications that are triggered from within an inspection." },
    { title: "Inspection request approval", description: "Notification when an inspection is completed", tooltip: "Receive a notification when your approval is requested for an inspection." },
    { title: "Inspection approved", description: "Notification when an inspection report is added", tooltip: "Receive a notification when your inspection is approved." },
  ]);
  const [schedules] = useState([
    { title: "Scheduled inspection starting reminder", notifications: "Daily at 9:00 AM", tooltip: "Receive a notification when a scheduled inspection assigned to you is about to start." },
    {
      title: "Scheduled inspection due soon reminder",
      notifications: "Weekly on Mondays",
      tooltip: `Receive a notification when a scheduled inspection assigned to you is due soon. Depending on the schedule duration, you will receive notifications:
    
      - Daily or less: One hour prior.
      - Weekly or greater than 24 hours: 24 hours and one hour prior.
      - Monthly or greater than seven days: One week and one day prior.`,
    },
    { title: "Scheduled inspection overdue reminder", notifications: "Weekly on Mondays", tooltip: "Receive a notification when a scheduled inspection assigned to you is overdue" },
  ]);
  const [headsup] = useState([
    { title: "Heads Up assigned", notifications: "Daily at 9:00 AM", tooltip: "Receive a notification when a Heads Up gets assigned to you" },
    { title: "Assigned Heads Up reminder", notifications: "Weekly on Mondays", tooltip: "Receive a notification when a Heads Up that's assigned to you is still incomplete after 24 hours" },
    { title: "Heads Up completion rate", notifications: "Weekly on Mondays", tooltip: "Receive a notification when a Heads Up you published has progressed" },
    { title: "Heads Up completed", notifications: "Weekly on Mondays", tooltip: "Receive a notification when a Heads Up you published has been completed" },
    { title: "Published Heads Up reminder", notifications: "Weekly on Mondays", tooltip: "Receive a notification when a Heads Up you published is incomplete after 24 hours" },
    { title: "New comment or reply", notifications: "Weekly on Mondays", tooltip: "They'll receive a notification when a Heads Up they published is commented on or a comment of theirs has a new reply." },
  ]);
  const [credentials] = useState([
      {
        title: 'Credential reminder',
        description: 'Push*, Email',
        tooltip: `They'll receive a notification when one or more courses assigned to them is due soon or overdue. Depending on a course is due, they will receive notifications:
  
      Due soon reminder: Two weeks and one week prior.
      Due today reminder: On the due date.
      Overdue reminder: One week and two weeks after.`,
    },
      {
        title: 'Credential resubmission request',
        description: 'Push*, Email',
        tooltip: 'Receive a notification when a credential is resubmitted for approval.',
      },
      // Add more items as needed
    ]);
  const [settings, setSettings] = useState({
    global: { push: true, email: true, sms: false },
    credentials: [
      { id: 1, title: "API Key", push: true, email: true, sms: false },
      { id: 2, title: "User ID", push: false, email: true, sms: true },
    ],
  });

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("notificationSettings"));
    if (savedSettings) {
      // Fallback to default if a specific field is undefined or missing in localStorage
      setSettings({
        global: savedSettings.global || { push: true, email: true, sms: false },
        actions: savedSettings.actions || [ 
          { id: 1, name: "Action assigned", push: true, email: true, sms: false },
          { id: 2, name: "Action completed", push: true, email: true, sms: false },
          { id: 3, name: "Comments added", push: true, email: true, sms: true },
          { id: 4, name: "Media added", push: true, email: true, sms: true },
          { id: 5, name: "Actions reminders due soon", push: true, email: true, sms: true },
          { id: 6, name: "Actions reminders overdue", push: true, email: true, sms: true },
        ],
        issues: savedSettings.issues || [
          { id: 1, name: "New issue reported", push: true, email: false, sms: true },
          { id: 2, name: "Issue assigned or shared", push: true, email: true, sms: false },
          { id: 3, name: "Issue resolved", push: true, email: false, sms: true },
          { id: 4, name: "Comments, or answers added", push: true, email: false, sms: true },
          { id: 5, name: "Media added", push: true, email: false, sms: true },
          { id: 6, name: "Issue reminders due soon", push: true, email: false, sms: true },
          { id: 7, name: "Issue reminders overdue", push: true, email: false, sms: true },
        ],
        inspections: savedSettings.inspections || [
          { id: 1, name: "Inspection triggered notifications", push: true, email: true, sms: false },
      { id: 2, name: "Inspection request approval", push: true, email: true, sms: false },
      { id: 3, name: "Inspection approved", push: true, email: false, sms: true },
        ],
        schedules: savedSettings.schedules || [
          { id: 1, title: "Scheduled inspection starting reminder", push: true, email: true, sms: false },
          { id: 2, title: "Scheduled inspection due soon reminder", push: false, email: true, sms: false },
          { id: 3, title: "Scheduled inspection overdue reminder", push: false, email: true, sms: false },
        ],
        headsup: savedSettings.headsup || [
          { id: 1, title: "Heads Up assigned", push: true, email: false, sms: true },
          { id: 2, title: "Assigned Heads Up reminder", push: false, email: true, sms: false },
          { id: 3, title: "Heads Up completion rate", push: false, email: true, sms: false },
          { id: 4, title: "Heads Up completed", push: false, email: true, sms: false },
          { id: 5, title: "Published Heads Up reminder", push: false, email: true, sms: false },
          { id: 6, title: "New comment or reply", push: false, email: true, sms: false },
        ],
        credentials: savedSettings.credentials || [
          { id: 1, title: "Credential reminder", tooltip: "Access granted for admin.", description: "User has admin privileges.", push: true, email: true, sms: false },
          { id: 2, title: "Credential resubmission request", tooltip: "Access granted for user.", description: "User has limited access.", push: false, email: true, sms: false },
        ],
      });
    }
  }, []);
  
  return (
    <div className="min-h-screen p-4">
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {(
            authData.userName.charAt(0) +
            authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()}
          <FaEdit
            onClick={handleClick} // Opens popup
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold"> {authData?.userName}</h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
              <input
                type="text"
                placeholder="Enter new name"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleClosePopup} // Closes popup
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="p-6 bg-white rounded-lg border border-gray-300 max-w-7xl mx-auto mt-10">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl text-gray-900 mb-4 text-left">Notification Settings</h2>
        <button
          onClick={toggleEdit}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          {isEditing ? "Save" : "Edit"}
        </button>
      </div>

      {isEditing ? (
        <>
          <div className="p-4 rounded-lg mb-6" style={{ backgroundColor: "#e9edf6" }}>
            <h3 className="text-2xl text-gray-700 font-extralight mb-2 text-left">Enable notifications via</h3>
            <p className="text-md text-gray-600 mb-4 text-left">Type of notifications that you can receive</p>
            <div className="flex space-x-4">
              {["push", "email", "sms"].map((type) => (
                <button
                  key={type}
                  className={`px-4 py-2 rounded-full text-sm font-medium ${
                    settings.global[type]
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => handleGlobalToggle(type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left">Actions</h2>
          <ul className="space-y-2 text-left border-b">
            {settings.actions.map((action) => (
              <li key={action.id} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{action.name}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="flex space-x-10 mt-2">
                  {["push", "email", "sms"].map((type) => (
                    <label key={type} className="flex items-center space-x-2 text-sm">
                      <input
                        type="checkbox"
                        checked={action[type]}
                        onChange={() => handleActionToggle(action.id, type)}
                        className="form-checkbox h-5 w-5 text-blue-500"
                      />
                      <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    </label>
                  ))}
                </div>
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-4">Issues</h2>
          <ul className="space-y-2 text-left border-b">
            {settings.issues.map((issue) => (
              <li key={issue.id} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{issue.name}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="flex space-x-10 mt-2">
                  {["push", "email", "sms"].map((type) => (
                    <label key={type} className="flex items-center space-x-2 text-sm">
                      <input
                        type="checkbox"
                        checked={issue[type]}
                        onChange={() => handleIssueToggle(issue.id, type)}
                        className="form-checkbox h-5 w-5 text-blue-500"
                      />
                      <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    </label>
                  ))}
                </div>
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-4">Inspections</h2>
          <ul className="space-y-2 text-left border-b">
            {settings.inspections.map((inspection) => (
              <li key={inspection.id} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{inspection.name}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="flex space-x-10 mt-2">
                  {["push", "email", "sms"].map((type) => (
                    <label key={type} className="flex items-center space-x-2 text-sm">
                      <input
                        type="checkbox"
                        checked={inspection[type]}
                        onChange={() => handleInspectionToggle(inspection.id, type)}
                        className="form-checkbox h-5 w-5 text-blue-500"
                      />
                      <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    </label>
                  ))}
                </div>
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-4">Schedules</h2>
          <ul>
            {settings.schedules.map((schedule) => (
                <li key={schedule.id} className="border-gray-200">
                  <div className="flex items-center relative">
                    <span className="text-gray-600 text-md font-medium">{schedule.title}</span>
                    <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                  </div>
                  <div className="flex space-x-10 mt-2">
                    {["push", "email", "sms"].map((type) => (
                      <label key={type} className="flex items-center space-x-2 text-sm">
                        <input
                          type="checkbox"
                          checked={schedule[type]}
                          onChange={() => handleScheduleToggle(schedule.id, type)}
                          className="form-checkbox h-5 w-5 text-blue-500"
                        />
                        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                      </label>
                    ))}
                  </div>
                </li>
              ))
            }
          </ul>
          <h2 className="text-2xl font-semibold text-gray-600 mb-4 text-left mt-4">Heads Up</h2>
          <ul className="space-y-2 text-left border-b">
            {settings.headsup.map((item) => (
              <li key={item.id} className="border-gray-200">
                <div className="flex items-center">
                  <span className="text-gray-600 text-md font-medium">{item.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="flex space-x-10 mt-2">
                  {["push", "email", "sms"].map((type) => (
                    <label key={type} className="flex items-center space-x-2 text-sm">
                      <input
                        type="checkbox"
                        checked={item[type]}
                        onChange={() => handleHeadsUpToggle(item.id, type)}
                        className="form-checkbox h-5 w-5 text-blue-500"
                      />
                      <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                    </label>
                  ))}
                </div>
              </li>
            ))}
          </ul>
          <h2 className="text-2xl font-semibold text-gray-600 mb-4 text-left mt-4">Credentials</h2>
          <ul className="space-y-2 text-left border-b">
          {settings.credentials.map((credential) => (
            <li key={credential.id} className="border-gray-200">
              <div className="flex items-center">
                <span className="text-gray-600 text-md font-medium">{credential.title}</span>
                <span
                    style={{ display: "inline-flex", position: "relative" }}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
              </div>
              <div className="flex space-x-10 mt-2">
                {["push", "email", "sms"].map((type) => (
                  <label key={type} className="flex items-center space-x-2 text-sm">
                    <input
                      type="checkbox"
                      checked={credential[type]}
                      onChange={() => handleCredentialsToggle(credential.id, type)}
                      className="form-checkbox h-5 w-5 text-blue-500"
                    />
                    <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                  </label>
                ))}
                
              </div>
            </li>
          ))}
        </ul>
        <div className="p-4">
          <div className="flex justify-start space-x-4 mt-4">
            <button
              className="px-4 py-2 bg-gray-300 text-white rounded-md hover:bg-blue-600"
              onClick={() => console.log("Saved")}
            >
              Save Changes
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              onClick={toggleEdit}
            >
              Cancel
            </button>
          </div>
        </div>
        </>
      ) : (
        <>
          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left">Actions</h2>
          <ul className="space-y-2 text-left border-b">
            {notifications.map((notification, index) => (
              <li key={index} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{notification.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    aria-describedby={`popup-tooltip-${index}`}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: "150px", textAlign: "center" }}
                    >
                      {notification.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{notification.description}</span>
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-10">Issues</h2>
          <ul className="space-y-2 text-left border-b">
            {issuesData.map((issue, index) => (
              <li key={index} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{issue.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    aria-describedby={`popup-tooltip-${index}`}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: "150px", textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      {issue.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{issue.channels}</span>
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-10">Inspections</h2>
          <ul className="space-y-2 text-left border-b">
            {inspections.map((inspection, index) => (
              <li key={index} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{inspection.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    aria-describedby={`popup-tooltip-${index}`}
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}
                    >
                      {inspection.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{inspection.description}</span>
              </li>
            ))}
          </ul>
          
          <h2 className="text-2xl font-normal text-gray-600 mb-4 text-left mt-10">Schedules</h2>
          <ul className="space-y-2 text-left border-b">
            {schedules.map((schedule, index) => (
              <li key={index} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{schedule.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    aria-describedby="popup-tooltip"
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}
                    >
                      {schedule.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{schedule.notifications}</span>
              </li>
            ))}
          </ul>
          <h2 className="text-2xl font-semibold text-gray-600 mb-4 text-left mt-4">Heads Up</h2>
          <ul className="space-y-2 text-left border-b">
            {headsup.map((item) => (
              <li key={item.id} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{item.title}</span>
                  <span
                    style={{ display: "inline-flex", position: "relative" }}
                    aria-describedby="popup-tooltip"
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}
                    >
                      {item.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{item.notifications}</span>
              </li>
            ))}
          </ul>
          <h2 className="text-2xl font-semibold text-gray-600 mb-4 text-left mt-10">Credentials</h2>
          <ul className="space-y-2 text-left border-b">
            {credentials.map((credential, index) => (
              <li key={index} className="border-gray-200">
                <div className="flex items-center relative">
                  <span className="text-gray-600 text-md font-medium">{credential.title}</span>
                  <span
                    style={{ display: 'inline-flex', position: 'relative' }}
                    aria-describedby="popup-tooltip"
                    className="group"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      color="#545f70"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 notification-tooltip-styled__NotificationInfoOutlineSvg-iauditor__sc-1jvhwqk-0 TyqDk"
                    >
                      <path
                        d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                        fill="currentColor"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div
                      className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded-md px-3 py-2 left-full top-1/2 transform -translate-y-1/2 ml-2"
                      style={{ minWidth: '150px', textAlign: 'center', whiteSpace: 'nowrap' }}
                    >
                      {credential.tooltip}
                    </div>
                  </span>
                </div>
                <span className="text-sm text-gray-500">{credential.description}</span>
              </li>
            ))}
          </ul>
        </>
      )}
        </div>
    </div>
  );
};
export default UserNotification;
