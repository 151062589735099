import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../assets/home/SSC Logo Final svg.svg";
import { useNotification } from "../../common/hooks/useNotification";
import useHttp from "../../common/hooks/useHttp";

const ResetPassword = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();
  const notify = useNotification();
  const { sendRequest } = useHttp();

  const [isValidToken, setIsValidToken] = useState(false);
  const isTokenVerified = useRef(false);

  useEffect(() => {
    if (isTokenVerified.current || !token) return;

    const verifyToken = async () => {
      try {
        await sendRequest(
          {
            url: "v1/auth/reset-password-token/verify",
            method: "POST",
            payload: { token },
          },
          (response) => {
            if (response && response.responseCode === "200") {
              setIsValidToken(true);
              isTokenVerified.current = true;
            } else {
              notify(response.responseDesc, "error");
              navigate("/");
            }
          }
        );
      } catch (error) {
        console.error("Error verifying token:", error);
        notify("Invalid or expired token", "error");
        navigate("/");
      }
    };

    verifyToken();
  }, [token]);

  const formik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New password is required"),
      confirmNewPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm new password is required"),
    }),
    onSubmit: async (values) => {
      const { email, newPassword, confirmNewPassword } = values;

      try {
        await sendRequest(
          {
            url: "v1/auth/reset-password",
            method: "POST",
            headers: {
              userName: email,
            },
            payload: {
              email,
              token,
              newPassword,
              confirmNewPassword,
            },
          },
          (response) => {
            if (response && response.responseCode === "200") {
              notify(response.responseDesc, "success");
              navigate("/login");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        );
      } catch (error) {
        console.error("Error resetting password:", error);
        notify("Failed to reset password. Please try again.", "error");
      }
    },
  });

  if (!isValidToken) return null;

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="flex flex-col items-center min-h-screen bg-[#E9EEF6]">
        <div className="flex justify-center w-full my-6">
          <img src={Logo} alt="SafetyCulture Logo" className="h-24 w-auto" />
        </div>

        <div className="w-[90%] max-w-[500px] shadow-lg rounded-lg p-6">
          <h1 className="text-xl font-semibold text-gray-800 mb-6 text-start">
            {t("resetpassword.resetheading")}
          </h1>

          <form onSubmit={formik.handleSubmit}>
            <label
              htmlFor="email"
              className="block text-[#1f2533] mb-2 font-medium text-start"
            >
              {t("resetpassword.email")}
            </label>
            <input
              type="email"
              id="email"
              {...formik.getFieldProps("email")}
              className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
              placeholder=" {t('resetpassword.email')}"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-start text-red-500 text-sm">
                {formik.errors.email}
              </div>
            )}

            <label
              htmlFor="newPassword"
              className="block text-[#1f2533] mb-2 font-medium text-start"
            >
              {t("resetpassword.newpassword")}
            </label>
            <input
              type="password"
              id="newPassword"
              {...formik.getFieldProps("newPassword")}
              className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
              placeholder={t("resetpassword.newplaceholder")}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className="text-start text-red-500 text-sm">
                {formik.errors.newPassword}
              </div>
            )}

            <label
              htmlFor="confirmNewPassword"
              className="block text-[#1f2533] mb-2 font-medium text-start"
            >
              {t("resetpassword.newplaceholder")}
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              {...formik.getFieldProps("confirmNewPassword")}
              className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
              placeholder={t("resetpassword.confirmplaceholder")}
            />
            {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword && (
                <div className="text-start text-red-500 text-sm">
                  {formik.errors.confirmNewPassword}
                </div>
              )}

            <button
              type="submit"
              className="w-full bg-[#675df4] text-white font-semibold py-2.5 rounded-md hover:bg-[#5048c8] focus:outline-none"
            >
              {t("resetpassword.savebtn")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
