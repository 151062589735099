import React, { useState } from "react";
import Credentialcsv from "../notifications/bulkuploadcredential/uploadcredentialcsv";
import ReviewData from "../notifications/bulkUploadSitesTabs/reviewData";
import { useNavigate } from "react-router-dom"; 
const CredentialUploadCsv = () => {
  const [activeTab, setActiveTab] = useState("Upload CSV");
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };
  const renderContent = () => {
    switch (activeTab) {
      case "Upload CSV":
        return <Credentialcsv />;
      case "Review data":
        return <ReviewData />;
      //   case "Templates":
      //     return <RegionTab/>;
      default:
        return null;
    }
  };
  return (
    <div className="p-4 md:p-8  mx-auto">
      <div className="flex items-center justify-between w-full md:w-[53%] mb-7">
        {/* Left-aligned button */}
        <button
          className="font-bold text-[#4740d4] text-center border border-[#bfc6d4] py-3 px-4 rounded-lg whitespace-nowrap"
          onClick={handleCancel} // Call handleCancel on button click
        >
          Cancel and exit
        </button>

        {/* Centered tabs */}
        <div className="flex-1 flex justify-end">
          <div className="flex space-x-8 border-b border-gray-300">
            {["Upload CSV", "Review data", "Upload sites"].map(
              (tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`pb-2 font-medium whitespace-nowrap ${
                    activeTab === tab
                      ? "border-b-2 border-purple-600 text-purple-600"
                      : "text-gray-600"
                  }`}
                >
                  {tab}
                </button>
              )
            )}
          </div>
        </div>
      </div>
      {/* Main Content */}
      <div className="mt-8">{renderContent()}</div>
    </div>
  );
};

export default CredentialUploadCsv;
