import React, { useEffect, useState } from "react";
import { FaCube, FaPen, FaClipboard } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Popup from "./asset2";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";
import { FiArchive } from "react-icons/fi";

const Assetimg = require("../../../assets/adminassets/trolli.png");

const AssetsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authData } = useAuth();
  const { sendRequest } = useHttp();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [isArrowUp, setIsArrowUp] = useState(true);
  const initialOptions = [
    { id: 1, label: "Display name", checked: true },
    { id: 2, label: "Type", checked: true },
    { id: 3, label: "Last inspection date", checked: true, isNew: true },
    { id: 4, label: "Open actions", checked: true, isNew: true },
    { id: 5, label: "Modified date", checked: false },
    { id: 6, label: "Created date", checked: false },
    { id: 7, label: "Site", checked: false },
    { id: 8, label: "AT&T Fleet ID", checked: false },
    { id: 9, label: "Brand", checked: false },
    { id: 10, label: "Cat ID", checked: false },
    { id: 11, label: "Data source", checked: false },
    { id: 12, label: "Geotab ID ", checked: false },
    // { id: 13, label: "JJJJJJJJJJJJJ ", checked: false },
    { id: 14, label: "Model ", checked: false },
    // { id: 15, label: "nm ", checked: false },
    { id: 16, label: "Perspio ID ", checked: false },
    { id: 17, label: "Purchase date", checked: false },
    { id: 18, label: "Purchase price", checked: false },
    { id: 19, label: "Registration number", checked: false },
    { id: 20, label: "Samsara ID", checked: false },
    { id: 21, label: "Serial number", checked: false },
    // { id: 22, label: "sssssss", checked: false },
    { id: 23, label: "VIN", checked: false },
    { id: 24, label: "Warranty expiry date", checked: false },
    // { id: 25, label: "wwwwww", checked: false },
  ];
  const [options, setOptions] = useState(initialOptions);

  // const [filteredOptions, setFilteredOptions] = useState(initialOptions);
  
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  // Toggle checkbox state
  const toggleCheckbox = (id) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, checked: !option.checked } : option
      )
    );
  };
  // const [isDropdown, setIsDropdown] = useState(false);

  // const toggleCheckbox = (id) => {
  //   setFilteredOptions((prevOptions) =>
  //     prevOptions.map((option) =>
  //       option.id === id ? { ...option, checked: !option.checked } : option
  //     )
  //   );
  // };
  const getAssets = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setAssets(response.data.assets);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  // const addinArchive = () => {
  //   console.log("hello");
  // };
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };
  const toggleOptions = (e) => {
    e.stopPropagation(); // Prevents event bubbling
    setIsOptionsOpen(!isOptionsOpen);
  };
  const closeDropdown = () => {
    setDropdownOpen(false); // Ensure dropdown closes on any click
  };
  const handleOutsideClick = () => setIsOptionsOpen(false);
  React.useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    // Add a global click listener
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      // Cleanup listener on component unmount
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };
  const handleStepOneClick = () => {
    navigate("/dashboard/assets/types");
  };
  const toggleArrowDirection = () => {
    setIsArrowUp((prev) => !prev); // Toggle the state
  };
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the dropdown menu visibility
  };

  const handleStepTwoClick = () => {
    setIsPopupOpen(true); // Open the popup when "Step 2" is clicked
  };
  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };
  const handleStep3Click = () => {
    navigate("/dashboard/templates");
  };

  useEffect(() => {
    getAssets();
  }, [authData]);
  const filteredAssets = assets.filter((asset) =>
    asset.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".dropdown-menu") === null) {
        setIsMenuOpen(false); // Close the menu if clicked outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside); // Add event listener when menu is open
    } else {
      document.removeEventListener("mousedown", handleClickOutside); // Remove event listener when menu is closed
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup on component unmount
    };
  }, [isMenuOpen]);

  return (
    <div className="p-4 bg-white min-h-screen mr-10">
      {/* Tabs */}
      <div className="flex items-center space-x-4 mb-6">
        <Link
          to="/dashboard/assets"
          className={`px-4 py-2 font-semibold ${
            location.pathname.startsWith("/dashboard/assets")
              ? "text-purple-600 border-b-2 border-purple-600"
              : "text-gray-500"
          }`}
        >
          Assets
        </Link>
        <Link
          to="/dashboard/archive"
          className={`px-4 py-2 font-semibold ${
            location.pathname.startsWith("/dashboard/archive")
              ? "text-purple-600 border-b-2 border-purple-600"
              : "text-gray-500"
          }`}
        >
          Archive
        </Link>
      </div>

      {/* Title and Top Buttons */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Assets</h1>
        <div className="flex items-center space-x-4">
          <Link
            to={"/dashboard/assets/types"}
            className="text-purple-600 border border-purple-600 px-4 py-2 rounded-md"
          >
            Settings
          </Link>
          <button
            onClick={() => {
              navigate("/dashboard/asset-prompt");
            }}
            className="bg-purple-600 text-white px-4 py-2 rounded-md"
          >
            Add asset <span className="ml-1">&#9662;</span>
          </button>
        </div>
      </div>

      {/* Steps Section */}
      <div className="p-6 bg-gray-50 rounded-lg shadow-md">
        <p className="text-left font-medium mb-6">
          Get started with assets by completing three easy steps.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Step 1 */}
          <div
            className="p-4 bg-white h-48 rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStepOneClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-green-400 mb-2 mt-2"
              viewBox="0 0 24 24"
              width="48"
              height="48"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 13l4 4L19 7" />
            </svg>
            <h3 className="font-semibold">1. Create asset types</h3>
            <p className="text-center text-gray-500 text-sm">
              Create and define the information you want to capture for each
              type of asset.
            </p>
          </div>
          {/* Step 2 */}
          <div
            className="p-4 bg-white rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStepTwoClick}
          >
            <FaPen className="text-yellow-500 text-3xl mb-6 mt-4" />
            <h3 className="font-semibold">2. Add assets</h3>
            <p className="text-center text-gray-500 text-sm">
              Add your assets one-by-one or in bulk by uploading a CSV file.
            </p>
          </div>
          {/* Step 3 */}
          <div
            className="p-4 bg-white rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStep3Click}
          >
            <FaClipboard className="text-blue-500 text-3xl mb-6 mt-4" />
            <h3 className="font-semibold">3. Link assets to templates</h3>
            <p className="text-center text-gray-500 text-sm">
              Add the "Asset" question to a template to track inspection
              activity for your assets.
            </p>
          </div>
        </div>
      </div>
      {isPopupOpen && <Popup onClose={closePopup} />}

      {/* Stats Section */}
      <div className="mt-10 flex justify-start items-center space-x-4">
        {/* Total assets */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaCube className="text-purple-600 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">
              {filteredAssets.length}
            </h4>
            <p className="text-gray-500 text-sm">Total assets</p>
          </div>
        </div>
        {/* Open actions */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaClipboard className="text-blue-500 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">{assets.length}</h4>
            <p className="text-gray-500 text-sm">Open actions</p>
          </div>
        </div>
        {/* Inspections last 30 days */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaClipboard className="text-purple-600 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">0</h4>
            <p className="text-gray-500 text-sm">Inspections last 30 days</p>
          </div>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="mt-6 flex items-center space-x-4">
        <input
          type="text"
          placeholder="Search"
          className="px-4 py-2 border border-gray-300 rounded-md w-56"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="relative" onClick={(e) => e.stopPropagation()}>
          {/* Add Filter Button */}
          <button
            className="text-blue-500"
            onClick={(e) => {
              e.stopPropagation(); // Prevent dropdown from immediately closing
              toggleDropdown();
            }}
          >
            + Add filter
          </button>

          {/* Dropdown */}
          {isDropdownOpen && (
            <div className="absolute mt-2 bg-white border border-gray-300 rounded-md shadow-lg p-2">
              <div className="mb-2">
                <div className="block p-2 hover:bg-gray-100 cursor-pointer">
                  Type
                </div>
                <div className="block p-2 hover:bg-gray-100 cursor-pointer">
                  Site
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Flex container for icons aligned to the right */}
        <div className="flex justify-end space-x-4 flex-1">
          {/* Grid Icon Button */}
          <button
            className="p-2 bg-gray-200 rounded focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 5h3v3H7V5zM3 5h3v3H3V5zm0 8h3v3H3v-3zm0 8h3v3H3v-3zm5-8h3v3H8v-3zm0 8h3v3H8v-3zm5-8h3v3h-3V5zm0 8h3v3h-3v-3zm5-8h3v3h-3V5z"
              />
            </svg>
          </button>

          {/* Dropdown Menu */}
          {isMenuOpen && (
            <div className="bg-white rounded shadow-md p-2 absolute mt-8 right-[130px]">
              <ul className="space-y-1">
                <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-300">
                  <svg className="w-5 h-5 mr-2 text-gray-600" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M3 4h18a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1zm0 2v14h18V6H3zm2 6h2v2H5zm4 0h2v2H9zm4 0h2v2h-2zm4 0h2v2h-2zm-8-4h2v2H5zm4 0h2v2H9zm4 0h2v2h-2zm4 0h2v2h-2z"/>
                  </svg>
                  Table
                </li>
                <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-300">
                  <svg className="w-5 h-5 mr-2 text-gray-600" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2l7 4v12l-7 4-7-4V6l7-4zm0 14l5 2.83V7.17L12 5l-5 2.83v9.34L12 16zm0-12l5 2.83V6l-5-2.83L7 6v9.34l5-2.83V4z"/>
                  </svg>
                  Gallery
                </li>
                <li className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-300">
                  <svg className="w-5 h-5 mr-2 text-gray-600" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2a9.93 9.93 0 00-7 2.93C3.36 6.77 3 7.85 3 9c0 1.23.36 2.45 1 3.57L12 22l8-9.43c.64-1.12 1-2.34 1-3.57 0-1.15-.36-2.23-1-3.07A9.93 9.93 0 0012 2zm0 14a4 4 0 110-8 4 4 0 010 8z"/>
                  </svg>
                  Map
                </li>
              </ul>
            </div>
          )}
          {/* More Options Icon Button */}
          <button
            className="p-2 bg-gray-200 rounded focus:outline-none"
            onClick={toggleOptions}
          >
            <svg
              className="w-6 h-6 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 7a2 2 0 110-4 2 2 0 010 4zm0 7a2 2 0 110-4 2 2 0 010 4zm0 7a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>

          {/* Dropdown Menu */}
          {isOptionsOpen && (
            <ul className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                Download all assets
              </li>
              <li className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                Edit assets in bulk
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300" style={{background: '#eef2ff'}}>
          <thead style={{background: '#eef2ff'}}>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              <th className="p-2">
                <input type="checkbox" className="w-4 h-4" />
              </th>
              <th className="flex-1 text-left p-2 font-medium">
                Unique ID
                <span
                  className="ml-1 cursor-pointer text-gray-400"
                  onClick={toggleArrowDirection}
                >
                  {isArrowUp ? "↑" : "↓"} {/* Toggle arrow direction */}
                </span>
              </th>
              <th className="bg-indigo-50 text-gray-700 font-thin h-14 w-full flex">
                {filteredOptions
                  .filter((option) => option.checked) // Only include checked options
                  .map((option) => (
                    <th
                      key={option.id}
                      className="p-2 flex-1 text-left font-medium"
                      style={{ marginRight: '20px' }} // Adds 20px spacing between each <th>
                    >
                      {option.label}
                    </th>
                  ))}
              </th>

              <th className="p-2 flex-1 text-left font-medium">
                {/* Settings Button */}
                <div className="flex justify-end items-center">
                  <button onClick={toggleSettingsDropdown} className="relative">
                    <FaCog className="text-gray-500 text-xl" />
                  </button>
                </div>

                {/* Dropdown Menu */}
                {isSettingsOpen && (
        <div
          className="w-72 border rounded-lg shadow-sm bg-white p-4"
          style={{ position: "absolute", right: "100px" }}
        >
          {/* Search Bar */}
          <div className="mb-3">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Checkbox List */}
          <div className="overflow-y-auto max-h-60">
            <ul>
              {filteredOptions.map((item) => (
                <li
                  key={item.id}
                  className="flex items-center mb-2 hover:bg-gray-100 rounded-md p-1 transition"
                >
                  <label className="flex items-center cursor-pointer w-full">
                    <input
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => toggleCheckbox(item.id)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 rounded"
                    />
                    <span className="ml-3 text-gray-700">{item.label}</span>
                    {item.isNew && (
                      <span className="ml-auto bg-purple-100 text-purple-600 text-xs font-semibold px-2 py-0.5 rounded">
                        NEW
                      </span>
                    )}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
              </th>
            </tr>
          </thead>

          <tbody className="h-64" style={{background: '#ffffff'}}>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((asset) => (
                <tr key={asset.id} className="border-t">
                  <td className="p-2 text-xs sm:text-sm md:text-base"></td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {asset.displayName}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {new Date(asset.createdAt).toLocaleDateString()}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {asset?.assetType?.name}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    <button className=" flex  gap-2 bg-black  text-white items-center px-2 py-1 rounded-md">
                      Archive <FiArchive />
                    </button>
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    <button className="text-black px-2 py-1 rounded-md">...</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="h-64 p-4 sm:p-6">
                  <div className="flex flex-col justify-center items-center text-center h-full">
                    <img
                      src={Assetimg}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 text-sm">
                      No assets added yet
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetsPage;

