const Credentialcsv = () => {
  return (
    <div className="ml-20 mr-20">
      <h1 className="text-gray-800 font-semibold text-start text-xl md:text-2xl">Upload CSV</h1>
      <p
        className="flex items-start gap-2 p-2 text-gray-800 text-sm text-start mb-6 w-1/2"
        style={{ backgroundColor: "#e5faff" }}
        >
        {/* SVG Icon */}
        <span>
            <svg
            width="21"
            height="21"
            viewBox="0 0 24 24"
            className="text-indigo-700"
            color="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M13.2992 7.50215C13.2992 8.22012 12.7172 8.80215 11.9992 8.80215C11.2812 8.80215 10.6992 8.22012 10.6992 7.50215C10.6992 6.78418 11.2812 6.20215 11.9992 6.20215C12.7172 6.20215 13.2992 6.78418 13.2992 7.50215Z"
                fill="currentColor"
            ></path>
            <path
                d="M11.7988 10.1978C11.2465 10.1978 10.7988 10.6455 10.7988 11.1978V16.7978C10.7988 17.35 11.2465 17.7978 11.7988 17.7978H12.1988C12.7511 17.7978 13.1988 17.35 13.1988 16.7978V11.1978C13.1988 10.6455 12.7511 10.1978 12.1988 10.1978H11.7988Z"
                fill="currentColor"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                fill="currentColor"
            ></path>
            </svg>
        </span>
        
        {/* Text Content */}
        <span>
            Before you bulk upload credentials for your users, please make sure you've created 
            the credential types beforehand and all the users you're uploading for are added 
            to your organization.
        </span>
        </p>

      <h2 className="text-xl font-semibold text-gray-800 text-start mb-3">
            Step 1. Prepare your CSV
          </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Step 1 - Left Section */}
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      
          <p className="text-gray-600 mt-2 text-start">What are you trying to do?</p>
          <div className="mt-4 space-y-4">
            <ol className="list-decimal pl-6 space-y-2 text-left">
                <li>
                Unzip the files and use the <strong>"users"</strong> and <strong>"credential-types"</strong> CSV templates to prepare the list of user emails and credential types in your organization for bulk upload credentials.
                </li>
                <li>
                Do not remove, edit, or change the order of the column headers.
                </li>
                <li>
                If you leave the <strong>"Issue date"</strong> or <strong>"Expiration date"</strong> cells blank for a credential, it'll be treated as <strong>"No issue date"</strong> or <strong>"No expiration date"</strong>.
                </li>
                <li>
                Ensure the date format for each credential's <strong>"Issue date"</strong> and <strong>"Expiration date"</strong> match your profile’s date format (<em>d MMM yyyy</em>).
                </li>
                <li>
                If the CSV contains a credential that a user already has, the credential will be updated with a new version.
                </li>
            </ol>
          </div>
          <div className="flex justify-center">
            <button
                className="bg-[#4740d4] text-white text-sm font-medium py-2 px-4 rounded-lg hover:bg-[#3a36c4] focus:outline-none focus:ring-2 focus:ring-[#3a36c4] focus:ring-offset-2 flex items-center space-x-2"
                onClick={() => {
                // Logic for downloading the template
                window.open("path-to-your-csv-template.csv", "_blank");
                }}
            >
                <span className="py-2">
                <svg width="1.125rem" height="1.125rem" viewBox="0 0 24 24" color="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V12.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289L13 12.5858V3Z" fill="currentColor"></path>
                    <path d="M3 14C3.55229 14 4 14.4477 4 15V17.8C4 18.3766 4.00078 18.7488 4.02393 19.0322C4.04613 19.3038 4.0838 19.4045 4.109 19.454C4.20487 19.6422 4.35785 19.7951 4.54601 19.891C4.59546 19.9162 4.69618 19.9539 4.96784 19.9761C5.25118 19.9992 5.62345 20 6.2 20H17.8C18.3766 20 18.7488 19.9992 19.0322 19.9761C19.3038 19.9539 19.4045 19.9162 19.454 19.891C19.6422 19.7951 19.7951 19.6422 19.891 19.454C19.9162 19.4045 19.9539 19.3038 19.9761 19.0322C19.9992 18.7488 20 18.3766 20 17.8V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V17.8386C22 18.3657 22 18.8205 21.9694 19.195C21.9371 19.5904 21.8658 19.9836 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.9836 21.8658 19.5904 21.9371 19.195 21.9694C18.8205 22 18.3657 22 17.8385 22H6.16146C5.63431 22 5.17955 22 4.80497 21.9694C4.40963 21.9371 4.01641 21.8658 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2.13419 19.9836 2.06287 19.5904 2.03057 19.195C1.99997 18.8205 1.99998 18.3657 2 17.8386V15C2 14.4477 2.44772 14 3 14Z" fill="currentColor"></path>
                </svg>
                </span>
                <span className="py-2">Download CSV Template</span>
            </button>
            </div>
        </div>

        {/* Step 2 - Right Section */}
        <div>
        <h2 className="text-xl font-semibold text-gray-800 text-start mb-3 mt-[-40px]">
            Step 2. Upload your CSV
          </h2>
        <div className="bg-gray-50 h-full border-dashed border-2 border-gray-300 rounded-lg flex items-center justify-center p-10">
      
          <div className="text-center">
            <div className="flex justify-center">
            <svg width="30" height="30" viewBox="0 0 24 24" color="#6559ff" xmlns="http://www.w3.org/2000/svg"><path d="M5.24923 7.14303C6.0632 4.17816 8.7764 2 12 2C15.2236 2 17.9368 4.17816 18.7508 7.14303C21.1861 7.70961 23 9.8925 23 12.5C23 14.4079 22.0277 16.0883 20.5563 17.0732C20.0973 17.3804 19.4762 17.2574 19.169 16.7985C18.8618 16.3395 18.9848 15.7184 19.4437 15.4112C20.3842 14.7817 21 13.7125 21 12.5C21 10.6778 19.6067 9.17984 17.828 9.01511C17.375 8.97315 17.0073 8.63059 16.9335 8.18159C16.5436 5.80968 14.4822 4 12 4C9.51785 4 7.45643 5.80968 7.0665 8.18159C6.99268 8.63059 6.62505 8.97315 6.17196 9.01511C4.39329 9.17984 3 10.6778 3 12.5C3 13.7125 3.61575 14.7817 4.55626 15.4112C5.01521 15.7184 5.13822 16.3395 4.83101 16.7985C4.52379 17.2574 3.90269 17.3804 3.44374 17.0732C1.97228 16.0883 1 14.4079 1 12.5C1 9.8925 2.8139 7.70961 5.24923 7.14303Z" fill="currentColor"></path><path d="M7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L11 14.4142V20.9999C11 21.5521 11.4477 21.9999 12 21.9999C12.5523 21.9999 13 21.5521 13 20.9999V14.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071Z" fill="currentColor"></path></svg>
            </div>
      
            <p className="text-gray-700 mt-2">Drag your CSV file here</p>
            <p className="text-gray-600 text-sm">
              or{" "}
              <button
                onClick={() => document.getElementById("fileInput").click()}
                className="text-[#4740d4]"
              >
                browse
              </button>
            </p>
            <input type="file" id="fileInput" className="hidden" />
          </div>
        </div>
        </div>
      
      </div>
    </div>
  );
};
export default Credentialcsv;
