import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";

const CategoryAccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authData } = useAuth();
  const { sendRequest } = useHttp();

  const [defaultAccess, setAccess] = useState([]);
  const [selectedButton, setSelectedButton] = useState("access");
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const params = new URLSearchParams(location.search);
  const name = params.get("name") || "Default Name";
  const categoryId = params.get("id");

  useEffect(() => {
    if (location.state?.selectedButton) {
      setSelectedButton(location.state.selectedButton);
    }
  }, [location.state]);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleNavigate = () => {
    navigate("/dashboard/category-access");
  };

  const handleNewNavigate = () => {
    navigate("/dashboard/edit-category");
  };

  const handleEditButtonClick = (content) => {
    setSidebarContent(content);
    setSidebarOpen(true);
  };

  const getCategories = async () => {
    try {
      const response = await sendRequest({
        url: `v1/categories/${categoryId}`,
        accessToken: authData.accessToken,
        headers: {
          userName: authData.userName,
          organizationId: authData.organizationId,
          deviceId: authData.deviceId,
        },
      });
      if (response && response.data) {
        setAccess(response.data.category.notificationSettings.accessSettings.defaultAccess);
      } else {
        console.warn("No category data found in response");
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const FilterAccess = defaultAccess.filter((type) => type.defaultAccess?.name());

  return (
    <>
      <div className="flex flex-col items-center bg-white">
        {/* Top Row */}
        <div className="flex items-center justify-between w-full px-4 py-2 bg-white border-b">
          <button className="text-black px-4 py-2" onClick={() => navigate("/dashboard/category-page")}>
            ← Back
          </button>
          <h1 className="text-lg font-light mx-4 absolute left-1/2 transform -translate-x-1/2">
            Edit Category
          </h1>
        </div>
        <div className="flex justify-center items-center space-x-4 py-4">
          <button
            className={`text-lg font-medium px-4 py-2 ${
              selectedButton === "workflow" ? "text-indigo-500 underline" : "text-black hover:text-indigo-500 hover:underline"
            }`}
            onClick={() => handleNewNavigate("workflow")}
          >
            Workflow
          </button>
          <button
            className={`text-lg font-medium px-4 py-2 ${
              selectedButton === "access" ? "text-indigo-500 border-b-4 border-indigo-500" : "text-black hover:text-indigo-500 hover:underline"
            }`}
            onClick={() => handleButtonClick("access")}
          >
            Access
          </button>
        </div>
      </div>

      <div className="p-6 bg-[#e9edf6] h-screen">
        {/* Category Visibility Section */}
        <div className="flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-md p-6 mb-6 w-full max-w-5xl text-left">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-700">Category visibility</h3>
              <button
                className="text-indigo-600 font-medium hover:bg-indigo-100 border border-gray-300 py-3 px-4 rounded-lg"
                onClick={() => handleEditButtonClick("categoryVisibility")}
              >
                Edit
              </button>
            </div>
            <p className="text-gray-500 mt-2 ">This category can be reported by:</p>
            <div className="mt-4 border-t pt-4">
              <span className="px-3 py-1 bg-indigo-100 text-indigo-600 text-sm font-medium rounded-full">Everyone</span>
            </div>
          </div>
        </div>

        {/* Access Section */}
        <div className="flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-5xl text-left ">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-700">Access</h3>
              <button
                className="text-indigo-600 font-medium hover:bg-indigo-100 border border-gray-300 py-3 px-4 rounded-lg"
                onClick={() => handleEditButtonClick("access")}
              >
                Edit
              </button>
            </div>
            <p className="text-gray-500 mt-2">
              Select who has access to issues raised in this category and their level of access.
            </p>
            {/* Access Table */}
            <table className="w-full mt-4 border-t border-gray-200">
              <thead>
                <tr>
                  <th className="text-left text-gray-600 text-sm font-medium py-2">Issues available to:</th>
                  <th className="text-left text-gray-600 text-sm font-medium py-2">Access level:</th>
                </tr>
              </thead>
              <tbody>
                {FilterAccess.length > 0 ? (
                  FilterAccess.map((type, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="text-gray-700 text-sm py-2">{type.reporterAccess}</td>
                        <td className="text-gray-700 text-sm py-2">{type.reporterAccess}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="text-center text-gray-500 py-4">
                      No result
                    </td>
                  </tr>
                )}
              </tbody>
                <div className="mt-4 text-sm text-gray-500">
                <p className="flex items-center">
                    <i className="fas fa-info-circle text-indigo-600 mr-2">
                        <svg viewBox="0 0 24 24" width="12" height="12" focusable="false" className="flex">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path fill="#545f70" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                        </svg>
                    </i>
                    <span>
                        Notifying or assigning a user to an issue will give them view and edit access, regardless of their category access.
                    </span>
                    </p>

                <a
                    href="#"
                    className="text-indigo-600 font-medium hover:underline mt-2 inline-block"
                >
                    Learn more about issue access
                </a>
                </div>
            </table>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-end z-50">
          <div className="bg-indigo-50 w-1/3 h-full p-6 shadow-lg overflow-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-gray-800 flex-grow text-center">
                    Category Visibility
                </h2>
                <button className="text-black font-bold" onClick={() => setSidebarOpen(false)}>
                    ×
                </button>
            </div>
            <div className="mt-4">
            {sidebarContent === "categoryVisibility" && (
            <>
            <div className="p-6 bg-white text-left rounded-lg">
                <div className="mb-6">
                    <label
                    htmlFor="visibility"
                    className="block text-sm font-medium text-gray-700 mb-2"
                    >
                    This category can be reported by
                    </label>
                    <select
                    id="visibility"
                    className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    >
                    <option value="" disabled>
                        Select users, groups or sites
                    </option>
                    <option value="users">Users</option>
                    <option value="groups">Groups</option>
                    <option value="sites">Sites</option>
                    </select>
                    <a
                    href="#"
                    className="block text-sm text-indigo-600 hover:underline mt-2"
                    >
                    Learn more about Issue access
                    </a>
                </div>
            </div>


                <div className="flex justify-start space-x-2 mt-2">
                    <button
                    className="px-4 py-2 border rounded-lg text-sm font-medium text-gray-600 bg-white hover:bg-gray-50"
                    >
                    Cancel
                    </button>
                    <button
                    onClick={() => alert("Saved!")}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg text-sm font-medium hover:bg-indigo-700"
                    >
                    Save
                    </button>
                </div>
                </>
            )}
              {sidebarContent === "access" && <p>Edit Access Content</p>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryAccess;
