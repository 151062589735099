import React, { useState } from "react";
import Search from "../search/search";
import { useNavigate } from "react-router-dom";
import { FaCheckSquare, FaPlus, FaSearch } from "react-icons/fa";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const data = [
    {
      id: 1,
      icon: <FaSearch className="text-[#6559ff]" />,
      title: "Find pre-made template",
      description: "Choose from over 100,000 editable templates.",
      completed: true,
    },
    {
      id: 2,
      icon: <FaPlus className="text-[#6559ff]" />,
      title: "Build your template",
      description: "Explore a range of options for building templates.",
      completed: false,
    },
    {
      id: 3,
      icon: <FaCheckSquare color="green" />,
      title: "View sample report",
      description: "Preview what an inspection report looks like.",
      completed: true,
    },
  ];
  return (
    <div className=" pt-5 ">
      {isSearchOpen && <Search />}
      {/* Top Section with Home and Buttons */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-[#1f2533]">Home</h1>
        <div className="flex gap-2">
          <button
            onClick={() => setIsSearchOpen(true)}
            className="inline-flex items-center py-3 px-5 ms-2 text-sm font-medium text-[#4740d4] bg-white rounded-lg border border-[#bfc6d4] focus:ring-4 hover:bg-[#e0e4ff]"
          >
            <svg
              className="w-4 h-4 me-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            Search
          </button>
          <button className="bg-[#6559ff] text-white px-5 py-3 rounded-2xl hover:bg-[#7470f5] shadow">
            + Create
          </button>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="bg-[#F8F9FC] p-8 rounded-lg shadow-md">
        <div className="flex">
          <h2 className="text-xl font-semibold mb-4">
            Getting started with SecureSafetyCulture
          </h2>
          <div className="w-[290px] bg-gray-200 rounded-full h-2 ms-4 mt-2.5">
            <div
              className="bg-[#6559FF] h-2 rounded-full"
              style={{ width: "70%" }}
            ></div>
          </div>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {data.map((item) => (
            <div
              onClick={() => {
                navigate(
                  item.id === 1
                    ? "/dashboard/templates"
                    : item.id === 2
                    ? "/dashboard/create-template"
                    : ""
                );
              }}
              key={item.id}
              className="flex flex-col items-center cursor-pointer justify-center bg-white p-6 rounded-lg shadow-md text-center"
            >
              <div className="text-2xl">{item.icon}</div>
              <h3 className="mt-4 font-semibold text-[15px] text-[#545f70] mt-2">
                {item.title}
              </h3>
              <p className="mt-2 text-[13px] text-gray-500">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
